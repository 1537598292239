import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  createMuiTheme,
  makeStyles,
  createStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import MenuBarsTemplate from '../../templates/MenuBarsTemplate';
import LoadingBubbleOrganisms from '../../organisms/LoadingBubbleOrganisms';
import 'react-datepicker/dist/react-datepicker.css';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Line } from 'react-chartjs-2';

import { Group } from '../../../utils/interfaces/Group';
import { GroupBattle, BattleEvent } from '../../../utils/interfaces/Battle';
import { ChartGraph, GraphData } from '../../../utils/interfaces/Interface';

import {
  AppBar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import dayjs from 'dayjs';

import { useGroups } from '../../../controllers/services/useGroups';
import { useGroupBattles } from '../../../controllers/services/useGroupBattles';
import { useToast } from '../../molecules/toastMolecules';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import ModalMolecures from '../../molecules/ModalMolecules';

const GroupsCtx = createContext<Group[]>([]);
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function createChartDatas(
  start_date: dayjs.Dayjs,
  end_date: dayjs.Dayjs,
  battleevents: BattleEvent
) {
  let refDate: dayjs.Dayjs = dayjs();
  if (refDate.isAfter(end_date)) {
    refDate = end_date;
  } else if (start_date.add(6, 'day').isAfter(refDate)) {
    refDate = start_date.add(6, 'day');
  }
  const yCoordRange = 7;
  const yAxisID: dayjs.Dayjs[] = [];
  const chartgraph: ChartGraph = {} as ChartGraph;
  const labels: string[] = [];
  const datasets: GraphData[] = [] as GraphData[];
  const graphtemplate = {
    fill: false,
    lineTension: 0.1,
    borderCapStyle: 'round',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'square',
    pointBackgroundColor: '#eee',
    pointBorderWidth: 10,
    pointHoverRadius: 5,
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 1,
    pointRadius: 1,
    pointHitRadius: 10,
  };
  for (let i = 1; i <= yCoordRange; i++) {
    yAxisID.push(refDate.subtract(yCoordRange - i - 1, 'day'));
    labels.push(refDate.subtract(yCoordRange - i, 'day').format('MM/DD'));
  }
  battleevents.groups?.map((group) => {
    const idx = datasets.push({
      label: group.group_name,
      data: [],
      backgroundColor: `rgba(\
        ${parseInt(group.team_color.substr(0, 2), 16)},\
        ${parseInt(group.team_color.substr(2, 2), 16)},\
        ${parseInt(group.team_color.substr(4, 2), 16)},\
        0.4)`,
      borderColor: `rgba(\
        ${parseInt(group.team_color.substr(0, 2), 16)},\
        ${parseInt(group.team_color.substr(2, 2), 16)},\
        ${parseInt(group.team_color.substr(4, 2), 16)},\
        1)`,
      pointBorderColor: `rgba(\
        ${parseInt(group.team_color.substr(0, 2), 16)},\
        ${parseInt(group.team_color.substr(2, 2), 16)},\
        ${parseInt(group.team_color.substr(4, 2), 16)},\
        1)`,
      pointHoverBackgroundColor: `rgba(\
        ${parseInt(group.team_color.substr(0, 2), 16)},\
        ${parseInt(group.team_color.substr(2, 2), 16)},\
        ${parseInt(group.team_color.substr(4, 2), 16)},\
        1)`,
      ...graphtemplate,
    });
    yAxisID.map((yAxis, yAxisIdx) => {
      datasets[idx - 1].data[yAxisIdx] = 0;
      let user_count = 0;
      group.groupbattleSummary?.map((deptbattleRecord) => {
        if (
          dayjs(deptbattleRecord.batch_execute_time).format('YYYY-MM-DD') ==
            yAxis.format('YYYY-MM-DD') &&
          deptbattleRecord.group_id == group.group_id &&
          deptbattleRecord.battleevents_id == battleevents.battleevents_id
        ) {
          user_count++;
          datasets[idx - 1].data[yAxisIdx] += deptbattleRecord.summation_value;
        }
      });
      //平均競走の場合はユーザー数で割り算し、平均歩数を算出（小数点以下切り捨て）
      if(battleevents.battleevents_id == 1 || battleevents.battleevents_id == 2){
        datasets[idx - 1].data[yAxisIdx] = Math.floor(datasets[idx - 1].data[yAxisIdx] / user_count );
      }
    });
  });
  chartgraph.labels = labels;
  chartgraph.datasets = datasets;
  return chartgraph;
}

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#8fc31f' }, // テーマの色
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    container: {
      maxHeight: 2000,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      height: 600,
      width: 500,
      padding: 20,
      outline: 'none',
      borderRadius: 10,
    },
    button: {
      height: 50,
      width: 200,
      marginBottom: 30,
      backgroundColor: 'textSecondary',
      outline: 'none',
    },
    form: {
      height: 50,
      width: '80%',
      marginTop: 20,
      marginLeft: 45,
    },
    formControl: {
      marginBottom: 100,
      width: '5%',
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    deleteModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: 'white',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    EditButton: {
      marginLeft: theme.spacing(2),
    },
    rowAreaformControl: {
      margin: theme.spacing(1),
      maxWidth: '200px',
    },
    createGroupButton: {
      marginLeft: theme.spacing(2),
    },
  })
);

type GroupBattleProps = RouteComponentProps<{
  groupbattleid: string;
}>;

function GroupBattleEditModal(props: {
  isOpen: boolean;
  onClose: () => void;
  groupbattleOrigin: GroupBattle;
}) {
  const { isOpen, onClose, groupbattleOrigin } = props;
  const [groupbattle, setGroupBattle] = useState(groupbattleOrigin);
  const { usePutGroupBattleMutation } = useGroupBattles();
  const putGroupBattleMutate = usePutGroupBattleMutation().mutate;
  const groups = useContext(GroupsCtx);
  const [searchedGroupName, setSearchedGroupName] = useState('');
  const [searchedGroupId, setSearchedGroupId] = useState('');
  const [searchedJoinedGroupName, setSearchedJoinedGroupName] = useState('');
  const [searchedJoinedGroupId, setSearchedJoinedGroupId] = useState('');

  useEffect(() => {
    setGroupBattle(groupbattleOrigin);
  }, [groupbattleOrigin]);

  const addGroupToGroupBattle = async (group: Group) => {
    setGroupBattle((state) => ({
      ...state,
      groups: [...state.groups, group],
    }));
  };

  const removeGroupFromGroupBattle = async (group: Group) => {
    setGroupBattle((state) => {
      return {
        ...state,
        groups: state.groups.filter(
          (groupRow) => groupRow.group_id !== group.group_id
        ),
      };
    });
  };

  const confirmGroupBattle = async () => {
    await putGroupBattleMutate(groupbattle);
    onClose();
  };

  const cancelEditGroupBattle = async () => {
    setGroupBattle(groupbattleOrigin);
    onClose();
  };

  const notPerticipatedGroups = groups.filter(
    (group) =>
      !groupbattle.groups.find(
        (unJoinedGroup) => unJoinedGroup.group_id == group.group_id
      )
  );

  const searchedResultGroups = notPerticipatedGroups.filter(
    (group) =>
      group.group_name.includes(searchedGroupName) &&
      group.group_id.toString().includes(searchedGroupId)
  );

  const clearJoinedGroupSearch = () => {
    setSearchedJoinedGroupName('');
    setSearchedJoinedGroupId('');
  };

  const clearGroupSearch = () => {
    setSearchedGroupName('');
    setSearchedGroupId('');
  };

  const searchResultGroups = groupbattle.groups.filter(
    (group) =>
      group.group_name.includes(searchedJoinedGroupName) &&
      group.group_id.toString().includes(searchedJoinedGroupId)
  );

  return (
    <ModalMolecures
      isOpen={isOpen}
      onClose={onClose}
      fullWidth={true}
      maxWidth={false}>
      <br></br>
      <Typography
        color="textSecondary"
        variant="h5"
        gutterBottom
        component="div">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;参加グループ編集
      </Typography>
      <hr></hr>
      <Grid container spacing={0} style={{ width: '80vw' }}>
        <Grid item xs={6}>
          <DialogContent>
            <DialogContentText>対抗戦に参加しているグループ</DialogContentText>
            <Table
              size="small"
              aria-label="purchases"
              style={{ marginBottom: '2%' }}>
              <TableBody>
                <TableRow>
                  <TableCell size="small" style={{ width: '80%' }}>
                    <TextField
                      id="searchedJoinedGroupName"
                      label="グループ名"
                      value={searchedJoinedGroupName}
                      onChange={(event) =>
                        setSearchedJoinedGroupName(event.target.value)
                      }
                      name="groupId"
                      placeholder="グループ名"
                      fullWidth={true}
                    />
                  </TableCell>
                  <TableCell align="right" style={{ width: '10%' }}>
                    <IconButton aria-label="delete">
                      <HighlightOffIcon
                        onClick={() => clearJoinedGroupSearch()}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TableContainer style={{ height: '50vh' }}>
              <Table
                stickyHeader
                size="small"
                aria-label="perticipatedUserTable">
                <TableHead>
                  <TableRow>
                    <TableCell>グループ名</TableCell>
                    <TableCell align="center">削除</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchResultGroups.map((group) => (
                    <TableRow
                      key={groupbattle.groupbattle_id + '-' + group.group_id}>
                      <TableCell component="th" scope="row">
                        {group.group_name}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="delete"
                          onClick={() => removeGroupFromGroupBattle(group)}>
                          <DeleteIcon color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Grid>
        <Grid item xs={6}>
          <DialogContent>
            <DialogContentText>
              対抗戦に追加するグループを設定してください
            </DialogContentText>
            <Table
              size="small"
              aria-label="purchases"
              style={{ marginBottom: '2%' }}>
              <TableBody>
                <TableRow>
                  <TableCell size="small" style={{ width: '80%' }}>
                    <TextField
                      id="searchedGroupName"
                      label="グループ名"
                      value={searchedGroupName}
                      onChange={(event) =>
                        setSearchedGroupName(event.target.value)
                      }
                      name="userId"
                      placeholder="グループ名"
                      fullWidth={true}
                    />
                  </TableCell>
                  <TableCell align="right" style={{ width: '10%' }}>
                    <IconButton aria-label="delete">
                      <HighlightOffIcon onClick={() => clearGroupSearch()} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TableContainer style={{ height: '50vh' }}>
              <Table stickyHeader size="small" aria-label="search-result">
                <TableHead>
                  <TableRow>
                    <TableCell>グループ</TableCell>
                    <TableCell align="center">追加</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchedResultGroups.map((group, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th">{group.group_name}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="add"
                          onClick={() => addGroupToGroupBattle(group)}>
                          <AddCircleOutlineIcon color="primary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelEditGroupBattle()} color="primary">
              キャンセル
            </Button>
            <Button onClick={() => confirmGroupBattle()} color="primary">
              決定
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </ModalMolecures>
  );
}

const GroupBattleDetail: React.FC<GroupBattleProps> = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { showToast } = useToast();
  const { useGetSelectedGroupBattleMutation } = useGroupBattles();
  const { useGetGroups } = useGroups();
  const groupbattleid = props.match.params.groupbattleid;

  const [groupEditModalState, setGroupEditModalState] = React.useState(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const {
    isLoading: groupIsLoading,
    data: groupData,
    isError: groupIsError,
  } = useGetGroups();

  const {
    isLoading: groupbattleIsLoading,
    data: groupbattleData,
    isError: groupbattleIsError,
  } = useGetSelectedGroupBattleMutation(Number(groupbattleid));

  if (!groupData || !groupbattleData) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (groupIsLoading || groupbattleIsLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (groupIsError || groupbattleIsError) {
    showToast(
      'error',
      'データ取得に失敗しました',
      '時間をおいて再度お試しください',
      3000
    );
    return (
      <MenuBarsTemplate title="">
        <></>
      </MenuBarsTemplate>
    );
  }

  return (
    <ThemeProvider theme={theme}>
    <GroupsCtx.Provider value={groupData}>
      <MenuBarsTemplate title="対抗戦詳細">
        <Typography
          color="textSecondary"
          variant="h5"
          gutterBottom
          component="div">
          <Link to={`/groupbattles`}>
            <IconButton>
              <ChevronLeftIcon />
            </IconButton>
          </Link>
          対抗戦詳細
          <Button
            className={classes.createGroupButton}
            variant="outlined"
            vertical-align="center"
            size="small"
            onClick={() => {
              setGroupEditModalState(true);
            }}>
            <EditIcon />
            参加グループ編集
          </Button>
        </Typography>
        <br />
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example">
              {groupbattleData.battleevents.map((battleevent, idx) => (
                <Tab
                  label={battleevent.battleevents_name}
                  key={`tabmenu-${idx}`}
                  {...a11yProps(idx)}
                />
              ))}
            </Tabs>
          </AppBar>
          {groupbattleData.battleevents.map((battleevent, idx) => (
            <TabPanel key={`tabpanel-${idx}`} value={value} index={idx}>
              <Grid container>
                <Grid item xs={9}>
                  <Line
                    height={window.innerHeight * 0.8}
                    width={window.innerWidth * 0.8}
                    data={createChartDatas(
                      dayjs(groupbattleData.start_date),
                      dayjs(groupbattleData.end_date),
                      battleevent
                    )}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TableContainer style={{ height: '55vh' }}>
                    <Table
                      stickyHeader
                      size="small"
                      aria-label="perticipatedUserTable">
                      <TableHead>
                        <TableRow>
                          <TableCell>参加グループ</TableCell>
                          {/* <TableCell align="center" style={{ width: '5%' }}>
                            スコア
                          </TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {battleevent.groups?.map((group) => (
                          <TableRow key={group.group_id}>
                            <TableCell component="th" scope="row">
                              <div
                                style={{
                                  cursor: 'pointer',
                                  float: 'left',
                                  width: '18px',
                                  height: '18px',
                                  border: '1px solid #999',
                                  margin: '1px',
                                  backgroundColor: `#${group.team_color}`,
                                }}>
                                &nbsp;
                              </div>
                              &nbsp;
                              {group.group_name}
                            </TableCell>
                            {/* <TableCell component="th" scope="row">
                              {group.group_name}
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </TabPanel>
          ))}
        </div>
        <Paper className={classes.root}></Paper>
      </MenuBarsTemplate>
      <GroupBattleEditModal
        isOpen={groupEditModalState}
        onClose={() => setGroupEditModalState(false)}
        groupbattleOrigin={groupbattleData}></GroupBattleEditModal>
    </GroupsCtx.Provider>
    </ThemeProvider>
  );
};

export default GroupBattleDetail;