import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Link, useHistory } from 'react-router-dom';
import Linking from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import HomeIcon from '@material-ui/icons/Home';
import BuildIcon from '@material-ui/icons/Build';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChatIcon from '@material-ui/icons/Chat';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PublicOutlined from '@material-ui/icons/PublicOutlined';
import { ExitToAppRounded, ArrowDropDown } from '@material-ui/icons';
import LanguageIcon from '@material-ui/icons/Language';
import { useAuth } from '../../controllers/services/useAuth';
import dayjs from 'dayjs';
import {
  AppBar,
  Button,
  Box,
  Divider,
  Drawer,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import LoadingBubbleOrganisms from '../organisms/LoadingBubbleOrganisms';
import * as ld from 'lodash';
import { useToast } from '../molecules/toastMolecules';
import { LoginUserContext } from '../../utils/CommonContext';
import { useCompanies } from '../../controllers/services/useCompany';

const drawerWidth = 240;

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#8fc31f' }, // テーマの色
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: {
      backgroundColor: '#17A673',
      zIndex: theme.zIndex.drawer + 1,
      //paddingRight: 24,
    },
    toolbarIcon: {
      backgroundColor: '#17A673',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      backgroundColor: 'lightgreen',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
      color: 'white',
      fontWeight: 'bold',
    },
    userButton: {
      flexGrow: 1,
      color: 'white',
      fontWeight: 'bold',
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.text.secondary,
    },
  })
);

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Linking color="inherit" href="https://arteryex.biz/about-us/">
        with LEAF
      </Linking>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export interface MenuBarsTemplate {
  children: React.ReactNode;
  title: string;
}

function checkRoles(admin_role: any, role: any) {
  if (ld.findIndex(admin_role, { admin_role_id: role }) == -1) {
    return false;
  } else {
    return true;
  }
}

const MenuBarsTemplate: React.FC<MenuBarsTemplate> = ({ children, title }) => {
  const classes = useStyles();
  const history = useHistory();
  const path = window.location.pathname;
  const { showToast } = useToast();
  const [open, setOpen] = useState(true);
  const [isToggleOpen, setIsToggleOpen] = React.useState(false);
  const { useRequestLogoutMutation, useGetAuth } = useAuth();
  // const { useGetCompany } = useCompanies();
  const requestLogoutMutate = useRequestLogoutMutation().mutateAsync;
  const { isLoading, data, isError } = useGetAuth();
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  // const {
  //   isLoading: companyIsLoading,
  //   data: companyData,
  // } = useGetCompany();
  // console.log("companyData", companyData)

  const handleToggle = () => {
    setIsToggleOpen((prevOpen) => !prevOpen);
  };

  const handleClose = async (event: React.MouseEvent<EventTarget>) => {
    console.debug('handleClose ---1---', dayjs().format('HH:mm:ss-SSS'));
    await requestLogoutMutate();
    console.debug('handleClose ---2---', dayjs().format('HH:mm:ss-SSS'));

    history.push('/login');
    console.debug('handleClose ---3---', dayjs().format('HH:mm:ss-SSS'));
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsToggleOpen(false);
    }
  }

  const prevOpen = React.useRef(isToggleOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && isToggleOpen === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = isToggleOpen;
  }, [isToggleOpen]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const loginUser = useContext(LoginUserContext);
  let is_demo: any;
  if (loginUser.company) {
    is_demo = new Map(Object.entries(loginUser.company)).get('is_demo');
  } else {
    is_demo = 1;
  }
  // const isDemo: any = new Map(Object.entries(loginUser.company)).get('is_demo');
  // console.debug(isDemo);
  if (!data) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (isLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }
  if (isError) {
    showToast(
      'error',
      'データ取得に失敗しました',
      '時間をおいて再度お試しください',
      3000
    );
    return (
      <MenuBarsTemplate title="">
        <></>
      </MenuBarsTemplate>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}>
              <MenuIcon />
            </IconButton>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Typography component="h1" variant="h5" className={classes.title}>
                with LEAF 管理画面
              </Typography>
            </Link>
            <div style={{ marginLeft: 'auto' }}>
              <div>
                <Button
                  ref={anchorRef}
                  aria-label="open toggle button"
                  aria-controls={isToggleOpen ? 'menu-list-grow' : undefined}
                  onClick={handleToggle}
                  aria-haspopup="true"
                  className={classes.userButton}>
                  {data.name}
                  <ArrowDropDown />
                </Button>
                <Popper
                  open={isToggleOpen}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}>
                      <Paper>
                        <MenuList
                          style={{ marginRight: 15 }}
                          autoFocusItem={isToggleOpen}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}>
                          <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                              <ExitToAppRounded />
                            </ListItemIcon>
                            Logout
                          </MenuItem>
                        </MenuList>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}>
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {loginUser.admin_grants?.dashboard.any.read ||
            loginUser.admin_grants?.dashboard.self.read ? (
              <>
                <Link to="/dashboard" className={classes.link}>
                  <ListItem button selected={path == '/'}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="ダッシュボード" />
                  </ListItem>
                </Link>
              </>
            ) : (
              <></>
            )}

            {loginUser.admin_grants?.user.any.read ||
            loginUser.admin_grants?.user.self.read ? (
              <>
                <Link to="/users" className={classes.link}>
                  <ListItem button selected={path == '/users'}>
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="ユーザー" />
                  </ListItem>
                </Link>
              </>
            ) : (
              <></>
            )}

            {loginUser.admin_grants?.user.any.read ||
            loginUser.admin_grants?.user.self.read ? (
              <>
                <Link to="/chatgroups" className={classes.link}>
                  <ListItem button selected={path == '/chatgroups'}>
                    <ListItemIcon>
                      <ChatIcon />
                    </ListItemIcon>
                    <ListItemText primary="トークグループ" />
                  </ListItem>
                </Link>
              </>
            ) : (
              <></>
            )}

            {(is_demo === 0 && loginUser.admin_grants?.admin.any.read) ||
            loginUser.admin_grants?.admin.self.read ? (
              <>
                <Link to="/administrators" className={classes.link}>
                  <ListItem button selected={path == '/administrators'}>
                    <ListItemIcon>
                      <VpnKeyIcon />
                    </ListItemIcon>
                    <ListItemText primary="管理者" />
                  </ListItem>
                </Link>
              </>
            ) : (
              <></>
            )}
            {/* 
            {isDemo === 0 &&
            loginUser.admin_grants?.challenge.any.read ||
            loginUser.admin_grants?.challenge.self.read ? (
              <>
                <Link to="/challenges" className={classes.link}>
                  <ListItem button selected={path == '/challenges'}>
                    <ListItemIcon>
                      <WhatshotIcon />
                    </ListItemIcon>
                    <ListItemText primary="チャレンジ" />
                  </ListItem>
                </Link>
              </>
            ) : (
              <></>
            )} */}

            {(is_demo === 0 && loginUser.admin_grants?.healthcheck.any.read) ||
            loginUser.admin_grants?.healthcheck.self.read ? (
              <>
                <Link to="/healthchecks" className={classes.link}>
                  <ListItem button selected={path == '/healthchecks'}>
                    <ListItemIcon>
                      <FavoriteIcon />
                    </ListItemIcon>
                    <ListItemText primary="健康診断" />
                  </ListItem>
                </Link>
              </>
            ) : (
              <></>
            )}

            {(is_demo === 0 && loginUser.admin_grants?.groupbattle.any.read) ||
            loginUser.admin_grants?.groupbattle.self.read ? (
              <>
                <Link to="/groupbattles" className={classes.link}>
                  <ListItem button selected={path == '/groupbattles'}>
                    <ListItemIcon>
                      <LanguageIcon />
                    </ListItemIcon>
                    <ListItemText primary="対抗戦" />
                  </ListItem>
                </Link>
              </>
            ) : (
              <></>
            )}

            {loginUser.admin_grants?.groupbattle.any.read ||
            loginUser.admin_grants?.groupbattle.self.read ? (
              <>
                <Link to="/companybattles" className={classes.link}>
                  <ListItem button selected={path == '/companybattles'}>
                    <ListItemIcon>
                      <PublicOutlined />
                    </ListItemIcon>
                    <ListItemText primary="企業間対抗戦" />
                  </ListItem>
                </Link>
              </>
            ) : (
              <></>
            )}

            {loginUser.admin_grants?.import.any.read ||
            loginUser.admin_grants?.import.self.read ? (
              <>
                <Link to="/import" className={classes.link}>
                  <ListItem button selected={path == '/import'}>
                    <ListItemIcon>
                      <BuildIcon />
                    </ListItemIcon>
                    <ListItemText primary="設定" />
                  </ListItem>
                </Link>
              </>
            ) : (
              <></>
            )}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth={false} className={classes.container}>
            {children}
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    </ThemeProvider>
  );
};

export default MenuBarsTemplate;
