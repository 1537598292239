import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MenuBarsTemplate from '../../templates/MenuBarsTemplate';
import LoadingBubbleOrganisms from '../../organisms/LoadingBubbleOrganisms';

import { Paper, Tabs, Tab, Box, Typography } from '@material-ui/core';
import 'date-fns';

import { useGroupBattles } from '../../../controllers/services/useGroupBattles';
import { useBattleEvents } from '../../../controllers/services/useBattleEvents';
import { useToast } from '../../molecules/toastMolecules';
import { GroupWorkOutlined, GroupOutlined } from '@material-ui/icons';
import GroupBattlesList from './GroupBattlesList';
import GroupBattlesGroups from './GroupBattlesGroups';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    container: {
      maxHeight: 2000,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      height: 600,
      width: 500,
      padding: 20,
      outline: 'none',
      borderRadius: 10,
    },
    button: {
      height: 50,
      width: 200,
      marginBottom: 30,
      backgroundColor: 'textSecondary',
      outline: 'none',
    },
    form: {
      height: 50,
      width: '80%',
      marginTop: 20,
      marginLeft: 45,
    },
    formControl: {
      marginBottom: 100,
      width: '5%',
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    deleteModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: 'white',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    EditButton: {
      marginLeft: theme.spacing(2),
    },
    rowAreaformControl: {
      margin: theme.spacing(1),
      maxWidth: '200px',
    },
    createGroupButton: {
      marginLeft: theme.spacing(2),
    },
  })
);

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const GroupBattles: React.FC = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const { showToast } = useToast();
  const { useGetGroupBattlesMutation } = useGroupBattles();
  const { useReadBattleEvents } = useBattleEvents();
  const {
    isLoading: groupbattleLoading,
    data: groupbattleData,
    isError: groupbattleIsError,
  } = useGetGroupBattlesMutation();

  const {
    isLoading: battleeventsLoading,
    data: battleeventsData,
    isError: battleeventsIsError,
  } = useReadBattleEvents();

  if (!groupbattleData || !battleeventsData) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (groupbattleLoading || battleeventsLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (groupbattleIsError || battleeventsIsError) {
    showToast(
      'error',
      'データ取得に失敗しました',
      '時間をおいて再度お試しください',
      3000
    );
    return (
      <MenuBarsTemplate title="">
        <></>
      </MenuBarsTemplate>
    );
  }

  return (
    <MenuBarsTemplate title="対抗戦管理">
      <Paper square className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
          style={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, .5)' }}>
          <Tab icon={<GroupWorkOutlined />} label="対抗戦一覧" />
          <Tab icon={<GroupOutlined />} label="グループ管理" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <GroupBattlesList></GroupBattlesList>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GroupBattlesGroups></GroupBattlesGroups>
        </TabPanel>
      </Paper>
    </MenuBarsTemplate>
  );
};

export default GroupBattles;
