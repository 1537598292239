import { useState } from 'react';
import { commonHttpClient } from './commonHttpClient';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useToast } from '../../components/molecules/toastMolecules';
import { Report, ReportSummary } from '../../utils/interfaces/Report';
import { Challenge } from '../../utils/interfaces/Challenge';

export const useHealthReports = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const useGetHealthReports = (during: number) =>
    useQuery(['healthreport', during], () => getHealthReports(during));

  const useGetHealthReportsSummaryList = () =>
    useQuery('healthreportList', getHealthReportSummaryList);

  const getHealthReportSummary = async () => {
    const { data } = await http.get('/reports');
    return data as Challenge[];
  };

  const getHealthReportSummaryList = async () => {
    const { data } = await http.get('/reports/list/all');
    return data as ReportSummary[];
  };

  const getHealthReports = async (during: number): Promise<Report[]> => {
    const { data } = await http.get(`/reports/${during}`);
    return data as Report[];
  };

  return {
    useGetHealthReports,
    getHealthReportSummary,
    useGetHealthReportsSummaryList,
  };
};
