import { Notification } from 'rsuite';
import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#8fc31f' }, // テーマの色
  },
});

export const useToast = () => {
  const showToast = (
    funcName: 'info' | 'success' | 'error' | 'warning',
    title: any,
    message: any,
    duration: number
  ) => {
    if (funcName === 'success') {
      Notification[funcName]({
        title: title,
        description: message,
        style: {
          width: '400px',
        },
        duration: duration,
      });
    } else if (funcName === 'error') {
      Notification[funcName]({
        title: title,
        description: message,
        style: {
          width: '400px',
        },
        duration: duration,
      });
    }
  };
  return { showToast };
};
