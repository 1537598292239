import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { BorderColor } from '@material-ui/icons';

type Props = {
  title: string;
  category: string;
};

const DashbordCard: React.FC<Props> = ({ title, category }) => {
  const useStyles = makeStyles({
    root: {
      minWidth: 500,
      height: 100,
      marginTop: 20,
      marginRight: 10,
      borderLeft: 10,
      borderLeftColor: 'lightgreen',
      borderLeftWidth: 10,
      borderLeftStyle: 'solid',
    },

    title: {
      fontSize: 18,
    },
    pos: {
      fontWeight: 50,
      fontSize: 25,
      marginTop: 5,
      marginLeft: 20,
    },
  });

  const classes = useStyles();

  return (
    <div>
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            component="h2">
            {category}
          </Typography>
          <Typography className={classes.pos} variant="h5">
            {title}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default DashbordCard;
