import { commonHttpClient } from './commonHttpClient';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useToast } from '../../components/molecules/toastMolecules';
import { CompanyBattle, UpdateCompanyBattlePayload, PutCompanyBattlePayload, CreateCompanyBattleChatGroupsPayload } from '../../utils/interfaces/CompanyBattle';

export const useCompanyBattles = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  //全バトル取得
  const getCompanyBattles = async () => {
    const { data } = await http.get('/companybattle');
    return data as CompanyBattle[];
  };

  const useGetCompanyBattlesMutation = () =>
    useQuery('CompanyBattles', getCompanyBattles);

  // 指定したIDのバトル取得;
  const getSelectedCompanyBattle = async (
    companybattle_id: number
  ): Promise<CompanyBattle> => {
    const { data } = await http.get(`/companybattle/${companybattle_id}`);
    return data as CompanyBattle;
  };

  const useGetSelectedGroupBattleMutation = (companybattle_id: number) =>
    useQuery('CompanyBattle', () => getSelectedCompanyBattle(companybattle_id));

  const updateCompanyBattle = async (
    updateCompanyBattlePayload: UpdateCompanyBattlePayload
  ): Promise<void> => {
    await http.patch(
      `/companybattle`,
      updateCompanyBattlePayload
    );
  };

  const useUpdateCompanyBattleMutation = () =>
    useMutation(updateCompanyBattle, {
      onSuccess: () => {
        showToast(
          'success',
          '対抗戦を編集しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('CompanyBattles');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  const putCompanyBattleMember = async (
    updateCompanyBattlePayload: PutCompanyBattlePayload
  ): Promise<void> => {
    await http.put(`/companybattle`, updateCompanyBattlePayload);
  };

  const usePutCompanyBattleMemberMutation = () =>
    useMutation(putCompanyBattleMember, {
      onSuccess: () => {
        showToast(
          'success',
          '対抗戦を編集しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('CompanyBattles');
          queryClient.invalidateQueries('CompanyBattle');
        }, 299);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  // デモ企業対抗戦ユーザー追加
  const addUsersToCompanyBattle = async (
  ): Promise<void> => {
    await http.post('/companybattle/addUsers_companybattle');
  };

  const useAddUsersToCompanyBattleMutation = () =>
    useMutation(addUsersToCompanyBattle, {
      onSuccess: () => {
        showToast(
          'success',
          '対抗戦にユーザーを追加しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('CompanyBattles');
        }, 299);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ取得に失敗しました', e.message, 3000);
        }
      },
    });

  return {
    useGetCompanyBattlesMutation,
    getSelectedCompanyBattle,
    useAddUsersToCompanyBattleMutation,
    useUpdateCompanyBattleMutation,
    usePutCompanyBattleMemberMutation,
    useGetSelectedGroupBattleMutation,
  };
};
