import React, { createContext, useContext, useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MenuBarsTemplate from '../../templates/MenuBarsTemplate';
import ModalMolecures from '../../molecules/ModalMolecules';
import LoadingBubbleOrganisms from '../../organisms/LoadingBubbleOrganisms';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
} from '@material-ui/core';
import dayjs from 'dayjs';
import 'date-fns';

import { useGroupBattles } from '../../../controllers/services/useGroupBattles';
import { BattleEvent, GroupBattle } from '../../../utils/interfaces/Battle';
import { useBattleEvents } from '../../../controllers/services/useBattleEvents';
import { useToast } from '../../molecules/toastMolecules';
import { Link } from 'react-router-dom';
import {
  FavoriteOutlined,
  PersonPin,
  PersonPinOutlined,
  PhonelinkLockOutlined,
} from '@material-ui/icons';
import { values } from 'lodash';
import InputGroupAddon from 'rsuite/lib/InputGroup/InputGroupAddon';
import Autocomplete from '@material-ui/lab/Autocomplete';

const BattleEventsCtx = createContext<BattleEvent[]>([]);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    container: {
      maxHeight: 2000,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      height: 600,
      width: 500,
      padding: 20,
      outline: 'none',
      borderRadius: 10,
    },
    button: {
      height: 50,
      width: 200,
      marginBottom: 30,
      backgroundColor: 'textSecondary',
      outline: 'none',
    },
    form: {
      height: 50,
      width: '80%',
      marginTop: 20,
      marginLeft: 45,
    },
    formControl: {
      marginBottom: 100,
      width: '5%',
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    deleteModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: 'white',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    EditButton: {
      marginLeft: theme.spacing(2),
    },
    rowAreaformControl: {
      margin: theme.spacing(1),
      maxWidth: '200px',
    },
    createDepartmentButton: {
      marginLeft: theme.spacing(2),
    },
  })
);

function GroupBattleRow(props: { groupbattle: GroupBattle }) {
  const classes = useStyles();
  const battleevents = useContext(BattleEventsCtx);

  const { groupbattle: groupBattleOrigin } = props;
  const [deleteConfirmation, setDeleteConfirmation] = React.useState('');
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [groupbattle, setGroupBattle] =
    React.useState<GroupBattle>(groupBattleOrigin);
  const [previous, setPrevious] = React.useState({} as GroupBattle);

  const { showToast } = useToast();

  const { useUpdateGroupBattleMutation, useDeleteGroupBattleMutation } =
    useGroupBattles();
  const updateGroupBattleMutate = useUpdateGroupBattleMutation().mutate;
  const deleteGroupBattleMutate = useDeleteGroupBattleMutation().mutate;

  useEffect(() => {
    setGroupBattle(groupBattleOrigin);
  }, [groupBattleOrigin]);

  // モーダル表示制御関数
  const [deleteGroupBattleModalState, setDeleteGroupBattleModalState] =
    React.useState<{
      [key: string]: number;
    }>({});

  const onToggleEditMode = () => {
    setIsEditMode((state) => !state);
    if (!Object.keys(previous).length) {
      setPrevious((state) => ({ ...state, ...groupbattle }));
    }
  };

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    groupbattle: GroupBattle
  ) => {
    const value = event.target.value;
    const groupbattle_name = event.target.name;
    const newGroupBattle = {
      ...groupbattle,
      [groupbattle_name]: value,
    };
    setGroupBattle(newGroupBattle);
  };

  const onChangeMultiSelector = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    groupbattle: GroupBattle
  ) => {
    if (!Object.keys(previous).length) {
      setPrevious((state) => ({
        ...state,
        ...groupbattle,
      }));
    }
    if (!event.target.name) {
      showToast(
        'error',
        'データ取得に失敗しました',
        '管理者に連絡をお願いします',
        3000
      );
      return;
    }
    const value = battleevents.filter((battleevent) => {
      return (event.target.value as string[]).includes(
        battleevent.battleevents_name
      );
    });
    const groupbattle_name = event.target.name;
    const newGroupBattle = {
      ...groupbattle,
      [groupbattle_name]: value,
    };
    setGroupBattle(newGroupBattle);
  };

  const onConfirm = async () => {
    setPrevious((state) => {
      state = {} as GroupBattle;
      return state;
    });
    await updateGroupBattleMutate({
      groupbattle_id: groupbattle.groupbattle_id,
      groupbattle_name: groupbattle.groupbattle_name,
      groupbattle_subtitle: groupbattle.groupbattle_subtitle,
      start_date: dayjs(groupbattle.start_date)
        .startOf('day')
        .millisecond(0)
        .toDate(),
      end_date: dayjs(groupbattle.end_date)
        .endOf('day')
        .millisecond(0)
        .toDate(),
      battleevents: groupbattle.battleevents,
    });
    onToggleEditMode();
  };

  const onClickDeleteGroupBattleButton = async () => {
    await deleteGroupBattleMutate(deleteGroupBattleModalState.groupbattle_id);
    setDeleteGroupBattleModalState({});
  };

  return (
    <React.Fragment>
      {/* 対抗戦表示領域 */}
      <TableRow className={classes.root} hover role="checkbox" tabIndex={-1}>
        <TableCell component="th" scope="row" style={{ width: '20%' }}>
          {isEditMode ? (
            <TextField
              id="groupbattle_name"
              label="対抗戦名 *"
              onChange={(event) => onChange(event, groupbattle)}
              name="groupbattle_name"
              placeholder="対抗戦名 *"
              defaultValue={groupbattle.groupbattle_name}
              fullWidth={true}
            />
          ) : (
            <>{groupbattle.groupbattle_name}</>
          )}
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: '20%' }}>
          {isEditMode ? (
            <TextField
              id="groupbattle_subtitle"
              label="対抗戦詳細"
              onChange={(event) => onChange(event, groupbattle)}
              name="groupbattle_subtitle"
              placeholder="対抗戦詳細"
              defaultValue={groupbattle.groupbattle_subtitle}
              fullWidth={true}
            />
          ) : (
            <>{groupbattle.groupbattle_subtitle}</>
          )}
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: '20%' }}>
          {isEditMode ? (
            <FormControl
              className={classes.rowAreaformControl}
              fullWidth={true}>
              <InputLabel id="battleevents-name-label">競走種目</InputLabel>
              <Select
                labelId="battle-events-label"
                id="battle_events"
                label="競走種目"
                name="battleevents"
                multiple
                defaultValue={groupbattle.battleevents.map(
                  (event) => event.battleevents_name
                )}
                onChange={(event) => onChangeMultiSelector(event, groupbattle)}
                input={<Input />}>
                {battleevents.map((battleevent) => (
                  <MenuItem
                    key={battleevent.battleevents_id}
                    value={battleevent.battleevents_name}>
                    {battleevent.battleevents_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <List dense={true}>
              {groupbattle.battleevents.map((battleEvent, idx) => (
                <ListItem key={idx} dense={true} disableGutters={true}>
                  <ListItemText primary={battleEvent.battleevents_name} />
                </ListItem>
              ))}
            </List>
          )}
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: '10%' }}>
          {isEditMode ? (
            <TextField
              id="start_date"
              label="対抗戦開始日"
              type="date"
              onChange={(event) => onChange(event, groupbattle)}
              name="start_date"
              placeholder="対抗戦開始日"
              defaultValue={dayjs(groupbattle.start_date).format('YYYY-MM-DD')}
              fullWidth={true}
            />
          ) : (
            <>{dayjs(groupbattle.start_date).format('YYYY-MM-DD')}</>
          )}
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: '10%' }}>
          {isEditMode ? (
            <TextField
              id="end_date"
              label="対抗戦終了日"
              type="date"
              onChange={(event) => onChange(event, groupbattle)}
              name="end_date"
              placeholder="対抗戦終了日"
              defaultValue={dayjs(groupbattle.end_date).format('YYYY-MM-DD')}
              fullWidth={true}
            />
          ) : (
            <>{dayjs(groupbattle.end_date).format('YYYY-MM-DD')}</>
          )}
        </TableCell>
        <TableCell align="right" style={{ width: '5%' }}>
          <IconButton
            aria-label="delete"
            onClick={() =>
              setDeleteGroupBattleModalState({
                groupbattle_id: groupbattle.groupbattle_id,
              })
            }>
            <DeleteIcon color="secondary" />
          </IconButton>
        </TableCell>
        <TableCell align="right" style={{ width: '5%' }}>
          {isEditMode ? (
            <></>
          ) : (
            <Link to={`/groupbattledetail/${groupbattle.groupbattle_id}`}>
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </Link>
          )}
        </TableCell>
      </TableRow>
      <ModalMolecures
        isOpen={!!Object.keys(deleteGroupBattleModalState).length}
        onClose={() => setDeleteGroupBattleModalState({})}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          noWrap
          style={{ margin: '2%' }}>
          対抗戦削除
        </Typography>
        <hr></hr>
        <DialogContent>
          <DialogContentText>
            対抗戦を削除します
            <br />
            確認のため入力フォームに対抗戦の名前を入力してください
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="delete-confirm-form"
            label="確認"
            type="text"
            fullWidth
            onChange={(e) => {
              setDeleteConfirmation(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteGroupBattleModalState({})}
            color="primary">
            キャンセル
          </Button>
          <Button
            onClick={() => onClickDeleteGroupBattleButton()}
            color="secondary"
            disabled={groupbattle.groupbattle_name != deleteConfirmation}>
            削除
          </Button>
        </DialogActions>
      </ModalMolecures>
    </React.Fragment>
  );
}

const GroupBattlesList: React.FC = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchedText, setSearchedText] = useState('');
  const { showToast } = useToast();
  const { useGetGroupBattlesMutation } = useGroupBattles();
  const { useReadBattleEvents } = useBattleEvents();
  const {
    isLoading: groupbattleLoading,
    data: groupbattleData,
    isError: groupbattleIsError,
  } = useGetGroupBattlesMutation();

  const {
    isLoading: battleeventsLoading,
    data: battleeventsData,
    isError: battleeventsIsError,
  } = useReadBattleEvents();

  // モーダル表示制御関数
  const [createModalState, setCreateModalState] = React.useState(false);
  const [isCautionModalOpen, setisCautionModalOpen] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!groupbattleData || !battleeventsData) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (groupbattleLoading || battleeventsLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (groupbattleIsError || battleeventsIsError) {
    showToast(
      'error',
      'データ取得に失敗しました',
      '時間をおいて再度お試しください',
      3000
    );
    return (
      <MenuBarsTemplate title="">
        <></>
      </MenuBarsTemplate>
    );
  }
  function CreateGroupBattleModal(props: {
    isOpen: boolean;
    onClose: () => void;
  }) {
    const { isOpen, onClose } = props;
    const [createChatChecked, setCreateChatChecked] = useState(false);
    const [groupBattleNameOnCreate, setGroupBattleNameOnCreate] = useState('');
    const [groupBattleSubtitleOnCreate, setGroupBattleSubtitleOnCreate] =
      useState('');
    const defaultStartDay = dayjs()
      .add(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');
    const defaultEndDay = dayjs()
      .add(2, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');
    const battleevents = useContext(BattleEventsCtx);
    const battleEvent = battleevents.filter((event) => event.battleevents_name);
    const [startDateOnCreate, setStartDateOnCreate] = useState(defaultStartDay);
    const [endDateOnCreate, setEndDateOnCreate] = useState(defaultEndDay);
    const [battleEventsOnCreate, setBattleEventsOnCreate] =
      useState(battleEvent);
    const {
      useCreateGroupBattleMutation,
      useCreateGroupBattleChatGroupsMutation,
    } = useGroupBattles();
    const createGroupBattleMutate = useCreateGroupBattleMutation().mutate;
    const createGroupBattleChatGroupsMutate =
      useCreateGroupBattleChatGroupsMutation().mutate;
    const dateReset = () => {
      setisCautionModalOpen(false);
      setStartDateOnCreate(defaultStartDay);
      setEndDateOnCreate(defaultEndDay);
    };

    const dateValidate = (date: string, format: string) => {
      return dayjs(date, format).format(format) === date;
    };

    const handleCreateChatCheckedChange = (event: any) => {
      setCreateChatChecked(!createChatChecked);
    };

    const onClickCreateDepartmentButtleButton = async () => {
      // if (dayjs(endDateOnCreate)
      // .tz('Asia/Tokyo')
      // .startOf('day')
      // .toDate() >= dayjs(startDateOnCreate)
      // .tz('Asia/Tokyo')
      // .startOf('day')
      // .toDate()) {
      if (createChatChecked) {
        await createGroupBattleChatGroupsMutate({
          groupbattle_name: groupBattleNameOnCreate,
          groupbattle_subtitle: groupBattleSubtitleOnCreate,
          start_date: dayjs(startDateOnCreate)
            .startOf('day')
            .millisecond(0)
            .toDate(),
          end_date: dayjs(endDateOnCreate).endOf('day').millisecond(0).toDate(),
          //avatar: imageURL == "" ? imageData![0] : imageURL,
          battleevents: battleEventsOnCreate,
        });
      } else {
        await createGroupBattleMutate({
          groupbattle_name: groupBattleNameOnCreate,
          groupbattle_subtitle: groupBattleSubtitleOnCreate,
          start_date: dayjs(startDateOnCreate)
            .startOf('day')
            .millisecond(0)
            .toDate(),
          end_date: dayjs(endDateOnCreate).endOf('day').millisecond(0).toDate(),
          battleevents: battleEventsOnCreate,
        });
      }
      onClose();
      setGroupBattleNameOnCreate('');
      setGroupBattleSubtitleOnCreate('');
      setStartDateOnCreate(
        dayjs().add(1, 'month').startOf('month').format('YYYY-MM-DD')
      );
      setEndDateOnCreate(
        dayjs().add(2, 'month').startOf('month').format('YYYY-MM-DD')
      );
      setBattleEventsOnCreate([] as BattleEvent[]);
      // }else{
      //   setisCautionModalOpen(true);
      // }
      // dateReset();
    };

    return (
      <>
        <ModalMolecures isOpen={isOpen} onClose={onClose}>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            noWrap
            style={{ margin: '2%' }}>
            新規対抗戦作成
          </Typography>
          <hr></hr>
          <DialogContent>
            <DialogContentText>
              新たに作成する対抗戦の情報を入力してください
              <br />
              ※(開始日の0時から終了日の24時までが対抗戦の集計期間になります)
            </DialogContentText>
            <TextField
              value={groupBattleNameOnCreate}
              onChange={(event) =>
                setGroupBattleNameOnCreate(event.target.value)
              }
              onKeyPress={(e) => {
                if (
                  e.key == 'Enter' &&
                  groupBattleNameOnCreate !== '' &&
                  groupBattleNameOnCreate.length <= 100 &&
                  dayjs(endDateOnCreate)
                    .tz('Asia/Tokyo')
                    .startOf('day')
                    .toDate() >
                    dayjs(startDateOnCreate)
                      .tz('Asia/Tokyo')
                      .startOf('day')
                      .toDate() &&
                  battleEventsOnCreate.length > 0
                ) {
                  e.preventDefault();
                  onClickCreateDepartmentButtleButton();
                }
              }}
              autoFocus
              margin="dense"
              label="対抗戦名 *"
              fullWidth={true}
              inputProps={{ maxLength: 100 }}
              helperText={
                groupBattleNameOnCreate.length == 100
                  ? '入力は100文字までです'
                  : ''
              }
            />
            <TextField
              value={groupBattleSubtitleOnCreate}
              onChange={(event) =>
                setGroupBattleSubtitleOnCreate(event.target.value)
              }
              onKeyPress={(e) => {
                if (
                  e.key == 'Enter' &&
                  groupBattleNameOnCreate !== '' &&
                  groupBattleNameOnCreate.length <= 100 &&
                  dayjs(endDateOnCreate)
                    .tz('Asia/Tokyo')
                    .startOf('day')
                    .toDate() >
                    dayjs(startDateOnCreate)
                      .tz('Asia/Tokyo')
                      .startOf('day')
                      .toDate() &&
                  battleEventsOnCreate.length > 0
                ) {
                  e.preventDefault();
                  onClickCreateDepartmentButtleButton();
                }
              }}
              margin="dense"
              label="サブタイトル"
              fullWidth={true}
              inputProps={{ maxLength: 100 }}
              helperText={
                groupBattleSubtitleOnCreate.length == 100
                  ? '入力は100文字までです'
                  : ''
              }
            />
            <Grid container>
              <Grid item xs={5}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="開始日"
                  type="date"
                  onChange={(event) => {
                    if (!event.target.value) {
                      event.target.value = startDateOnCreate;
                    }
                    if (dateValidate(event.target.value, 'YYYY-MM-DD')) {
                      setStartDateOnCreate(event.target.value);
                    }
                  }}
                  onKeyPress={(e) => {
                    if (
                      e.key == 'Enter' &&
                      groupBattleNameOnCreate !== '' &&
                      groupBattleNameOnCreate.length <= 100 &&
                      dayjs(endDateOnCreate)
                        .tz('Asia/Tokyo')
                        .startOf('day')
                        .toDate() >
                        dayjs(startDateOnCreate)
                          .tz('Asia/Tokyo')
                          .startOf('day')
                          .toDate() &&
                      battleEventsOnCreate.length > 0
                    ) {
                      e.preventDefault();
                      onClickCreateDepartmentButtleButton();
                    }
                  }}
                  name="start_date"
                  placeholder="開始日"
                  defaultValue={dayjs(startDateOnCreate).format('YYYY-MM-DD')}
                  fullWidth={true}
                  helperText={
                    dayjs(endDateOnCreate)
                      .tz('Asia/Tokyo')
                      .startOf('day')
                      .toDate() <=
                    dayjs(startDateOnCreate)
                      .tz('Asia/Tokyo')
                      .startOf('day')
                      .toDate()
                      ? '終了日よりも前にしてください'
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={5}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="終了日"
                  type="date"
                  onChange={(event) => {
                    if (!event.target.value) {
                      event.target.value = endDateOnCreate;
                    }
                    if (dateValidate(event.target.value, 'YYYY-MM-DD')) {
                      setEndDateOnCreate(event.target.value);
                    }
                  }}
                  onKeyPress={(e) => {
                    if (
                      e.key == 'Enter' &&
                      groupBattleNameOnCreate !== '' &&
                      groupBattleNameOnCreate.length <= 100 &&
                      dayjs(endDateOnCreate)
                        .tz('Asia/Tokyo')
                        .startOf('day')
                        .toDate() >
                        dayjs(startDateOnCreate)
                          .tz('Asia/Tokyo')
                          .startOf('day')
                          .toDate() &&
                      battleEventsOnCreate.length > 0
                    ) {
                      e.preventDefault();
                      onClickCreateDepartmentButtleButton();
                    }
                  }}
                  name="end_date"
                  placeholder="終了日"
                  defaultValue={dayjs(endDateOnCreate).format('YYYY-MM-DD')}
                  fullWidth={true}
                  helperText={
                    dayjs(endDateOnCreate)
                      .tz('Asia/Tokyo')
                      .startOf('day')
                      .toDate() <=
                    dayjs(startDateOnCreate)
                      .tz('Asia/Tokyo')
                      .startOf('day')
                      .toDate()
                      ? '開始日よりも後にしてください'
                      : ''
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <FormControlLabel
                label="チャットグループ作成"
                labelPlacement="end"
                control={
                  <Checkbox
                    checked={createChatChecked}
                    onChange={handleCreateChatCheckedChange}
                    color="primary"
                  />
                }
              />
            </Grid>
            {/* <FormControl fullWidth={true}>
              <InputLabel>競走種目 *</InputLabel>
              <Select
                labelId="battle-events-label"
                label="競走種目 *"
                defaultValue={battleEventsOnCreate}
                multiple
                onChange={(event) =>
                  setBattleEventsOnCreate(
                    battleevents.filter((battleevent) => {
                      return (event.target.value as string[]).includes(
                        battleevent.battleevents_name
                      );
                    })
                  )
                }
                input={<Input />}>
                {battleevents.map((battleevent) => (
                  <MenuItem
                    key={battleevent.battleevents_id}
                    value={battleevent.battleevents_name}>
                    {battleevent.battleevents_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              キャンセル
            </Button>
            <Button
              onClick={() => onClickCreateDepartmentButtleButton()}
              color="primary"
              disabled={
                groupBattleNameOnCreate === '' ||
                battleEventsOnCreate.length === 0 ||
                battleEventsOnCreate.length >= 100 ||
                dayjs(endDateOnCreate)
                  .tz('Asia/Tokyo')
                  .startOf('day')
                  .toDate() <=
                  dayjs(startDateOnCreate)
                    .tz('Asia/Tokyo')
                    .startOf('day')
                    .toDate() ||
                battleEventsOnCreate.length === 0
              }>
              決定
            </Button>
          </DialogActions>
        </ModalMolecures>

        <ModalMolecures
          isOpen={isCautionModalOpen}
          onClose={() => setisCautionModalOpen(false)}>
          <DialogContent>
            <DialogContentText>
              開始日は終了日より早くしてください
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setisCautionModalOpen(false)}
              color="primary"
              disabled={false}>
              OK
            </Button>
          </DialogActions>
        </ModalMolecures>
      </>
    );
  }

  return (
    <>
      <BattleEventsCtx.Provider value={battleeventsData}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Typography
            color="textSecondary"
            variant="h5"
            gutterBottom
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
            component="div">
            <Button
              className={classes.createDepartmentButton}
              variant="outlined"
              vertical-align="center"
              size="small"
              onClick={() => {
                setCreateModalState(true);
              }}>
              <AddCircleOutlineIcon />
              新規対抗戦作成
            </Button>
          </Typography>
          <Autocomplete
            freeSolo
            id="free-solo"
            disableClearable
            onChange={(event, newValue) => setSearchedText(newValue)}
            style={{ width: '25vw' }}
            options={groupbattleData.map((option) => option.groupbattle_name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="対抗戦検索"
                margin="normal"
                variant="outlined"
                value={searchedText}
                InputProps={{ ...params.InputProps, type: 'search' }}
                onChange={(event) => setSearchedText(event.target.value)}
              />
            )}
          />
        </div>
        <br />
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '20%' }}>バトル名</TableCell>
                  <TableCell style={{ width: '20%' }} align="left">
                    サブタイトル
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>競走種目</TableCell>
                  <TableCell style={{ width: '10%' }}>開始日</TableCell>
                  <TableCell style={{ width: '10%' }}>終了日</TableCell>
                  {/* <TableCell align="center" style={{ width: '10%' }}>
                    編集
                  </TableCell> */}
                  <TableCell align="center" style={{ width: '5%' }}>
                    削除
                  </TableCell>
                  <TableCell align="center" style={{ width: '5%' }}>
                    詳細
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupbattleData
                  .filter(
                    (groupbattle) =>
                      groupbattle.groupbattle_name.includes(searchedText) ||
                      groupbattle.groupbattle_subtitle.includes(searchedText)
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((groupbattle) => (
                    <GroupBattleRow
                      key={groupbattle.groupbattle_id}
                      groupbattle={groupbattle}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={groupbattleData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        <CreateGroupBattleModal
          isOpen={createModalState}
          onClose={() => setCreateModalState(false)}></CreateGroupBattleModal>
      </BattleEventsCtx.Provider>
    </>
  );
};

export default GroupBattlesList;
