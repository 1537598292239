import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import LoadingBubbleOrganisms from '../components/organisms/LoadingBubbleOrganisms';
import AuthTemplate from '../components/templates/AuthTemplate';
import MenuBarsTemplate from '../components/templates/MenuBarsTemplate';
import { useAuth } from '../controllers/services/useAuth';

const Public = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<Route<RouteProps>> &
    Readonly<RouteProps> &
    Readonly<{ children?: React.ReactNode }>
) => {
  const { useGetAuth } = useAuth();
  const { isLoading, data, isError, error } = useGetAuth();

  if (isLoading) {
    return (
      // <AuthTemplate>
      //   <></>
      // </AuthTemplate>
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }
  if (data !== undefined && Object.keys(data).length) {
    return <Redirect to={'/dashboard'} />;
  }

  // if (Date.now() < Number(localStorage.getItem('exp')) * 1000) {
  //   localStorage.removeItem('token');
  //   return <Redirect to={'/dashboard'} />;
  // }

  return <Route {...props} />;
};
export default Public;
