import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import MenuBarsTemplate from '../../templates/MenuBarsTemplate';
import ModalMolecures from '../../molecules/ModalMolecules';
import LoadingBubbleOrganisms from '../../organisms/LoadingBubbleOrganisms';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DoneIcon from '@material-ui/icons/DoneAllTwoTone';
import RevertIcon from '@material-ui/icons/NotInterestedOutlined';
import { LoginUserContext } from '../../../utils/CommonContext';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  Box,
  Collapse,
} from '@material-ui/core';
import dayjs from 'dayjs';
import 'date-fns';
import { useToast } from '../../molecules/toastMolecules';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useUser } from '../../../controllers/services/useUser';
import { User } from '../../../utils/interfaces/User';
import { useChatgroups } from '../../../controllers/services/useChatgroups';
import { Chatgroups } from '../../../utils/interfaces/Chatgroups';
const ChatgroupsEventsCtx = createContext<Chatgroups[]>([]);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    container: {
      maxHeight: 2000,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      height: 600,
      width: 500,
      padding: 20,
      outline: 'none',
      borderRadius: 10,
    },
    button: {
      height: 50,
      width: 200,
      marginBottom: 30,
      backgroundColor: 'textSecondary',
      outline: 'none',
    },
    form: {
      height: 50,
      width: '80%',
      marginTop: 20,
      marginLeft: 45,
    },
    formControl: {
      marginBottom: 100,
      width: '5%',
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    deleteModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: 'white',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    EditButton: {
      marginLeft: theme.spacing(2),
    },
    rowAreaformControl: {
      margin: theme.spacing(1),
      maxWidth: '200px',
    },
    createDepartmentButton: {
      marginLeft: theme.spacing(2),
    },
    createCompanyBattleMemberButton: {
      marginLeft: theme.spacing(2),
    },
  })
);

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#8fc31f' }, // テーマの色
  },
});
function ChatgroupsEditModal(props: {
  isOpen: boolean;
  onClose: () => void;
  chatgroupsOrigin: Chatgroups;
}) {
  const { isOpen, onClose, chatgroupsOrigin } = props;
  const [chatgroups, setChatgroups] = useState(chatgroupsOrigin);
  const [chatgroupsUsers, setChatgroupsUsers] =
    React.useState<Chatgroups>(chatgroupsOrigin);
  const { usePutChatgroupsMemberMutation } = useChatgroups();
  const putChatgroupsUsersMutate = usePutChatgroupsMemberMutation().mutate;
  const { useGetUserMutation } = useUser();
  const [searchedUsersName, setSearchedUsersName] = useState('');
  const [searchedUsersId, setSearchedUsersId] = useState('');
  const [searchedUsersEmail, setSearchedUsersEmail] = useState('');
  const [searchedJoinedUsersName, setSearchedJoinedUsersName] = useState('');
  const [searchedJoinedUsersId, setSearchedJoinedUsersId] = useState('');
  const [searchedJoinedUsersEmail, setSearchedJoinedUsersEmail] = useState('');
  const loginUser = useContext(LoginUserContext);
  const [searchedJoinedUsersRname, setSearchedJoinedUsersRname] = useState('');
  const [searchedUsersRname, setSearchedUsersRname] = useState('');
  const { useCreateChatgroupsMutation } = useChatgroups();
  const createChatGroupsMutate = useCreateChatgroupsMutation().mutate;
  const [createChatChecked, setCreateChatChecked] = useState(false);

  useEffect(() => {
    setChatgroupsUsers(chatgroupsOrigin);
  }, [chatgroupsOrigin]);

  const { data: usersData } = useGetUserMutation();

  const addUsersToChatgroups = async (user: User) => {
    setChatgroupsUsers((state) => {
      return {
        ...state,
        users: [...state.users, user],
      };
    });
  };

  const addAllUsersToChatgroups = async () => {
    searchedResultUsers?.map((user) => {
      setChatgroupsUsers((state) => {
        return {
          ...state,
          users: [...state.users, user],
        };
      });
    });
  };

  const removeUsersFromChatgroups = async (user: User) => {
    setChatgroupsUsers((state) => {
      return {
        ...state,
        users: state.users.filter((usersRow) => usersRow.uid !== user.uid),
      };
    });
  };

  const removeAllUsersFromChatgroups = async () => {
    searchResultUsers.map((user) => {
      setChatgroupsUsers((state) => {
        return {
          ...state,
          users: state.users.filter((userRow) => userRow.uid !== user.uid),
        };
      });
    });
  };

  const confirmChatgroups = async () => {
    await putChatgroupsUsersMutate(chatgroupsUsers);
    onClose();
  };

  const cancelEditChatgroups = async () => {
    setChatgroupsUsers(chatgroupsOrigin);
    onClose();
  };
  const notPerticipatedUsers = usersData?.filter(
    (user) =>
      !chatgroupsUsers.users?.find(
        (unJoinedUsers) => unJoinedUsers.uid === user.uid
      )
  );

  const searchedResultUsers = notPerticipatedUsers?.filter(
    (user) =>
      user.uname.includes(searchedUsersName) &&
      user.employeeId?.toString().includes(searchedUsersId) &&
      user.email.includes(searchedUsersEmail) &&
      user.rname.includes(searchedUsersRname)
  );

  const clearJoinedUsersSearch = () => {
    setSearchedJoinedUsersName('');
    setSearchedJoinedUsersId('');
    setSearchedJoinedUsersEmail('');
    setSearchedJoinedUsersRname('');
  };

  const clearUsersSearch = () => {
    setSearchedUsersName('');
    setSearchedUsersId('');
    setSearchedUsersEmail('');
    setSearchedUsersRname('');
  };
  const searchResultUsers = chatgroupsUsers.users?.filter(
    (user) =>
      user.uname.includes(searchedJoinedUsersName) &&
      user.employeeId.toString().includes(searchedJoinedUsersId) &&
      user.email.includes(searchedJoinedUsersEmail) &&
      user.rname.includes(searchedJoinedUsersRname)
  );

  return (
    <ThemeProvider theme={theme}>
      <ModalMolecures
        isOpen={isOpen}
        onClose={() => false}
        fullWidth={true}
        maxWidth={false}>
        <br></br>
        <Typography
          color="textSecondary"
          variant="h5"
          gutterBottom
          component="div">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;参加ユーザー編集
        </Typography>
        <hr></hr>
        <Grid container spacing={0} style={{ width: '80vw' }}>
          <Grid item xs={6}>
            <DialogContent>
              <DialogContentText>
                トークグループに参加しているユーザー
              </DialogContentText>
              <Table
                size="small"
                aria-label="purchases"
                style={{ marginBottom: '2%' }}>
                <TableBody>
                  <TableRow>
                    <TableCell size="small" style={{ width: '30%' }}>
                      <TextField
                        id="searchedJoinedUsersName"
                        label="ニックネーム"
                        value={searchedJoinedUsersName}
                        onChange={(event) =>
                          setSearchedJoinedUsersName(event.target.value)
                        }
                        name="usersName"
                        placeholder="ニックネーム"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell size="small" style={{ width: '30%' }}>
                      <TextField
                        id="searchedJoinedUsersRname"
                        label="社員名"
                        value={searchedJoinedUsersRname}
                        onChange={(event) =>
                          setSearchedJoinedUsersRname(event.target.value)
                        }
                        name="usersRname"
                        placeholder="社員名"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell size="small" style={{ width: '30%' }}>
                      <TextField
                        id="searchedJoinedUsersId"
                        label="社員番号"
                        value={searchedJoinedUsersId}
                        onChange={(event) =>
                          setSearchedJoinedUsersId(event.target.value)
                        }
                        name="userId"
                        placeholder="社員番号"
                        fullWidth={true}
                      />
                    </TableCell>
                    {/* <TableCell style={{ width: '50%' }}>
                      <TextField
                        id="searchedJoinedUsersEmail"
                        label="メールアドレス"
                        value={searchedJoinedUsersEmail}
                        onChange={(event) =>
                          setSearchedJoinedUsersEmail(event.target.value)
                        }
                        name="userId"
                        placeholder="メールアドレス"
                        fullWidth={true}
                      />
                    </TableCell> */}
                    <TableCell align="right" style={{ width: '10%' }}>
                      <IconButton aria-label="delete">
                        <HighlightOffIcon
                          onClick={() => clearJoinedUsersSearch()}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <TableContainer style={{ height: '50vh' }}>
                <Table
                  stickyHeader
                  size="small"
                  aria-label="perticipatedUsersTable">
                  <TableHead>
                    <TableRow>
                      <TableCell>ニックネーム</TableCell>
                      <TableCell>社員名</TableCell>
                      <TableCell>社員番号</TableCell>
                      <TableCell align="center">削除</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchResultUsers
                      ?.filter(
                        (user) =>
                          user.companyid.toString() === loginUser.company_id
                      )
                      .map((user) => (
                        <TableRow key={chatgroups.id + '-' + user.uid}>
                          <TableCell component="th" scope="row">
                            {user.uname}
                          </TableCell>
                          <TableCell>{user.rname}</TableCell>
                          <TableCell>{user.employeeId}</TableCell>
                          <TableCell align="center">
                            <IconButton
                              aria-label="delete"
                              onClick={() => removeUsersFromChatgroups(user)}>
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => removeAllUsersFromChatgroups()}
                color="secondary">
                <DeleteIcon color="secondary" />
                一括削除
              </Button>
            </DialogActions>
          </Grid>
          <Grid item xs={6}>
            <DialogContent>
              <DialogContentText>
                トークグループに追加するユーザーを設定してください
              </DialogContentText>
              <Table
                size="small"
                aria-label="purchases"
                style={{ marginBottom: '2%' }}>
                <TableBody>
                  <TableRow>
                    <TableCell size="small" style={{ width: '30%' }}>
                      <TextField
                        id="searchedUsersName"
                        label="ニックネーム"
                        value={searchedUsersName}
                        onChange={(event) =>
                          setSearchedUsersName(event.target.value)
                        }
                        name="usersName"
                        placeholder="ニックネーム"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell size="small" style={{ width: '30%' }}>
                      <TextField
                        id="searchedUsersName"
                        label="社員名"
                        value={searchedUsersRname}
                        onChange={(event) =>
                          setSearchedUsersRname(event.target.value)
                        }
                        name="usersRname"
                        placeholder="社員名"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell size="small" style={{ width: '30%' }}>
                      <TextField
                        id="searchedUsersId"
                        label="社員番号"
                        value={searchedUsersId}
                        onChange={(event) =>
                          setSearchedUsersId(event.target.value)
                        }
                        name="userId"
                        placeholder="社員番号"
                        fullWidth={true}
                      />
                    </TableCell>
                    {/* <TableCell style={{ width: '50%' }}>
                      <TextField
                        id="searchedUsersEmail"
                        label="メールアドレス"
                        value={searchedUsersEmail}
                        onChange={(event) =>
                          setSearchedUsersEmail(event.target.value)
                        }
                        name="userId"
                        placeholder="メールアドレス"
                        fullWidth={true}
                      />
                    </TableCell> */}
                    <TableCell align="right" style={{ width: '10%' }}>
                      <IconButton aria-label="delete">
                        <HighlightOffIcon onClick={() => clearUsersSearch()} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <TableContainer style={{ height: '50vh' }}>
                <Table stickyHeader size="small" aria-label="search-result">
                  <TableHead>
                    <TableRow>
                      <TableCell>ニックネーム</TableCell>
                      <TableCell>社員名</TableCell>
                      <TableCell>社員番号</TableCell>
                      <TableCell align="center">追加</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchedResultUsers?.map((user, idx) => (
                      <TableRow key={idx}>
                        <TableCell component="th">{user.uname}</TableCell>
                        <TableCell>{user.rname}</TableCell>
                        <TableCell>{user.employeeId}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="add"
                            onClick={() => addUsersToChatgroups(user)}>
                            <AddCircleOutlineIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => addAllUsersToChatgroups()} color="primary">
                <AddCircleOutlineIcon color="primary" />
                一括追加
              </Button>
            </DialogActions>
            <DialogActions>
              <Button
                onClick={() => cancelEditChatgroups()}
                color="primary"
                variant="contained">
                キャンセル
              </Button>
              <Button
                onClick={() => confirmChatgroups()}
                color="primary"
                variant="contained">
                決定
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </ModalMolecures>
    </ThemeProvider>
  );
}

function ChatgroupsRow(props: { chatgroups: Chatgroups }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { chatgroups: chatgroupsOrigin } = props;
  const [chatgroups, setChatgroups] = useState<Chatgroups>(chatgroupsOrigin);
  const loginUser = useContext(LoginUserContext);
  const { useUpdateChatgroupsMutation, useDeleteChatgroupsMutation } =
    useChatgroups();
  const updateChatgroupsMutate = useUpdateChatgroupsMutation().mutate;
  const deleteChatgroupsMutate = useDeleteChatgroupsMutation().mutate;
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [previous, setPrevious] = React.useState({} as Chatgroups);

  useEffect(() => {
    setChatgroups(chatgroupsOrigin);
  }, [chatgroupsOrigin]);

  const [chatgroupsMemberEditModalState, setChatgroupsMemberEditModalState] =
    React.useState(false);
  const [userEditModalState, setUserEditModalState] = React.useState(false);
  const [deleteChatgroupsModalState, setDeleteChatgroupsModalState] =
    React.useState<{
      [key: string]: number;
    }>({});

  const onRevert = () => {
    setChatgroups(previous);
    setPrevious((state) => {
      state = {} as Chatgroups;
      return state;
    });
    onToggleEditMode();
  };

  const onToggleEditMode = () => {
    setIsEditMode((state) => !state);
    if (!Object.keys(previous).length) {
      setPrevious((state) => ({ ...state, ...chatgroups }));
    }
  };

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    chatgroups: Chatgroups
  ) => {
    const value = event.target.value;
    const name = event.target.name;
    const { id } = chatgroups;
    const newChatgroups = {
      ...chatgroups,
      [name]: value,
    };
    setChatgroups(newChatgroups);
  };

  const onConfirm = async () => {
    setPrevious((state) => {
      state = {} as Chatgroups;
      return state;
    });

    await updateChatgroupsMutate({
      id: chatgroups.id,
      name: chatgroups.name,
      description: chatgroups.description,
    });
    onToggleEditMode();
  };

  const onClickDeleteChatgroups = async () => {
    await deleteChatgroupsMutate(deleteChatgroupsModalState.id);
    setDeleteChatgroupsModalState({});
  };

  return chatgroups.is_invalidated ? null : (
    <>
      {/* トークグループ一覧表示領域 */}
      <TableRow className={classes.root} hover role="checkbox" tabIndex={-1}>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            color="default"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {/* トークグループ名＋編集 */}
        <TableCell component="th" scope="row" style={{ width: '20%' }}>
          {isEditMode ? (
            <TextField
              id="standard-basic"
              label="トークグループ名"
              inputProps={{ maxLength: 100, minLength: 1 }}
              onChange={(event) => onChange(event, chatgroups)}
              onKeyPress={(e) => {
                if (
                  e.key == 'Enter' &&
                  chatgroups.name.length <= 100 &&
                  chatgroups.name.length > 0
                ) {
                  e.preventDefault();
                  onConfirm();
                }
              }}
              helperText={
                chatgroups.name.length < 1 || chatgroups.name.length > 100
                  ? 'グループ名は1〜100文字で指定してください。'
                  : ''
              }
              name="name"
              placeholder="トークグループ名"
              autoComplete="off"
              defaultValue={chatgroups.name}
              fullWidth={true}
            />
          ) : (
            <div>{chatgroups.name}</div>
          )}
        </TableCell>

        {/* グループの説明＋編集 */}
        <TableCell component="th" scope="row" style={{ width: '20%' }}>
          {isEditMode ? (
            <TextField
              id="standard-basic"
              label="グループの説明"
              inputProps={{ maxLength: 100, minLength: 1 }}
              onChange={(event) => onChange(event, chatgroups)}
              onKeyPress={(e) => {
                if (
                  e.key == 'Enter' &&
                  chatgroups.description.length <= 100 &&
                  chatgroups.description.length > 0
                ) {
                  e.preventDefault();
                  onConfirm();
                }
              }}
              helperText={
                chatgroups.name.length < 1 || chatgroups.name.length > 100
                  ? '説名は1〜100文字で指定してください。'
                  : ''
              }
              name="description"
              placeholder="グループの説明"
              autoComplete="off"
              defaultValue={chatgroups.description}
              fullWidth={true}
            />
          ) : (
            <div>{chatgroups.description}</div>
          )}
        </TableCell>

        {/* 参加人数 */}
        <TableCell component="th" scope="row" style={{ width: '20%' }}>
          <>{chatgroupsOrigin.users.length}</>
        </TableCell>
        {/* 作成日 */}
        <TableCell component="th" scope="row" style={{ width: '10%' }}>
          <>{dayjs(chatgroupsOrigin.createdat).format('YYYY-MM-DD')}</>
        </TableCell>

        {/* 編集アイコン */}
        <TableCell align="center">
          {isEditMode ? (
            <>
              <IconButton
                aria-label="done"
                onClick={() => {
                  if (
                    chatgroups.name.length > 0 &&
                    chatgroups.name.length <= 100
                  ) {
                    onConfirm();
                  }
                }}>
                <DoneIcon
                  color={
                    chatgroups.name.length > 0 && chatgroups.name.length <= 100
                      ? 'primary'
                      : 'disabled'
                  }
                />
              </IconButton>
              <IconButton aria-label="revert" onClick={() => onRevert()}>
                <RevertIcon color="secondary" />
              </IconButton>
            </>
          ) : (
            <IconButton aria-label="edit" onClick={() => onToggleEditMode()}>
              <EditIcon color="primary" />
            </IconButton>
          )}
        </TableCell>

        {/* 削除アイコン */}
        <TableCell align="right">
          <IconButton
            aria-label="delete"
            onClick={() =>
              setDeleteChatgroupsModalState({ id: chatgroups.id })
            }>
            <DeleteIcon color="secondary" />
          </IconButton>
        </TableCell>
      </TableRow>
      {/* 参加ユーザ表示領域 */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                参加メンバー
                <Button
                  className={classes.createCompanyBattleMemberButton}
                  variant="outlined"
                  vertical-align="center"
                  size="small"
                  onClick={() => {
                    setChatgroupsMemberEditModalState(true);
                  }}>
                  <EditIcon />
                  編集
                </Button>
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '15%' }}>氏名</TableCell>
                    <TableCell style={{ width: '15%' }}>社員番号</TableCell>
                    <TableCell style={{ width: '70%' }}>メール</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chatgroupsOrigin.users
                    ?.filter(
                      (user) =>
                        user.companyid.toString() === loginUser.company_id
                    )
                    .map((user) => (
                      <TableRow key={chatgroupsOrigin.id + '-' + user.uid}>
                        <TableCell component="th" scope="row">
                          {user.uname}
                        </TableCell>
                        <TableCell>{user.employeeId}</TableCell>
                        <TableCell>{user.email}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ChatgroupsEditModal
        isOpen={chatgroupsMemberEditModalState}
        onClose={() => setChatgroupsMemberEditModalState(false)}
        chatgroupsOrigin={chatgroupsOrigin}></ChatgroupsEditModal>
      <ModalMolecures
        isOpen={!!Object.keys(deleteChatgroupsModalState).length}
        onClose={() => setDeleteChatgroupsModalState({})}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          noWrap
          style={{ margin: '2%' }}>
          グループ削除
        </Typography>
        <hr></hr>
        <DialogContent>
          <DialogContentText>
            グループを削除します、よろしいですか
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteChatgroupsModalState({})}
            color="primary">
            キャンセル
          </Button>
          <Button onClick={() => onClickDeleteChatgroups()} color="secondary">
            削除
          </Button>
        </DialogActions>
      </ModalMolecures>
    </>
  );
}
const ChatgroupsList: React.FC = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [createChatgroupsTitleName, setCreateChatgroupsTitleName] =
    useState('');
  const [createChatgroupsSubtitleName, setCreateChatgroupsSubtitleName] =
    useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchedText, setSearchedText] = useState('');
  const { showToast } = useToast();
  const { useGetChatgroupsMutation, useCreateChatgroupsMutation } =
    useChatgroups();
  const createChatgroupsMutate = useCreateChatgroupsMutation().mutate;

  const {
    isLoading: chatgroupsLoading,
    data: chatgroupsData,
    isError: chatgroupsIsError,
  } = useGetChatgroupsMutation();

  const [createModalState, setCreateModalState] = React.useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onClickCreateChatgroups = async () => {
    await createChatgroupsMutate({
      name: createChatgroupsTitleName,
      description: createChatgroupsSubtitleName,
    });
    setCreateModalState(false);
    setCreateChatgroupsTitleName('');
    setCreateChatgroupsSubtitleName('');
  };

  const onClickCloseChatgroups = () => {
    setCreateModalState(false);
    setCreateChatgroupsTitleName('');
    setCreateChatgroupsSubtitleName('');
  };

  if (!chatgroupsData) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (chatgroupsLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (chatgroupsIsError) {
    showToast(
      'error',
      'データ取得に失敗しました',
      '時間をおいて再度お試しください',
      3000
    );
    return (
      <MenuBarsTemplate title="">
        <></>
      </MenuBarsTemplate>
    );
  }

  return (
    <>
      <ChatgroupsEventsCtx.Provider value={chatgroupsData}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Typography
            color="textSecondary"
            variant="h5"
            gutterBottom
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
            component="div">
            <Button
              className={classes.createDepartmentButton}
              variant="outlined"
              vertical-align="center"
              size="small"
              onClick={() => {
                setCreateModalState(true);
              }}>
              <AddCircleOutlineIcon />
              トークグループ作成
            </Button>
          </Typography>
          <Autocomplete
            freeSolo
            id="free-solo"
            disableClearable
            onChange={(event, newValue) => setSearchedText(newValue)}
            style={{ width: '25vw' }}
            options={chatgroupsData.map((option) => option.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="トークグループ検索"
                margin="normal"
                variant="outlined"
                value={searchedText}
                InputProps={{ ...params.InputProps, type: 'search' }}
                onChange={(event) => setSearchedText(event.target.value)}
              />
            )}
          />
        </div>
        <br />
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '5%' }}></TableCell>
                  <TableCell style={{ width: '20%' }}>
                    トークグループ名
                  </TableCell>
                  <TableCell style={{ width: '20%' }} align="left">
                    グループの説明
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>参加人数</TableCell>
                  <TableCell style={{ width: '10%' }}>作成日</TableCell>
                  <TableCell align="center" style={{ width: '10%' }}>
                    編集
                  </TableCell>
                  <TableCell align="center" style={{ width: '5%' }}>
                    削除
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chatgroupsData
                  ?.filter(
                    (Chatgroups) =>
                      Chatgroups.name?.includes(searchedText) ||
                      Chatgroups.description?.includes(searchedText)
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((Chatgroups) => (
                    <ChatgroupsRow
                      key={Chatgroups.id}
                      chatgroups={Chatgroups}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={chatgroupsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        <ModalMolecures
          isOpen={createModalState}
          onClose={() => setCreateModalState(false)}>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            noWrap
            style={{ margin: '2%' }}>
            トークグループ作成
          </Typography>
          <hr></hr>
          <DialogContent>
            <DialogContentText>
              新たに作成するトークグループの情報を入力してください{' '}
            </DialogContentText>
            <TextField
              value={createChatgroupsTitleName}
              inputProps={{ maxLength: 60, minLength: 1 }}
              helperText={
                createChatgroupsTitleName.length < 1 ||
                createChatgroupsTitleName.length >= 60
                  ? ''
                  : // トークグループ名は60文字までで指定してください。
                    ''
              }
              onChange={(event) =>
                setCreateChatgroupsTitleName(event.target.value)
              }
              onKeyPress={(e) => {
                if (
                  e.key == 'Enter' &&
                  createChatgroupsTitleName !== '' &&
                  createChatgroupsTitleName.length <= 60
                ) {
                  e.preventDefault();
                  onClickCreateChatgroups();
                }
              }}
              autoComplete="off"
              autoFocus
              margin="dense"
              id="inquiry-team-name"
              label="トークグループ名"
              fullWidth={true}
            />
            <TextField
              value={createChatgroupsSubtitleName}
              inputProps={{ maxLength: 60, minLength: 1 }}
              helperText={
                createChatgroupsSubtitleName.length < 1 ||
                createChatgroupsSubtitleName.length >= 60
                  ? ''
                  : // グループ説明は60文字までで指定してください。
                    ''
              }
              onChange={(event) =>
                setCreateChatgroupsSubtitleName(event.target.value)
              }
              onKeyPress={(e) => {
                if (
                  e.key == 'Enter' &&
                  createChatgroupsSubtitleName !== '' &&
                  createChatgroupsSubtitleName.length <= 60
                ) {
                  e.preventDefault();
                  onClickCreateChatgroups();
                }
              }}
              autoComplete="off"
              margin="dense"
              id="inquiry-team-name"
              label="グループの説明"
              fullWidth={true}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClickCloseChatgroups} color="primary">
              キャンセル
            </Button>
            <Button
              onClick={() => onClickCreateChatgroups()}
              color="primary"
              disabled={createChatgroupsTitleName === ''}>
              決定
            </Button>
          </DialogActions>
        </ModalMolecures>
      </ChatgroupsEventsCtx.Provider>
    </>
  );
};

export default ChatgroupsList;
