import React, { useState } from 'react';
import { useHealthReports } from '../../controllers/services/useHealthReports';
import { Link } from 'react-router-dom';
import MenuBarsTemplate from '../templates/MenuBarsTemplate';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useQuery } from 'react-query';
import {
  TotalStatistics,
  CategoryStatistics,
} from '../../components/organisms/Graph';
import LoadingBubbleOrganisms from '../organisms/LoadingBubbleOrganisms';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  AssignmentIndRounded,
  SystemUpdateAltOutlined,
  Folder,
} from '@material-ui/icons';
import * as constants from '../../utils/CommonConstants';
import ModalMolecures from '../molecules/ModalMolecules';
import { Report } from '../../utils/interfaces/Report';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'center';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: '社員名', minWidth: 100, align: 'center' },
  { id: 'code', label: '社員番号', minWidth: 100, align: 'center' },
  { id: 'year', label: '受診年度', minWidth: 100, align: 'center' },
  { id: 'total', label: '総合評価', minWidth: 100, align: 'center' },
  { id: 'sex', label: '性別', minWidth: 100, align: 'center' },
  { id: 'before', label: '既往歴', minWidth: 100, align: 'center' },
  { id: 'pressure_h', label: '血圧(上)', minWidth: 100, align: 'center' },
  { id: 'pressure_l', label: '血圧(下)', minWidth: 100, align: 'center' },
  { id: 'fat', label: '中性脂肪(mg/dℓ)', minWidth: 100, align: 'center' },
  { id: 'hg', label: 'ヘモグロビン(g/dl)', minWidth: 100, align: 'center' },
  { id: 'hba1c', label: 'HbA1c(%)', minWidth: 100, align: 'center' },
  { id: 'gamma', label: 'γ-GTP(U/L)', minWidth: 100, align: 'center' },
  { id: 'alt', label: 'ALT(U/L)', minWidth: 100, align: 'center' },
  { id: 'ast', label: 'AST(U/UL)', minWidth: 100, align: 'center' },
  { id: 'bmi', label: 'BMI', minWidth: 100, align: 'center' },
  { id: 'glucose', label: '空腹時血糖(mg/dℓ)', minWidth: 100, align: 'center' },
  { id: 'hdl', label: 'HDL(mg/dℓ)', minWidth: 100, align: 'center' },
  { id: 'ldl', label: 'LDL(mg/dℓ)', minWidth: 100, align: 'center' },
  {
    id: 'cholesterol',
    label: '総コレステロール(mg/dℓ)',
    minWidth: 100,
    align: 'center',
  },
  { id: 'uric', label: '尿酸値(mg/dℓ)', minWidth: 100, align: 'center' },
  { id: 'detail', label: '詳細', minWidth: 70, align: 'center' },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(1),
      height: 550,
      margin: theme.spacing(),
    },
    pie: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(3),
        minWidth: theme.spacing(100),
        height: '70%',
      },
    },
    bar: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(3),
        minWidth: theme.spacing(95),
        width: '70%',
        height: theme.spacing(54),
      },
    },
    table: {
      minWidth: 650,
    },
    root: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    container: {
      maxHeight: 440,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 110,
    },
    button: {
      '& > *': {
        display: 'flex',
      },
    },
    reportDownloadButton: {
      marginLeft: theme.spacing(2),
    },
    query: {},
  })
);

const HealthChecks: React.FC = () => {
  const classes = useStyles();
  const [during, setDuring] = useState(new Date().getFullYear());
  const [recordModalState, setRecordModalState] = useState(false);
  const { useGetHealthReports, useGetHealthReportsSummaryList } =
    useHealthReports();
  //company_idを取得する必要あり
  const { isLoading, data, refetch } = useGetHealthReports(during);
  const { isLoading: summaryLsIsLoading, data: summaryLsData } =
    useGetHealthReportsSummaryList();
  const [page, setPage] = useState(0);
  const [searchedText, setSearchedText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleFormChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDuring(Number(event.target.value));
    refetch();
  };

  if (!data || !summaryLsData) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (isLoading || summaryLsIsLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const mensRecord = data.filter((elm: Report) => elm.sex === 'M');
  const womensRecord = data.filter((elm: Report) => elm.sex === 'F');

  return (
    <MenuBarsTemplate title="健康診断結果">
      <Grid container>
        <Grid item xs={10}>
          <Typography
            color="textSecondary"
            variant="h5"
            gutterBottom
            component="div">
            健康診断記録
            {summaryLsData.length == 0 ? (
              <a href={`${constants.SERVER_URL}/reports/summary/sample`}>
                <Button
                  className={classes.reportDownloadButton}
                  variant="outlined"
                  vertical-align="center"
                  size="small">
                  <SystemUpdateAltOutlined />
                  健康診断レポートダウンロード
                </Button>
              </a>
            ) : (
              <Button
                className={classes.reportDownloadButton}
                variant="outlined"
                vertical-align="center"
                onClick={() => setRecordModalState(true)}
                size="small">
                <SystemUpdateAltOutlined />
                健康診断レポートダウンロード
              </Button>
            )}
          </Typography>
        </Grid>
        <Grid item xs={2} justify="flex-end">
          <FormControl
            variant="outlined"
            className={classes.formControl}
            vertical-align="center"
            size="small"
            fullWidth={true}
            margin="none">
            <InputLabel id="demo-simple-select-outlined-label">
              受診年度
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={during}
              onChange={handleFormChange}
              label="Age">
              <MenuItem value="2019">
                <em>選択</em>
              </MenuItem>
              <MenuItem value={2016}>2016年度</MenuItem>
              <MenuItem value={2017}>2017年度</MenuItem>
              <MenuItem value={2018}>2018年度</MenuItem>
              <MenuItem value={2019}>2019年度</MenuItem>
              <MenuItem value={2020}>2020年度</MenuItem>
              <MenuItem value={2021}>2021年度</MenuItem>
              <MenuItem value={2022}>2022年度</MenuItem>
              <MenuItem value={2023}>2023年度</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <TotalStatistics record={mensRecord} sex="M" />
          </Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <CategoryStatistics record={mensRecord} sex="M" />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <TotalStatistics record={womensRecord} sex="F" />
          </Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <CategoryStatistics record={womensRecord} sex="F" />
          </Paper>
        </Grid>
      </Grid>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <Typography variant="h5" style={{ marginTop: 30 }}>
          診断結果 一覧
        </Typography>
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          disableClearable
          style={{ width: 200 }}
          options={data?.map((option: any) => option.name)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="ユーザー検索"
              margin="normal"
              variant="outlined"
              InputProps={{ ...params.InputProps, type: 'search' }}
              value={searchedText}
              onChange={(event) => setSearchedText(event.target.value)}
            />
          )}
        />
      </div>

      <Paper className={classes.root}>
        <Box margin={1}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className={classes.table}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.filter(
                    (user: any) =>
                      user.name.includes(searchedText) ||
                      user.employeeId.includes(searchedText)
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((elm: any, idx: number) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                        <TableCell align="center">{elm['name']}</TableCell>
                        <TableCell align="center">
                          {elm['employeeId']}
                        </TableCell>
                        <TableCell align="center">{elm['during']}</TableCell>
                        <TableCell align="center">{elm['total']}</TableCell>
                        <TableCell align="center">{elm['sex']}</TableCell>
                        <TableCell align="center">{elm['past']}</TableCell>
                        <TableCell align="center">{elm['bloodup']}</TableCell>
                        <TableCell align="center">{elm['bloodlow']}</TableCell>
                        <TableCell align="center">{elm['TG']}</TableCell>
                        <TableCell align="center">{elm['hmg']}</TableCell>
                        <TableCell align="center">{elm['HbA1c']}</TableCell>
                        <TableCell align="center">{elm['GTP']}</TableCell>
                        <TableCell align="center">{elm['ALT']}</TableCell>
                        <TableCell align="center">{elm['AST']}</TableCell>
                        <TableCell align="center">{elm['BMI']}</TableCell>
                        <TableCell align="center">{elm['glucose']}</TableCell>
                        <TableCell align="center">{elm['HDL']}</TableCell>
                        <TableCell align="center">{elm['LDL']}</TableCell>
                        <TableCell align="center">{elm['Tchol']}</TableCell>
                        <TableCell align="center">{elm['UA']}</TableCell>
                        <TableCell align="center">
                          <Link to={`/userdetail?uid=${elm['uid']}`}>
                            <IconButton aria-label="search">
                              <AssignmentIndRounded color="primary" />
                            </IconButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <ModalMolecures
        isOpen={recordModalState}
        onClose={() => setRecordModalState(false)}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          noWrap
          style={{ margin: '2%' }}>
          健康診断レポートダウンロード
        </Typography>
        <hr></hr>
        <DialogContent>
          <DialogContentText>
            健康診断レポートを選択してダウンロードしてください
          </DialogContentText>
          <div>
            <List dense={true} style={{ maxHeight: '70vh', overflow: 'auto' }}>
              {summaryLsData.map((summary, index) => (
                <a
                  href={`${constants.SERVER_URL}/reports/summary/${summary.report_path}`}
                  key={index}
                  style={{ textDecoration: 'none', color: 'black' }}>
                  <ListItem divider={true}>
                    <ListItemAvatar>
                      <Avatar>
                        <Folder />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={summary.report_name} />
                  </ListItem>
                </a>
              ))}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRecordModalState(false)} color="secondary">
            閉じる
          </Button>
        </DialogActions>
      </ModalMolecures>
    </MenuBarsTemplate>
  );
};

export default HealthChecks;
