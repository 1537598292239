import { commonHttpClient } from './commonHttpClient';
import { Company } from '../../utils/interfaces/Company';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToast } from '../../components/molecules/toastMolecules';

export interface MonthlyManageData {
  month: string;
  count: number;
}

export interface DailyManageData {
  date: string;
  count: number;
}

export interface AnnuallyManageData {
  year: string;
  count: number;
}

export interface WeeklyManageData {
  weekdate:string;
  week: string;
  count: number;
}

export interface DailyAvgStep {
  date: string;
  record: number;
}

export interface WeeklyAvgStep {
  week: string;
  record: number;
}

export interface MonthlyAvgStep {
  month: string;
  record: number;
}

export interface AnnuallyAvgStep {
  year: string;
  record: number;
}

export interface UserCount {
  user_count: number;
  date?: string;
  week?: string;
  month?: string;
  year?: string;
}

const { http } = commonHttpClient();

// 会社名、従業員総数取得
export const getCompanyEmployee = async (): Promise<Company> => {
  const { data } = await http.get('/company');
  return data as Company;
};

// // ダッシュボードのレコード取得
// export const getDashboardRecord = async (
//   companyid: number
// ): Promise<DashboardRecord[]> => {
//   const { data } = await http.get(`/dashboard`);
//   return data as DashboardRecord[];
// };

export const getAvarageSteps = async (
  type: 'daily' | 'monthly' | 'annually' | 'weekly',
): Promise<DailyAvgStep[] | MonthlyAvgStep[] | WeeklyAvgStep[] | AnnuallyAvgStep[]> => {
  const { data } = await http.get(`/dashboard/record?type=${type}`);
  return data;
};

// login_managementテーブルの取得
export const getUserData = async (
  type: 'daily' | 'monthly' | 'annually' | 'weekly',
  data_type: 'login' | 'perficient',
): Promise<DailyManageData[] | MonthlyManageData[] | WeeklyManageData[] | AnnuallyManageData[]> => {
  const { data } = await http.get(`/dashboard/userdata?type=${type}&data_type=${data_type}`);
  return data;
};

export const getTotalCompanyMember = async (
  type: 'daily' | 'monthly' | 'annually' | 'weekly',
): Promise<UserCount[]> => {
  const { data } = await http.get(`/users/counts?type=${type}`);
  return data;
}

export const getNotificationInfo = async (): Promise<number> => {
  const { data } = await http.get(`/dashboard/notification`);
  return data;
}

export type AvtiveUser = {
  uid: number;
  name: string;
  email: string;
  employeeId: string;
};

export type NotAvtiveUser = AvtiveUser;

export const getActiveUsers = async (
  startDate: string,
  endDate: string,
  dataType: string
): Promise<AvtiveUser[]> => {
  const { data } = await http.get(
    `/dashboard/active_users?start-date=${startDate}&end-date=${endDate}&data-type=${dataType}`
  );
  return data;
};

export const getNotActiveUsers = async (
  startDate: string,
  endDate: string,
  dataType: string
): Promise<NotAvtiveUser[]> => {
  const { data } = await http.get(
    `/dashboard/not_active_users?start-date=${startDate}&end-date=${endDate}&data-type=${dataType}`
  );
  return data;
};

export interface CompanyInfo {
  companyid: string;
  name: string;
  passcode: number;
  flag: number;
  created_at: Date;
  updated_at: Date;
  is_invalidated: boolean;
}

export const useCompanies = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  // 会社名、従業員総数取得
  const getCompanyEmployee = async (): Promise<CompanyInfo[]> => {
    const { data } = await http.get('/company');
    return data as CompanyInfo[];
  };
  const getCompany = async () => {
    const { data } = await http.get('/company');
    return data as Company[];
  };
  const getDeletedCompany = async ():Promise<CompanyInfo[]>=>{
    const {data} = await http.get('/company/deletedcom');
    return data as CompanyInfo[];
  }
  const getCompanyTotal = async ():Promise<CompanyInfo[]>=>{
    const { data } = await http.get('/company/counts');
    return data as CompanyInfo[];
  };
  const createCompany = async(
    createCompanyPayload: CompanyInfo,
    ):Promise<void> => {
      await http.post('/company',createCompanyPayload);
    };

  const useGetCompany = () => useQuery('Company', getCompany);

  const GetCompanyInfo = () =>
    useQuery('companyInfo', getCompanyEmployee
    );
  const GetCompanyTotal = () =>
    useQuery('allCompanyTotal',getCompanyTotal
    );
  const GetDeleted = () =>
    useQuery('deletedCompany',getDeletedCompany);

  const useCreateCompany = () =>
  useMutation(
    createCompany,
    {
    onSuccess: ()=>{
      showToast(
        'success',
        '企業を追加しました',
        '※画面が更新されない場合はブラウザの更新をして下さい',
        3000
      );
    setTimeout(()=>{
      queryClient.invalidateQueries('companyInfo');  
    },1000);
    },
    onError: (e)=>{
      if (e instanceof Error){
        showToast('error',e.message,"",3000);
      }
    },
  });


  return {
    GetCompanyInfo,
    GetCompanyTotal,
    GetDeleted,
    useCreateCompany,
    useGetCompany
  };
};