import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import LoadingBubbleOrganisms from '../organisms/LoadingBubbleOrganisms';
import MenuBarsTemplate from '../templates/MenuBarsTemplate';
import ModalMolecures from '../molecules/ModalMolecules';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import { AssignmentIndRounded, CompareArrowsOutlined } from '@material-ui/icons';
import MaterialTable, { MTableToolbar } from 'material-table';
import { SlideProps } from '@material-ui/core/Slide';
import { useUser } from '../../controllers/services/useUser';
import { useFormik } from 'formik';
import {
  USERS_ADD_INITIAL_VALUES,
  USERS_ADD_VALIDATION_SCHEMA,
} from '../../utils/validator';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  TableContainer,
  Input,
  Select,
  TableRow,
  Typography,
  TextField,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { commonHttpClient } from '../../controllers/services/commonHttpClient';
import { CSVLink } from 'react-csv';
import { User} from '../../utils/interfaces/User';
import { DateDTO } from '../../utils/interfaces/Interface';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { LoginUserContext } from '../../utils/CommonContext';
import { useCompanyBattles } from '../../controllers/services/useCompanyBattle';
import LoadingOverlay from 'react-loading-overlay';
import { Department } from '../../utils/interfaces/Department';

dayjs.extend(utc);
dayjs.extend(timezone);

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#8fc31f' }, // テーマの色
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    container: {
      maxHeight: 440,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      height: 600,
      width: 500,
      padding: 20,
      outline: 'none',
      borderRadius: 10,
    },
    button: {
      height: 50,
      width: 200,
      marginBottom: 30,
      backgroundColor: 'textSecondary',
      outline: 'none',
    },
    form: {
      height: 50,
      width: '80%',
      marginTop: 20,
      marginLeft: 45,
    },
    createDepartmentButton: {
      marginLeft: theme.spacing(2),
    },
    table: {
      tableLayout: 'fixed',
    },
  })
);

const Users: React.FC = () => {
  type TransitionProps = Omit<SlideProps, 'direction'>;
  const csvLinkRef = React.useRef<any>();
  const classes = useStyles();
  const [dept, setDept] = useState([] as Department[]);
  const [isDLDayModalOpen, setIsDLDayModalOpen] = useState(false);
  const [isCautionModalOpen, setIsCautionModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [csvData, setCsvData] = useState<string[][]>([]);
  const defaultStartDay = dayjs().startOf('month').format('YYYY-MM-DD');
  const defaultEndDay = dayjs().format('YYYY-MM-DD');
  const [csvTargetStartDay, setcsvTargetStartDay] = useState(defaultStartDay);
  const [csvTargetEndDay, setcsvTargetEndDay] = useState(defaultEndDay);
  const { useGetUserMutation,useGetDeptMutation,useCreateUser, useCreateDemoUser } = useUser();
  const { useAddUsersToCompanyBattleMutation } = useCompanyBattles();

  const createUserMutation = useCreateUser().mutate;
  const createDemoUserMutation = useCreateDemoUser().mutate;

const { data: userData } = useGetUserMutation();

  const {
    isLoading: deptLoading,
    data: deptData,
    // isError: departmentsIsError,
 } = useGetDeptMutation();

  const addUsersToCompanyBattleMutation =
    useAddUsersToCompanyBattleMutation().mutate;

  const { http } = commonHttpClient();
  const loginUser = useContext(LoginUserContext);
  let isDemo: any;

  if (loginUser.company) {
    isDemo = new Map(Object.entries(loginUser.company)).get('is_demo');
  } else {
    isDemo = 1;
  }

  // 検索枠の値を保持する
  const [searchWord, setSearchWord] = useState(() => {
    const storedValue = localStorage.getItem('searchWord');
    return storedValue !== null ? JSON.parse(storedValue) : '';
  })
  useEffect(() => {
    localStorage.setItem('searchWord', JSON.stringify(searchWord));
  }, [searchWord]);

  const searchedResultUsers: User[] = userData?.filter(
    (user) => 
      user.uname.includes(searchWord) ||
      user.employeeId?.toString().includes(searchWord) ||
      user.email.includes(searchWord) ||
      user.rname.includes(searchWord)
  ) || userData || [];

  const clearSerchWords = () => {
    setSearchWord('');
    // localStorage.removeItem('searchWord');
  }

  const dateReset = () => {
    setIsDLDayModalOpen(false);
    setIsLoading(false);
    setcsvTargetStartDay(defaultStartDay);
    setcsvTargetEndDay(defaultEndDay);
  };

  const dateValidate = (date: string, format: string) => {
    return dayjs(date, format).format(format) === date;
  };

  // const [department, setDepartment] = React.useState<User>();

  
  const { values, touched, handleSubmit, handleChange, errors,setFieldValue,resetForm } =
    useFormik({
      initialValues:USERS_ADD_INITIAL_VALUES,
      validationSchema: USERS_ADD_VALIDATION_SCHEMA,
      onSubmit: onSubmit,
    });

  async function onSubmit() {
    try {
      if (isDemo === 0) {
        createUserMutation({
          uname: values.uname,
          rname: values.rname,
          employeeId: values.employeeId,
          email: values.email,
          pswd: values.password,
          department:dept,
        });
      } else {
        createDemoUserMutation({
          uname: values.uname,
          rname: values.rname,
          employeeId: values.employeeId,
          email: values.email,
          pswd: values.password,
          department:dept,
        });
        await new Promise((resolve) => setTimeout(resolve, 2000));
        await addUsersToCompanyBattleMutation();
      }
      resetForm();
    } catch (err) {
      console.log(err);
    }
  }

  async function requestDayCSVData(): Promise<void> {
    const date: DateDTO = {
      start_date: dayjs(csvTargetStartDay).tz('Asia/Tokyo').startOf('day'),
      end_date: dayjs(csvTargetEndDay).tz('Asia/Tokyo').endOf('day'),
    };

    // 終了日が開始日より遅い場合はcsvファイルを生成する処理をスキップする
    if (date.end_date >= date.start_date) {
      // ローディング中のためキャンセルボタンを非活性化
      setIsLoading(true);
      const { data } = await http.get('/users/csv/day', {
        params: {
          // ここにクエリパラメータを指定する
          start_date: date.start_date.toDate(),
          end_date: date.end_date.toDate(),
        },
      });

      // 期間中の日付を取得
      const stepsDays: any = [];
      for (
        let d = new Date(csvTargetStartDay);
        d <= new Date(csvTargetEndDay);
        d.setDate(d.getDate() + 1)
      ) {
        const formatedDate =
          d.getFullYear() +
          // "-" +
          ('0' + (d.getMonth() + 1)).slice(-2) +
          // "-" +
          ('0' + d.getDate()).slice(-2);

        stepsDays.push(formatedDate);
      }

      const csvFormedData: any = [];
      let stepDays: any = [];
      let list: any = {};
      let stepsByDay: any = [];

      data.map((user: User) => {
        let userSteps = 0;
        let userStepAve = 0;
        let userDistance = 0;
        let winDuels = 0;
        let gains = 0;
        list = {};
        stepDays = [];
        stepsByDay = [];
        // let possesion = 0;

        if (user.records) {
          stepsDays.map((days: string, index: number) => {
            user.records.map((record) => {
              if (record.yeardate == days) {
                if (record.type == 'steps') {
                  userSteps += record.value;
                  list = {
                    uid: record.uid,
                    stepsYearDate: record.yeardate,
                    userStep: record.value,
                  };
                  stepsByDay.push(list);
                } else if (record.type == 'distance') {
                  userDistance += record.value;
                }
              }
            });
            if (stepsByDay.length == index) {
              list = {
                uid: user.uid,
                stepsYearDate: days,
                userStep: 0,
              };
              stepsByDay.push(list);
            }
          });
        }
        if (!user.records) {
          stepsDays.map((days: string) => {
            list = {
              uid: user.uid,
              stepsYearDate: days,
              userStep: 0,
            };
            stepsByDay.push(list);
          });
        }
        if (user.win_duels) {
          winDuels = user.win_duels?.length;
        }
        if (user.received_transactions) {
          user.received_transactions.map((received_transaction) => {
            if (
              dayjs(received_transaction.tran_date) > date.start_date &&
              dayjs(received_transaction.tran_date) <= date.end_date
            ) {
              gains += received_transaction.amount;
            }
          });
        }

        userStepAve = Math.floor((userSteps / stepsByDay.length) * 10) / 10;

        // if (user.received_transactions) {
        //   user.received_transactions.map((received_transaction) => {
        //     if (
        //       dayjs(received_transaction.tran_date) <=
        //       date.end_date
        //     ) {
        //       possesion += received_transaction.amount;
        //     }
        //   });
        // }
        // if (user.sent_transactions) {
        //   user.sent_transactions.map((sent_transactions) => {
        //     if (
        //       dayjs(sent_transactions.tran_date) <=
        //       date.end_date
        //     ) {
        //       possesion -= sent_transactions.amount;
        //     }
        //   });
        // }

        let csvData = [];
        csvData = [
          user.uname,
          user.rname,
          user.email,
          user.employeeId,
          userSteps,
          userStepAve,
          //userDistance,
          winDuels,
          gains,
          user.possesion_points == null ? 0 : user.possesion_points,
        ];
        stepsByDay.map((data: any) => {
          csvData.push(data.userStep);
        });
        csvFormedData.push(csvData);
      });

      let csvColumn = [];
      csvColumn = [
        'ニックネーム',
        '社員名',
        '社用e-Mail',
        '社員番号',
        `${dayjs(csvTargetStartDay)
          .tz('Asia/Tokyo')
          .startOf('day')
          .format('YYYY年MM月DD日')}~
        ${dayjs(csvTargetEndDay)
          .tz('Asia/Tokyo')
          .startOf('day')
          .format('YYYY年MM月DD日')}の総歩数(歩)`,
        `${dayjs(csvTargetStartDay)
          .tz('Asia/Tokyo')
          .startOf('day')
          .format('YYYY年MM月DD日')}~
        ${dayjs(csvTargetEndDay)
          .tz('Asia/Tokyo')
          .startOf('day')
          .format('YYYY年MM月DD日')}の平均歩数(歩)`,
        // `${dayjs(csvTargetStartDay)
        //   .tz('Asia/Tokyo')
        //   .startOf('day')
        //   .format('YYYY年MM月DD日')}~
        //   ${dayjs(csvTargetEndDay)
        //     .tz('Asia/Tokyo')
        //     .startOf('day')
        //     .format('YYYY年MM月DD日')}の総距離数(m)`,
        `${dayjs(csvTargetStartDay)
          .tz('Asia/Tokyo')
          .startOf('day')
          .format('YYYY年MM月DD日')}~
        ${dayjs(csvTargetEndDay)
          .tz('Asia/Tokyo')
          .startOf('day')
          .format('YYYY年MM月DD日')}のデュエル勝利数(回)`,
        `${dayjs(csvTargetStartDay)
          .tz('Asia/Tokyo')
          .startOf('day')
          .format('YYYY年MM月DD日')}~
        ${dayjs(csvTargetEndDay)
          .tz('Asia/Tokyo')
          .startOf('day')
          .format('YYYY年MM月DD日')}間の獲得ポイント数`,
        `現在の保有ポイント数`,
      ];
      stepsDays.map((stepsDay: string) => {
        csvColumn.push(stepsDay);
      });
      setCsvData([csvColumn, ...csvFormedData]);
      csvLinkRef.current.link.click();
    } else {
      setIsCautionModalOpen(true);
    }
    dateReset();
  }

  // const tableRef = React.useRef<any>(MaterialTable)
  // const [OnSearch, setOnSearch] = useState([]);
  // const [search,setSearch] = useState('nakayama');

  // const handleSearchChange = (searchText: string) => {
  //       console.log(`searchText : "${searchText}" - data : `, tableRef.current.state.data)
  //       setOnSearch(tableRef.current.state.data);
  //   }

  // const defaultSerchText = () =>{
  //   setSerchText();
  // }

  if (!userData || !deptData) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (deptLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  // if (departmentsIsError) {
  //   return (
  //     <MenuBarsTemplate title="">
  //       <LoadingBubbleOrganisms />
  //     </MenuBarsTemplate>
  //   );
  // }

  return (
    <MenuBarsTemplate title="ユーザー管理">
      {!loginUser.admin_grants?.user.any.read ||
      loginUser.admin_grants?.user.any.create ||
      loginUser.admin_grants?.user.any.delete ||
      loginUser.admin_grants?.user.any.update ? (
        <Typography
          color="textSecondary"
          variant="h5"
          gutterBottom
          component="div">
          ユーザー 追加
        </Typography>
      ) : (
        <></>
      )}
      {!loginUser.admin_grants?.user.any.read ||
      loginUser.admin_grants?.user.any.create ||
      loginUser.admin_grants?.user.any.delete ||
      loginUser.admin_grants?.user.any.update ? (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableBody>
                <TableRow>
                  <TableCell size="small" style={{ width: '20%' }}>
                    <TextField
                      error={!!touched.uname && !!errors.uname}
                      helperText={touched.uname ? errors.uname : ''}
                      required
                      id="uname"
                      name="uname"
                      label="ニックネーム"
                      size="small"
                      placeholder="ニックネーム"
                      fullWidth={true}
                      value={values.uname}
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell size="small" style={{ width: '20%' }}>
                    <TextField
                      error={!!touched.rname && !!errors.rname}
                      helperText={touched.rname ? errors.rname : ''}
                      required
                      id="rname"
                      name="rname"
                      label="社員名"
                      size="small"
                      placeholder="社員名"
                      fullWidth={true}
                      value={values.rname}
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell size="small" style={{ width: '20%' }}>
                    <TextField
                      error={!!touched.employeeId && !!errors.employeeId}
                      helperText={touched.employeeId ? errors.employeeId : ''}
                      required
                      id="employeeId"
                      label="社員番号"
                      name="employeeId"
                      size="small"
                      placeholder="社員番号"
                      fullWidth={true}
                      value={values.employeeId}
                      onChange={handleChange}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: '30%' }}>
                    <TextField
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email ? errors.email : ''}
                      required
                      id="email"
                      label="メールアドレス"
                      name="email"
                      placeholder="メールアドレス"
                      fullWidth={true}
                      value={values.email}
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>
                    <TextField
                      error={!!touched.password && !!errors.password}
                      helperText={touched.password ? errors.password : ''}
                      required
                      label="パスワード"
                      name="password"
                      type="password"
                      id="password"
                      placeholder="パスワード"
                      fullWidth={true}
                      value={values.password}
                      onChange={handleChange}
                    />
                  </TableCell>
                  
                  { (() => {
                    if(deptData.length !== 0){
                      return (
                        <TableCell style={{ width: '20%' }}>
                        <FormControl
                        // error={!!touched.department_id && !!errors.department_id}
                        required={true} 
                        fullWidth={true}>
                      <InputLabel id="department_id">部署名</InputLabel>
                       <Select
                        labelId="department_id"
                        label="部署名"
                        id="department_id"
                        onChange={(event) => {
                          const selectedDepartment = deptData.filter(
                            (department_dept) => {
                              // console.log("=========",deptData);
                              return(
                                event.target.value as string[]
                              ).includes(department_dept.department_name); 
                            }
                          );
                          setFieldValue('dept',selectedDepartment);
                          setDept(selectedDepartment);
                        }}
                        input={<Input />}>
                         {deptData.map((dept)=> (
                          <MenuItem
                          key={dept.department_id}
                          value={dept.department_name}>
                          {dept.department_name}
                          </MenuItem>
                          ))}
                       </Select>
                       {/* <FormHelperText>
                       {touched.department_id ? errors.department_id : ''}
                      </FormHelperText> */}
                   </FormControl>
                   </TableCell>
                      )
                    }
                  })() }
                  <TableCell style={{ width: '5%' }}>
                    <IconButton
                      aria-label="search"
                      onClick={() => {
                        handleSubmit();}}>
                      <AddCircleOutlineIcon color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <></>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <Typography
          style={{ marginTop: 30 }}
          color="textSecondary"
          variant="h5"
          gutterBottom
          component="div">
          ユーザー 一覧
          <Button
            className={classes.createDepartmentButton}
            variant="outlined"
            vertical-align="center"
            size="small"
            onClick={() => setIsDLDayModalOpen(true)}>
            <SystemUpdateAltOutlinedIcon />
            ユーザー統計情報ダウンロード
          </Button>
          <CSVLink
            data={csvData}
            ref={csvLinkRef}
            filename={`ユーザー統計情報一覧_${dayjs(csvTargetStartDay)
              .tz('Asia/Tokyo')
              .startOf('day')
              .format('YYYY年MM月DD日')}_${dayjs(csvTargetEndDay)
              .tz('Asia/Tokyo')
              .startOf('day')
              .format('YYYY年MM月DD日')}.csv`}></CSVLink>
        </Typography>
      </div>
      <Paper className={classes.root}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          marginTop: 20,
          marginRight: 10,
        }}>
        <TextField
          id="searcWeords"
          // label="Search"
          value={searchWord}
          onChange={(event) =>
            setSearchWord(event.target.value)
          }
          name="Search"
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <IconButton onClick={() => clearSerchWords()}>
          <CloseIcon />
        </IconButton>
        </div>
          <MaterialTable
          columns={[
            { title: 'ニックネーム', field: 'uname' },
            { title: '社員名', field: 'rname' },
            { title: '社員番号', field: 'employeeId' },
            { title: '社用メール', field: 'email' },
            {
              title: '累計歩数(歩)',
              field: 'total_steps',
              searchable: false,
              type: 'numeric',
            },
            {
              title: '累計歩行距離(m)',
              field: 'total_distance',
              searchable: false,
              type: 'numeric',
            },
            {
              title: 'UID',
              field: 'uid',
              type: 'numeric',
            },
            {
              title: '詳細',
              field: 'detail',
              sorting: false,
              render: function toUserDetail(rowData: User) {
                return (
                  <Link
                    to={{
                      pathname: '/userdetail',
                      search: `?uid=${rowData.uid}`,
                      // state: search,
                    }}>
                    <IconButton aria-label="search">
                      <AssignmentIndRounded color="primary" />
                    </IconButton>
                  </Link>
                );
              },
            },
          ]}
          data={searchedResultUsers}
          // tableRef={tableRef}
          // onSearchChange={handleSearchChange}
          // onSearchChange={() => handleSearchChangeDirect(tableRef.current.state)}
          options={{
            showTitle: false,
            paging: true,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            filtering: false,
            search: false,
          }}
        />
      </Paper>

      <ModalMolecures isOpen={isDLDayModalOpen} onClose={() => dateReset()}>
        <LoadingOverlay active={isLoading} spinner text="ダウンロード中...">
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            noWrap
            style={{ margin: '2%' }}>
            ユーザー統計情報ダウンロード
          </Typography>
          <hr></hr>
          <DialogContent>
            <DialogContentText>
              ダウンロード対象月日を選択してください
            </DialogContentText>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus
              margin="dense"
              id="delete-confirm-form"
              label="開始日"
              type="date"
              fullWidth
              defaultValue={dayjs(csvTargetStartDay).format('YYYY-MM-DD')}
              onChange={(event) => {
                if (!event.target.value) {
                  event.target.value = csvTargetStartDay;
                }
                if (dateValidate(event.target.value, 'YYYY-MM-DD')) {
                  setcsvTargetStartDay(event.target.value);
                }
              }}
            />
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus
              margin="dense"
              id="delete-confirm-form"
              label="終了日"
              type="date"
              fullWidth
              defaultValue={dayjs(csvTargetEndDay).format('YYYY-MM-DD')}
              onChange={(event) => {
                if (!event.target.value) {
                  event.target.value = csvTargetEndDay;
                }
                if (dateValidate(event.target.value, 'YYYY-MM-DD')) {
                  setcsvTargetEndDay(event.target.value);
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isLoading}
              onClick={() => dateReset()}
              color="default">
              キャンセル
            </Button>
            <Button
              onClick={() => requestDayCSVData()}
              color="primary"
              disabled={false}>
              決定
            </Button>
          </DialogActions>
        </LoadingOverlay>
      </ModalMolecures>

      <ModalMolecures
        isOpen={isCautionModalOpen}
        onClose={() => setIsCautionModalOpen(false)}>
        <DialogContent>
          <DialogContentText>
            開始日は終了日より早くしてください
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsCautionModalOpen(false)}
            color="primary"
            disabled={false}>
            OK
          </Button>
        </DialogActions>
      </ModalMolecures>
    </MenuBarsTemplate>
  );
};

export default Users;
