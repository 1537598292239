import React, { createContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import {
  Admin,
  AuthorizedGrants,
  AuthorizeGrantsScope,
} from './interfaces/Admin';
import { useAuth } from '../controllers/services/useAuth';

interface CommonContext {
  children: React.ReactNode;
}

export const LoginUserContext = createContext<Admin>({} as Admin);

const CommonContext: React.FC<CommonContext> = ({ children }) => {
  const { useGetAuth } = useAuth();
  const { isLoading, data, isError, error } = useGetAuth();

  if (!data) {
    return <></>;
  }

  if (isLoading) {
    return <></>;
  }

  if (Object.keys(data).length) {
    data.admin_grants = getGrants(data);
  }

  return (
    <LoginUserContext.Provider value={data}>
      {children}
    </LoginUserContext.Provider>
  );
};

function getGrants(admin: Admin): AuthorizedGrants {
  const authorizedGrants = {
    user: {
      any: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      self: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
    },
    admin: {
      any: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      self: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
    },

    challenge: {
      any: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      self: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
    },

    dashboard: {
      any: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      self: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
    },

    groupbattle: {
      any: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      self: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
    },

    directmessage: {
      any: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      self: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
    },
    healthcheck: {
      any: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      self: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
    },
    group: {
      any: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      self: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
    },
    import: {
      any: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      self: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
    },
  } as AuthorizedGrants;
  admin.admin_role.map((role) => {
    role.role_grants.map((grant) => {
      authorizedGrants[grant.target as keyof AuthorizedGrants][
        grant.authorized_scope as keyof AuthorizeGrantsScope
      ].create =
        authorizedGrants[grant.target as keyof AuthorizedGrants][
          grant.authorized_scope as keyof AuthorizeGrantsScope
        ].create || grant.can_create;
      authorizedGrants[grant.target as keyof AuthorizedGrants][
        grant.authorized_scope as keyof AuthorizeGrantsScope
      ].read =
        authorizedGrants[grant.target as keyof AuthorizedGrants][
          grant.authorized_scope as keyof AuthorizeGrantsScope
        ].read || grant.can_read;
      authorizedGrants[grant.target as keyof AuthorizedGrants][
        grant.authorized_scope as keyof AuthorizeGrantsScope
      ].update =
        authorizedGrants[grant.target as keyof AuthorizedGrants][
          grant.authorized_scope as keyof AuthorizeGrantsScope
        ].update || grant.can_update;
      authorizedGrants[grant.target as keyof AuthorizedGrants][
        grant.authorized_scope as keyof AuthorizeGrantsScope
      ].delete =
        authorizedGrants[grant.target as keyof AuthorizedGrants][
          grant.authorized_scope as keyof AuthorizeGrantsScope
        ].delete || grant.can_delete;
    });
  });
  return authorizedGrants;
}

export default CommonContext;
