import { commonHttpClient } from './commonHttpClient';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useToast } from '../../components/molecules/toastMolecules';
import {
  Group,
  CreateGroupPayload,
  UpdateGroupPayload,
} from '../../utils/interfaces/Group';

export const useGroups = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const getGroups = async (): Promise<Group[]> => {
    const { data } = await http.get('/groups');
    return data as Group[];
  };

  const createGroup = async (
    createGroupPayload: CreateGroupPayload
  ): Promise<void> => {
    await http.post('/groups', createGroupPayload);
  };

  const updateGroup = async (
    updateGroupPayload: UpdateGroupPayload
  ): Promise<void> => {
    await http.patch('/groups/', updateGroupPayload);
  };

  const deleteGroup = async (group_id: number): Promise<void> => {
    await http.delete(`/groups/${group_id}`);
  };

  const useGetGroups = () => useQuery('Groups', getGroups);

  const useCreateGroupMutation = () =>
    useMutation(createGroup, {
      onSuccess: () => {
        showToast(
          'success',
          '新規グループを作成しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('Groups');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  const useUpdateGroupMutation = () =>
    useMutation(updateGroup, {
      onSuccess: () => {
        showToast(
          'success',
          'グループ情報を更新しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('Groups');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ取得に失敗しました', e.message, 3000);
        }
      },
    });

  const useDeleteGroupMutation = () =>
    useMutation(deleteGroup, {
      onSuccess: () => {
        showToast(
          'success',
          'グループを削除しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('Groups');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  return {
    useGetGroups,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
  };
};
