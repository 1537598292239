import React, { useState } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useChallenges } from '../../controllers/services/useChallenges';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuBarsTemplate from '../templates/MenuBarsTemplate';
import ModalMolecures from '../molecules/ModalMolecules';
import LoadingBubbleOrganisms from '../organisms/LoadingBubbleOrganisms';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  FormHelperText,
} from '@material-ui/core';
import { useFormik } from 'formik';
import {
  CHALLENGE_ADD_INITIAL_VALUES,
  CHALLENGE_ADD_VALIDATION_SCHEMA,
} from '../../utils/validator';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'center' | 'left';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'challenge_id', label: 'チャレンジID', minWidth: 170 },
  { id: 'name', label: 'チャレンジ名', minWidth: 170 },
  {
    id: 'mail',
    label: 'Daily/Weekly',
    minWidth: 170,
    align: 'left',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  { id: 'code', label: '歩数/距離', minWidth: 100 },
  {
    id: 'totalSteps',
    label: '目標値(歩/m)',
    minWidth: 170,
    align: 'center',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'coin',
    label: '獲得コイン数(HLC)',
    minWidth: 170,
    align: 'left',
    format: (value: number) => value.toFixed(2),
  },
  {
    id: 'description',
    label: 'チャレンジの概要',
    minWidth: 170,
    align: 'left',
    format: (value: number) => value.toFixed(2),
  },
  {
    id: 'delete',
    label: '削除',
    minWidth: 70,
    align: 'left',
    format: (value: number) => value.toFixed(2),
  },
];

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#8fc31f' }, // テーマの色
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: "auto",
      whiteSpace: "nowrap"
    },
    container: {
      maxHeight: 2000,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      height: 600,
      width: 500,
      padding: 20,
      outline: 'none',
      borderRadius: 10,
    },
    button: {
      height: 50,
      width: 200,
      marginBottom: 30,
      backgroundColor: 'textSecondary',
      outline: 'none',
    },
    form: {
      height: 50,
      width: '80%',
      marginTop: 20,
      marginLeft: 45,
    },
    StepOrDistance: {
      width: '10%',
      marginTop: 73,
      // margin: theme.spacing(1),
      // marginRight: '3%',
      // marginLeft: '10%',
      // minWidth: 50,
      minWidth: 150,
    },
    DailyOrWeekly: {
      width: '10%',
      // margin: theme.spacing(1),
      marginTop: 73,
      marginRight: '5%',
      // marginLeft: '10%',
      minWidth: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    deleteModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: 'white',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

const Challenges: React.FC = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<number | null>(
    null
  );
  const [title, setTitle] = useState('');
  const [span, setSpan] = useState('');
  const [description, setDescription] = useState('');
  const [challeType, setChalleType] = useState('');
  const [objective, setObjective] = useState(0);
  const [prize, setPrize] = useState(0);
  const [
    deleteChallengeState,
    setDeleteChallengeState,
  ] = React.useState<number>(0);

  const {
    useGetChallenges,
    useCreateChallengeMutation,
    useDeleteChallengeMutation,
  } = useChallenges();

  const createChallengeMutate = useCreateChallengeMutation().mutate;
  const deleteChallengeMutate = useDeleteChallengeMutation().mutate;

  const { isLoading, data, refetch } = useGetChallenges();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const {
    values,
    touched,
    handleSubmit,
    handleChange,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: CHALLENGE_ADD_INITIAL_VALUES,
    validationSchema: CHALLENGE_ADD_VALIDATION_SCHEMA,
    onSubmit: onSubmit,
  });

  async function onSubmit() {
    try {
      await createChallengeMutate({
        title: values.title,
        dayorweek: span,
        challetype: challeType,
        description: values.description,
        objective: values.objective,
        prize: values.prize,
      });
      resetForm();
      clearChalleState();
    } catch (err) {
      console.log(err);
    }
  }

  const clearChalleState = () => {
    setTitle('');
    setSpan('');
    setChalleType('');
    setDescription('');
    setObjective(0);
    setPrize(0);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!data) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (isLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  const onClickDeleteChallengeButton = async () => {
    deleteChallengeMutate(deleteChallengeState);
    setTitle('');
    setOpenDeleteModal(null);
  };

  const deleteChallengeModal = async (id: number) => {
    setDeleteChallengeState(id);
    setOpenDeleteModal(id);
  };

  return (
    <MenuBarsTemplate title="チャレンジ">
      <Typography
        color="textSecondary"
        variant="h5"
        gutterBottom
        component="div">
        チャレンジ追加
      </Typography>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              <TableRow>
                <TableCell size="medium" style={{ width: '15%' }}>
                  <TextField
                    error={touched.title && !!errors.title}
                    helperText={touched.title ? errors.title : ''}
                    required
                    id="title"
                    name="title"
                    label="チャレンジ名"
                    size="small"
                    placeholder="チャレンジ名"
                    fullWidth={true}
                    onChange={handleChange}
                    value={values.title}
                  />
                </TableCell>
                <TableCell style={{ width: '15%' }}>
                  <FormControl
                    error={!!touched.span && !!errors.span}
                    required={true}
                    fullWidth={true}>
                    <InputLabel shrink={true}>day/week</InputLabel>
                    <Select
                      id="span"
                      value={span}
                      onChange={(e) => {
                        const selectedSpan = e.target.value as string;
                        setFieldValue('span', selectedSpan);
                        setSpan(selectedSpan);
                      }}>
                      <MenuItem value="day">day</MenuItem>
                      <MenuItem value="week">week</MenuItem>
                    </Select>
                    {!!touched.span && !!errors.span && (
                      <FormHelperText>{errors.span}</FormHelperText>
                    )}
                  </FormControl>
                </TableCell>
                <TableCell style={{ width: '15%' }}>
                  <FormControl
                    error={!!touched.challetype && !!errors.challetype}
                    required={true}
                    fullWidth={true}>
                    <InputLabel shrink={true}>歩数(steps)/距離(distance)</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="challetype"
                      value={challeType}
                      onChange={(e) => {
                        const selectedChalleType = e.target.value as string;
                        setFieldValue('challetype', selectedChalleType);
                        setChalleType(selectedChalleType);
                      }}>
                      <MenuItem value="steps">steps</MenuItem>
                      <MenuItem value="distance">distance</MenuItem>
                    </Select>
                    {!!touched.challetype && !!errors.challetype && (
                      <FormHelperText>{errors.challetype}</FormHelperText>
                    )}
                  </FormControl>
                </TableCell>

                <TableCell style={{ width: '18%' }}>
                  <TextField
                    error={!!touched.objective && !!errors.objective}
                    helperText={touched.objective ? errors.objective : ''}
                    required={true}
                    type="number"
                    id="objective"
                    label="目標値(歩/m)"
                    name="objective"
                    placeholder="目標値"
                    fullWidth={true}
                    onChange={handleChange}
                    value={values.objective}
                  />
                </TableCell>
                <TableCell style={{ width: '20%' }}>
                  <TextField
                    error={!!touched.prize && !!errors.prize}
                    helperText={touched.prize ? errors.prize : ''}
                    required={true}
                    type="number"
                    id="prize"
                    label="獲得コイン数(HLC)"
                    name="prize"
                    placeholder="獲得コイン数"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth={true}
                    onChange={handleChange}
                    value={values.prize}
                  />
                </TableCell>

                <TableCell style={{ width: '25%', fontSize: '1em' }}>
                  <TextField
                    style={{ fontSize: '1em' }}
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description ? errors.description : ''}
                    id="description"
                    label="チャレンジの概要"
                    name="description"
                    placeholder="チャレンジの概要"
                    multiline
                    fullWidth={true}
                    onChange={handleChange}
                    value={values.description}
                  />
                </TableCell>
                <TableCell style={{ width: '5%' }}>
                  <IconButton
                    aria-label="addChallenge"
                    onClick={(e) => {
                      handleSubmit();
                    }}>
                    <AddCircleOutlineIcon color="primary" />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Typography
        style={{ marginTop: 30 }}
        color="textSecondary"
        variant="h5"
        gutterBottom
        component="div">
        現在開催されているチャレンジ
      </Typography>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ height: 80 }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((elm, idx: number) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                      <TableCell style={{ height: 100 }}>{elm['id']}</TableCell>
                      <TableCell>{elm['title']}</TableCell>
                      <TableCell align="left">{elm['dayorweek']}</TableCell>
                      <TableCell align="left">{elm['challetype']}</TableCell>
                      <TableCell align="center">{elm['objective']}</TableCell>
                      <TableCell align="left">{elm['prize']}</TableCell>
                      <TableCell align="left">{elm['description']}</TableCell>
                      <TableCell>
                        <IconButton
                          style={{ marginTop: 25 }}
                          aria-label="delete">
                          <DeleteIcon
                            color="secondary"
                            onClick={() => deleteChallengeModal(elm['id'])}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <ModalMolecures
        isOpen={typeof openDeleteModal === 'number'}
        onClose={() => setOpenDeleteModal(null)}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          noWrap
          style={{ margin: '2%' }}>
          チャレンジ削除
        </Typography>
        <hr></hr>
        <DialogContent>
          <DialogContentText>
            チャレンジを削除します、よろしいですか
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(null)} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={() => onClickDeleteChallengeButton()}
            color="secondary">
            削除
          </Button>
        </DialogActions>
      </ModalMolecures>
    </MenuBarsTemplate>
  );
};

export default Challenges;
