import React, { createContext, useContext, useEffect, useState } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MenuBarsTemplate from '../templates/MenuBarsTemplate';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LoadingBubbleOrganisms from '../organisms/LoadingBubbleOrganisms';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useAdmins } from '../../controllers/services/useAdmins';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SlideProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import ModalMolecures from '../molecules/ModalMolecules';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/DoneAllTwoTone';
import RevertIcon from '@material-ui/icons/NotInterestedOutlined';
import * as _ from 'lodash';
import { Admin, AdminsRole } from '../../utils/interfaces/Admin';
import { useToast } from '../molecules/toastMolecules';
import { useAdminRole } from '../../controllers/services/useAdminRole';
import { LoginUserContext } from '../../utils/CommonContext';
import {
  ADMIN_ADD_INITIAL_VALUES,
  ADMIN_ADD_VALIDATION_SCHEMA,
  ADMIN_EDIT_VALIDATION_SCHEMA,
} from '../../utils/validator';
import { EMLINK } from 'constants';

const AdminRoleCtx = createContext<AdminsRole[]>([]);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    container: {
      maxHeight: 440,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      height: 600,
      width: 500,
      padding: 20,
      outline: 'none',
      borderRadius: 10,
    },
    button: {
      height: 50,
      width: 200,
      marginBottom: 30,
      backgroundColor: 'textSecondary',
      outline: 'none',
    },
    form: {
      height: 50,
      width: '80%',
      marginTop: 20,
      marginLeft: 45,
    },
    selectButton: {
      display: 'block',
      marginTop: theme.spacing(2),
    },
    formControl: {
      minWidth: 120,
      height: 50,
      marginLeft: 10,
    },
    rowAreaformControl: {
      margin: theme.spacing(1),
      maxWidth: '200px',
    },
  })
);

function AdminRow(props: { admin: Admin }) {
  const classes = useStyles();

  const { admin: adminOrigin } = props;
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [admin, setAdmin] = React.useState<Admin>(adminOrigin);
  const [previous, setPrevious] = React.useState({} as Admin);
  const { useUpdateAdmin, useDeleteAdmin } = useAdmins();
  const updateAdminMutate = useUpdateAdmin().mutate;
  const deleteAdminMutate = useDeleteAdmin().mutate;

  const loginUser = useContext(LoginUserContext);

  useEffect(() => {
    setAdmin(adminOrigin);
  }, [adminOrigin]);

  const admin_roles = useContext(AdminRoleCtx);

  // モーダル表示制御関数
  const [deleteAdminModalState, setDeleteAdminModalState] = React.useState<{
    [key: string]: number;
  }>({});

  const onToggleEditMode = () => {
    if (!Object.keys(previous).length) {
      setPrevious((state) => ({ ...state, ...admin }));
    }
    setIsEditMode((state) => !state);
  };

  const { values, touched, handleSubmit, handleChange, errors, resetForm } =
    useFormik({
      initialValues: {
        name: admin.name,
        email: admin.email,
        admin_role: admin.admin_role,
      },
      validationSchema: ADMIN_EDIT_VALIDATION_SCHEMA,
      onSubmit: onSubmit,
    });

  async function onSubmit() {
    try {
      if (
        values.name != previous.name ||
        values.email != previous.email ||
        !_.isEqual(values.admin_role, previous.admin_role)
      ) {

        if(!_.isEqual(values.admin_role, previous.admin_role)){
          values.admin_role = await admin_roles.filter((admin_role) => {
            return (values.admin_role as unknown as string[]).includes(
              admin_role.admin_role_name
            );
          });
        }
        await updateAdminMutate({
          admin_id: admin.admin_id,
          name: values.name,
          email: values.email,
          admin_role: values.admin_role
        });
      }
      
      setPrevious((state) => {
        state = {} as Admin;
        return state;
      });
      onToggleEditMode();
    } catch (err) {
      console.log(err);
    }
  }

  const onRevert = () => {
    setAdmin(previous);
    setPrevious((state) => {
      state = {} as Admin;
      return state;
    });
    resetForm();
    onToggleEditMode();
  };

  const onClickDeleteAdminButton = async () => {
    await deleteAdminMutate(deleteAdminModalState.admin_id);
    setDeleteAdminModalState({});
  };

  return (
    <React.Fragment>
      {/* 表示領域 */}
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell>
          {isEditMode ? (
            <TextField
              label="氏名"
              value={values.name}
              error={touched.name && !!errors.name}
              required
              helperText={touched.name ? errors.name : ''}
              onChange={handleChange}
              onKeyPress={(e) => {
                if (e.key == 'Enter') {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              name="name"
              id="name"
              placeholder="氏名"
              fullWidth={true}
            />
          ) : (
            <>{admin.name}</>
          )}
        </TableCell>
        <TableCell align="left">
          {isEditMode ? (
            <TextField
              label="社用メール"
              error={!!touched.email && !!errors.email}
              helperText={touched.email ? errors.email : ''}
              required
              value={values.email}
              onChange={handleChange}
              onKeyPress={(e) => {
                if (e.key == 'Enter') {
                  e.preventDefault();
                  // onConfirm();
                  handleSubmit();
                }
              }}
              name="email"
              id="email"
              placeholder="社用メール"
              fullWidth={true}
            />
          ) : (
            <>{admin.email}</>
          )}
        </TableCell>
        <TableCell align="left">
          {(isEditMode && loginUser.admin_grants?.admin.any.update) ||
          (isEditMode &&
            loginUser.admin_grants?.admin.self.update &&
            _.pullAll(
              _.map(admin.admin_role, 'admin_role_id'),
              _.map(loginUser.admin_role, 'admin_role_id')
            ).length == 0) ? (
            <FormControl
              className={classes.rowAreaformControl}
              error={!!touched.admin_role && !!errors.admin_role}
              required={true}
              fullWidth={true}>
              <InputLabel id="admin-role-label">権限</InputLabel>
              <Select
                labelId="admin-role-label"
                id="admin_role"
                label="権限"
                name="admin_role"
                multiple
                defaultValue={admin.admin_role.map(
                  (role) => role.admin_role_name
                )}
                onChange={handleChange}
                input={<Input />}>
                {loginUser.admin_grants?.admin.any.create
                  ? admin_roles.map((admin_role) => (
                  <MenuItem
                        key={admin_role.admin_role_id}
                        value={admin_role.admin_role_name}>
                        {admin_role.admin_role_name}
                      </MenuItem>
                    ))
                  : loginUser.admin_role.map((admin_role) => (
                      <MenuItem
                        key={admin_role.admin_role_id}
                        value={admin_role.admin_role_name}>
                        {admin_role.admin_role_name}
                      </MenuItem>
                    ))}
              </Select>
              {!!touched.admin_role && !!errors.admin_role && (
                <FormHelperText>{errors.admin_role}</FormHelperText>
              )}
            </FormControl>
          ) : (
            <>{_.join(_.map(admin.admin_role, 'admin_role_name'), ',')}</>
          )}
        </TableCell>
        <TableCell align="center" style={{ width: '10%' }}>
          {loginUser.admin_grants?.admin.any.update ||
          (loginUser.admin_grants?.admin.self.update &&
            _.pullAll(
              _.map(admin.admin_role, 'admin_role_id'),
              _.map(loginUser.admin_role, 'admin_role_id')
            ).length == 0) ? (
            isEditMode ? (
              <>
                <IconButton
                  aria-label="done"
                  onClick={
                    (e) => {
                      handleSubmit();
                      }}>
                  <DoneIcon color="primary" />
                </IconButton>
                <IconButton aria-label="revert" onClick={() => onRevert()}>
                  <RevertIcon color="secondary" />
                </IconButton>
              </>
            ) : (
              <IconButton aria-label="edit" onClick={(e) => onToggleEditMode()}>
                <EditIcon color="primary" />
              </IconButton>
            )
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell align="center" style={{ width: '5%' }}>
          <IconButton
            aria-label="delete"
            onClick={() =>
              setDeleteAdminModalState({
                admin_id: admin.admin_id,
              })
            }>
            <DeleteIcon color="secondary" />
          </IconButton>
        </TableCell>
      </TableRow>
      <ModalMolecures
        isOpen={!!Object.keys(deleteAdminModalState).length}
        onClose={() => setDeleteAdminModalState({})}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          noWrap
          style={{ margin: '2%' }}>
          管理者削除
        </Typography>
        <hr></hr>
        <DialogContent>
          <DialogContentText>
            管理者を削除します、よろしいですか
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteAdminModalState({})} color="primary">
            キャンセル
          </Button>
          <Button onClick={() => onClickDeleteAdminButton()} color="secondary">
            削除
          </Button>
        </DialogActions>
      </ModalMolecures>
    </React.Fragment>
  );
}

const Administrotors: React.FC = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [role, setRole] = useState([] as AdminsRole[]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [employeeId, setEmployeeId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [addOpen, setAddOpen] = useState(false);
  const [searchedText, setSearchedText] = useState('');

  const { useGetAdmins, useCreateAdmin } = useAdmins();
  const { useReadAdminRole } = useAdminRole();
  const loginUser = useContext(LoginUserContext);

  const {
    isLoading: adminLoading,
    data: adminData,
    isError: adminIsError,
    error: adminError,
  } = useGetAdmins();

  const {
    isLoading: adminRoleLoading,
    data: adminRoleData,
    isError: adminRoleIsError,
    error: adminRoleError,
  } = useReadAdminRole();

  const createAdminMutation = useCreateAdmin().mutate;

  const { showToast } = useToast();

  const handleChangePage = (evt: unknown, newPage: number) => {
    setPage(newPage);
  };

  const {
    values,
    touched,
    handleSubmit,
    handleChange,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: ADMIN_ADD_INITIAL_VALUES,
    validationSchema: ADMIN_ADD_VALIDATION_SCHEMA,
    onSubmit: onSubmit,
  });

  const handleChangeRowsPerPage = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+evt.target.value);
    setPage(0);
  };

  async function onSubmit() {
    try {
      createAdminMutation({
        name: values.name,
        email: values.email,
        password: values.password,
        admin_role: role,
      });
      setEmployeeId('');
      setName('');
      setEmail('');
      setPassword('');
      setRole([] as AdminsRole[]);
      setAddOpen(true);
      resetForm();
    } catch (err) {
      console.log(err);
    }
  }

  if (!adminData || !adminRoleData) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (adminLoading || adminRoleLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (adminIsError || adminRoleIsError) {
    showToast(
      'error',
      'データ取得に失敗しました',
      '時間をおいて再度お試しください',
      3000
    );
    return (
      <MenuBarsTemplate title="">
        <></>
      </MenuBarsTemplate>
    );
  }

  return (
    <MenuBarsTemplate title="管理者管理">
      <AdminRoleCtx.Provider value={adminRoleData}>
        {!loginUser.admin_grants?.user.any.read ||
        loginUser.admin_grants?.user.any.create ||
        loginUser.admin_grants?.user.any.delete ||
        loginUser.admin_grants?.user.any.update ? (
          <Typography
            color="textSecondary"
            variant="h5"
            gutterBottom
            component="div">
            管理者追加
          </Typography>
        ) : (
          <></>
        )}
        {!loginUser.admin_grants?.user.any.read ||
        loginUser.admin_grants?.user.any.create ||
        loginUser.admin_grants?.user.any.delete ||
        loginUser.admin_grants?.user.any.update ? (
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableBody>
                  <TableRow>
                    <TableCell size="small" style={{ width: '20%' }}>
                      <TextField
                        error={!!touched.name && !!errors.name}
                        helperText={!touched.name ? errors.name : ''}
                        required
                        id="name"
                        label="氏名"
                        size="small"
                        name="name"
                        placeholder="氏名"
                        fullWidth={true}
                        value={values.name}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell style={{ width: '30%' }}>
                      <TextField
                        error={!!touched.email && !!errors.email}
                        helperText={!touched.email ? errors.email : ''}
                        required
                        id="email"
                        label="メールアドレス"
                        name="email"
                        placeholder="メールアドレス"
                        fullWidth={true}
                        value={values.email}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell style={{ width: '20%' }}>
                      <TextField
                        error={!!touched.password && !!errors.password}
                        helperText={!touched.password ? errors.password : ''}
                        required
                        label="パスワード"
                        name="password"
                        type="password"
                        id="password"
                        placeholder="パスワード"
                        fullWidth={true}
                        value={values.password}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell style={{ width: '20%' }}>
                      <FormControl
                        error={!!touched.role && !!errors.role}
                        required={true}
                        fullWidth={true}>
                        <InputLabel>権限</InputLabel>
                        <Select
                          labelId="admin_role"
                          label="権限"
                          id="role"
                          // value={role}
                          defaultValue={role}
                          multiple
                          onChange={(event) => {
                            const selectedRoles = adminRoleData.filter(
                              (admin_role) => {
                                return (
                                  event.target.value as string[]
                                ).includes(admin_role.admin_role_name);
                              }
                            );
                            setFieldValue('role', selectedRoles);
                            setRole(selectedRoles);
                          }}
                          input={<Input />}>
                          {loginUser.admin_grants?.admin.any.create
                            ? adminRoleData.map((admin_role) => (
                                <MenuItem
                                  key={admin_role.admin_role_id}
                                  value={admin_role.admin_role_name}>
                                  {admin_role.admin_role_name}
                                </MenuItem>
                              ))
                            : loginUser.admin_role.map((admin_role) => (
                                <MenuItem
                                  key={admin_role.admin_role_id}
                                  value={admin_role.admin_role_name}>
                                  {admin_role.admin_role_name}
                                </MenuItem>
                              ))}
                        </Select>
                        {!!touched.role && !!errors.role && (
                          <FormHelperText>{errors.role}</FormHelperText>
                        )}
                      </FormControl>
                    </TableCell>
                    <TableCell style={{ width: '10%' }} align="center">
                      <IconButton
                        aria-label="search"
                        onClick={() => {
                          handleSubmit();
                        }}>
                        <AddCircleOutlineIcon color="primary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <></>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Typography
            color="textSecondary"
            variant="h5"
            gutterBottom
            component="div"
            style={{ marginTop: 30 }}>
            管理者一覧
          </Typography>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            onChange={(event, newValue) => setSearchedText(newValue)}
            style={{ width: '25vw' }}
            options={adminData.map((option) => option.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="管理者検索"
                margin="normal"
                variant="outlined"
                value={searchedText}
                InputProps={{ ...params.InputProps, type: 'search' }}
                onChange={(event) => setSearchedText(event.target.value)}
              />
            )}
          />
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '20%' }}>氏名</TableCell>
                  <TableCell align="left" style={{ width: '30%' }}>
                    社用メール
                  </TableCell>
                  <TableCell align="left" style={{ width: '25%' }}>
                    権限
                  </TableCell>
                  <TableCell align="center" style={{ width: '10%' }}>
                    編集
                  </TableCell>{' '}
                  <TableCell align="center" style={{ width: '5%' }}>
                    削除
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminData
                  .filter(
                    (admin) =>
                      admin.name.includes(searchedText) ||
                      admin.email.includes(searchedText)
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((admin) => {
                    return <AdminRow key={admin.admin_id} admin={admin} />;
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={adminData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </AdminRoleCtx.Provider>
    </MenuBarsTemplate>
  );
};

export default Administrotors;
