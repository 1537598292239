import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../controllers/services/useAuth';
import {
  LOGIN_INITIAL_VALUES,
  LOGIN_VALIDATION_SCHEMA,
} from '../../utils/validator';
import { useFormik } from 'formik';

import AuthTemplate from '../templates/AuthTemplate';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    background: 'linear-gradient(45deg, #17A673 100%, #FE6B8B 0%)',
  },
  image: {
    backgroundImage: `url(${window.location.origin}/LEAF.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    background: 'linear-gradient(45deg, #74ad6c 30%, #55a617 70%)',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: 'linear-gradient(45deg, #17A673 100%, #FE6B8B 0%)',
  },
  notchedOutline: {
    borderWidth: '2px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: '17A673',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#17A673',
      },
    },
  },
}));

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://arteryex.biz/about-us/">
        with LEAF
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
};

const Login: React.FC = () => {
  const classes = useStyles();
  const router = useHistory();
  const { useRequestLoginMutation } = useAuth();
  const requestLoginMutate = useRequestLoginMutation().mutateAsync;

  const { values, touched, handleSubmit, handleChange, errors } = useFormik({
    initialValues: LOGIN_INITIAL_VALUES,
    validationSchema: LOGIN_VALIDATION_SCHEMA, // （2）
    onSubmit: onSubmit,
  });

  async function onSubmit() {
    try {
      await requestLoginMutate(values);
      router.push('/dashboard');
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <AuthTemplate>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          ログイン
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            error={touched.email && !!errors.email}
            helperText={touched.email ? errors.email : ''}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            placeholder="メールアドレス"
            value={values.email}
            onChange={handleChange}
            InputLabelProps={{ style: { color: '#17A673' } }}
            name="email"
            autoComplete="email"
            autoFocus
            className={classes.notchedOutline}
          />
          <TextField
            error={!!touched.password && !!errors.password}
            helperText={touched.password ? errors.password : ''}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            placeholder="パスワード"
            value={values.password}
            onChange={handleChange}
            InputLabelProps={{ style: { color: '#17A673' } }}
            type="password"
            id="password"
            autoComplete="current-password"
            className={classes.notchedOutline}
          />

          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            color="primary"
            type="submit">
            ログイン
          </Button>

          {/* <Grid container>
            <Grid item xs>
              <Link href="/reset_password" variant="body2">
                パスワードをお忘れの方
              </Link>
            </Grid>
          </Grid> */}

          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
      </div>
    </AuthTemplate>
  );
};

export default Login;
