import { commonHttpClient } from './commonHttpClient';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useToast } from '../../components/molecules/toastMolecules';
import {
  User,
  CreateUserPayload,
  EditUserPayload,
} from '../../utils/interfaces/User';
import { Department } from '../../utils/interfaces/Department';

export const useUser = () => {
  const { http } = commonHttpClient();
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const getUsers = async () => {
    const { data } = await http.get('/users');
    return data as User[];
  };

  const getDeptUser = async () => {
    const { data } = await http.get('/departments');
    return data as Department[];
  };

  const createUser = async (
    createUserPayload: CreateUserPayload
  ): Promise<void> => {
    console.log("useuser",createUserPayload);
    await http.post('/users', createUserPayload); 
  };

  const createDemoUser = async (
    createUserPayload: CreateUserPayload
  ): Promise<void> => {
    await http.post('/companybattle', createUserPayload);
  };

  const editUser = async (editUserPayload: EditUserPayload): Promise<void> => {
    await http.patch('/users/edit', editUserPayload);
  };

  const deleteUser = async (user_id: number): Promise<void> => {
    await http.delete(`/users/${user_id}`);
  };

  const deleteDemoUser = async (user_id: number): Promise<void> => {
    await http.delete(`/companybattle/${user_id}`);
  };

  const useGetUserMutation = () => useQuery('Users', getUsers);
  
  const useGetDeptMutation = () => useQuery('departments', getDeptUser);
  

  const useCreateUser = () =>
    useMutation(createUser, {
      onSuccess: () => {
        showToast(
          'success',
          'ユーザーを追加しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('Users');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          if (e.message === 'Request failed with status code 400') {
            showToast('error', '既にユーザーが登録されています', e.message, 3000);
          } else {
            showToast('error', 'データ作成に失敗しました', e.message, 3000);
          }
        }
      },
    });

  const useCreateDemoUser = () =>
    useMutation(createDemoUser, {
      onSuccess: () => {
        showToast(
          'success',
          'ユーザーを追加しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('Users');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          if (e.message === 'Request failed with status code 400') {
            showToast('error', '既にユーザーが登録されています', e.message, 3000);
          } else {
            showToast('error', 'データ作成に失敗しました', e.message, 3000);
          }
        }
      },
    });

  const useEditUser = () =>
    useMutation(editUser, {
      onSuccess: () => {
        showToast(
          'success',
          'ユーザーを編集しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('userDetails');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          if (e.message === 'Request failed with status code 400') {
            showToast('error','重複エラー', `社員番号かメールアドレスが重複するユーザーがいます。${e.message}`,  3000);
          }
          else{
            showToast('error', '', e.message, 3000);
          }
        }
      },
    });

  const useDeleteUser = () =>
    useMutation(deleteUser, {
      onSuccess: () => {
        showToast(
          'success',
          'ユーザーを削除しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('Users');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ削除に失敗しました', e.message, 3000);
        }
      },
    });

  const useDeleteDemoUser = () =>
    useMutation(deleteDemoUser, {
      onSuccess: () => {
        showToast(
          'success',
          'ユーザーを削除しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('Users');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ削除に失敗しました', e.message, 3000);
        }
      },
    });

  return {
    useGetUserMutation,
    useGetDeptMutation,
    useCreateUser,
    useCreateDemoUser,
    useDeleteUser,
    useDeleteDemoUser,
    useEditUser,
  };
};
