import React, { useRef, useState } from 'react';
import {
  Typography,
  Box,
  Paper,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuBarsTemplate from '../templates/MenuBarsTemplate';
import LoadingOverlay from 'react-loading-overlay';
import * as constants from '../../utils/CommonConstants';
import { useXlsToast } from '../../components/molecules/xlsToastMolecules';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import 'dayjs/locale/ja';
import axios from 'axios';

import ModalMolecures from '../molecules/ModalMolecules';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    filePickButton: {
      margin: theme.spacing(1),
      backgroundColor: '#FFFFFF',
      color: '#17A673',
      borderColor: '#17A673',
      borderWidth: '#17A673',
      marginLeft: 20,
    },
    downloadButton: {
      margin: theme.spacing(1),
      backgroundColor: '#FFFFFF',
      borderColor: '#068DE7',
      borderWidth: '1px',
      color: '#068DE7',
      marginLeft: 20,
    },
    uploadButton: {
      margin: theme.spacing(1),
      backgroundColor: '#FFFFFF',
      color: '#FF0000',
      borderColor: '#FF0000',
      borderWidth: '1px',
      marginLeft: 20,
    },
    selectedFileTypo: {
      color: '#362AD4',
      borderColor: '#362AD4',
      textDecoration: 'underline',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonProgress: {
      color: '#362AD4',
      borderColor: '#362AD4',
      borderWidth: '1px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -13,
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    paper: {
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(1),
      margin: theme.spacing(),
    },
  })
);

const Import: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const timer = React.useRef<number>();

  // ========= xlsx =================================
  const fileInput = useRef<HTMLInputElement>(null);
  const fileResetInput = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMailModalOpen, setIsMailModalOpen] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [uploadAlertMessage, setUploadAlertMessage] = useState('');
  const { showToast } = useXlsToast();
  const [isMailSent, setIsMailSent] = useState(false);

  const handleTriggerReadFile = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleResetTriggerReadFile = () => {
    if (fileResetInput.current) {
      fileResetInput.current.click();
    }
  };

  const handleReadFile = (fileObj: File) => {
    if (fileObj) {
      setFileName(fileObj.name);
    }
  };

  const toggleMailFlag = () => {
    setIsMailSent(!isMailSent);
  };

  const handleUpload = async (isForce: boolean, mail: boolean) => {
    try {
      if (!isLoading && fileInput.current) {
        setIsLoading(true);
        const params = new FormData();
        params.append('is_force', isForce.toString());
        params.append('is_mail_sent', mail.toString());
        if (fileInput.current.files !== null) {
          params.append('company_xlsx', fileInput.current.files[0]);
        }
        const client = axios.create({ withCredentials: true });
        const res = await client.post(
          constants.SERVER_URL + '/company_info/upload',
          params,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        showToast(
          'success',
          'エクセルファイルをアップロードしました',
          '',
          3000
        );
        setIsLoading(false);
      }
      setFileName('');
      handleResetTriggerReadFile();
    } catch (e: any) {
      timer.current = window.setTimeout(() => {
        if (e.response.status == 422) {
          setIsAlertModalOpen(true);
          setUploadAlertMessage(e.response.data.message);
        } else {
          setIsModalOpen(true);
          setUploadErrorMessage(e.response.data.message);
          setFileName('');
        }
        setIsLoading(false);
      }, 2000);
    }
  };

  return (
    <MenuBarsTemplate title="データインポート">
      <Typography component="h2" variant="h5" color="textSecondary">
        従業員情報一括編集
      </Typography>
      <LoadingOverlay active={isLoading} spinner text="アップロード中...">
        <Paper style={{ marginTop: 30 }}>
          <Box>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}>
              <a href={`${constants.SERVER_URL}/company_info/download`}>
                <Button
                  variant="outlined"
                  className={classes.downloadButton}
                  startIcon={<GetAppIcon style={{ color: '#068DE7' }} />}>
                  ダウンロード
                </Button>
              </a>
              <div
                style={{
                  marginTop: 13,
                  marginLeft: 5,
                }}>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  gutterBottom>
                  入力用xlsxファイルをダウンロード
                </Typography>
              </div>
            </div>
          </Box>
          <div
            style={{
              marginTop: 20,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Button
              variant="outlined"
              className={classes.filePickButton}
              onClick={() => handleTriggerReadFile()}
              startIcon={<AttachFileIcon style={{ color: '#17A673' }} />}>
              ファイル選択
            </Button>
            {!!fileName && (
              <div
                style={{
                  marginTop: 13,
                  marginLeft: 5,
                }}>
                <Typography
                  className={classes.selectedFileTypo}
                  color="textSecondary"
                  variant="subtitle2"
                  gutterBottom>
                  ファイル名：{fileName}
                </Typography>
              </div>
            )}
            {!fileName && (
              <div
                style={{
                  marginTop: 13,
                  marginLeft: 5,
                }}>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  gutterBottom>
                  入力したxlsxファイルを選択
                </Typography>
              </div>
            )}

            <form style={{ display: 'none' }}>
              <input
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ref={fileInput}
                onChange={(e: any) => {
                  e.preventDefault();
                  handleReadFile(e.currentTarget.files[0]);
                }}
              />

              <input id="resetButton" type="reset" ref={fileResetInput} />
            </form>
          </div>
          <div
            style={{
              marginTop: 20,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Button
              onClick={() => setIsMailModalOpen(true)}
              variant="outlined"
              className={classes.uploadButton}
              startIcon={
                <CloudUploadIcon
                  style={{ color: !fileName ? '#FAFAFA' : '#FF0000' }}
                />
              }
              disabled={!fileName ? true : false}>
              アップロード
            </Button>
            <div
              style={{
                marginTop: 13,
                marginLeft: 5,
              }}>
              <Typography
                color="textSecondary"
                variant="subtitle2"
                gutterBottom>
                ファイル名を確認してアップロード
              </Typography>
            </div>
          </div>
        </Paper>
      </LoadingOverlay>
      <ModalMolecures
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          noWrap
          style={{ margin: '2%' }}>
          アップロードエラー
        </Typography>
        <hr></hr>
        <DialogContent>
          <DialogContentText>
            アップロードに失敗しました
            <br />
            {uploadErrorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsModalOpen(false);
              setUploadErrorMessage('');
              setFileName('');
              handleResetTriggerReadFile();
            }}
            color="secondary">
            閉じる
          </Button>
        </DialogActions>
      </ModalMolecures>
      <ModalMolecures
        isOpen={isAlertModalOpen}
        onClose={() => setIsAlertModalOpen(false)}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          noWrap
          style={{ margin: '2%' }}>
          内容確認
        </Typography>
        <hr></hr>
        <DialogContent>
          <DialogContentText>{uploadAlertMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsAlertModalOpen(false);
              setUploadAlertMessage('');
              setFileName('');
              handleResetTriggerReadFile();
            }}
            color="primary">
            キャンセル
          </Button>
          <Button
            onClick={async () => {
              await handleUpload(true, isMailSent);
              setIsAlertModalOpen(false);
              setUploadAlertMessage('');
              handleResetTriggerReadFile();
            }}
            color="secondary">
            実行
          </Button>
        </DialogActions>
      </ModalMolecures>
      <ModalMolecures
        isOpen={isMailModalOpen}
        onClose={() => setIsMailModalOpen(false)}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          noWrap
          style={{ margin: '2%' }}>
          内容確認
        </Typography>
        <hr></hr>
        <DialogContent>従業員情報をアップロードします。</DialogContent>
        <DialogActions>
          <label>
            <input
              type="checkbox"
              checked={isMailSent}
              onChange={toggleMailFlag}
            />
            新規登録者に招待メールを送信する
          </label>
        </DialogActions>
        <DialogActions>
          <Button
            onClick={() => {
              setIsMailModalOpen(false);
              setFileName('');
              handleResetTriggerReadFile();
              setIsMailSent(false);
            }}
            color="primary">
            キャンセル
          </Button>
          <Button
            onClick={async () => {
              setIsMailModalOpen(false);
              await handleUpload(true, isMailSent);
              setIsMailModalOpen(false);
              setUploadAlertMessage('');
              handleResetTriggerReadFile();
              setIsMailSent(false);
            }}
            color="secondary">
            実行
          </Button>
        </DialogActions>
      </ModalMolecures>
    </MenuBarsTemplate>
  );
};

export default Import;
