import { useQueryClient, useQuery, useMutation } from 'react-query';
import { useToast } from '../../components/molecules/toastMolecules';
import {
  Chatgroups,
  CreateChatgroupsPayload,
  PutChatgroupsPayload,
  UpdateChatgroupsPayload,
} from '../../utils/interfaces/Chatgroups';
import { commonHttpClient } from './commonHttpClient';

export const useChatgroups = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  // 全トークグループ取得
  const getChatgroups = async () => {
    const { data } = await http.get('/chatgroups');
    return data as Chatgroups[];
  };
  const useGetChatgroupsMutation = () => useQuery('chatgroups', getChatgroups);

  // 参加ユーザー編集
  const putChatgroupsMember = async (
    updateChatgroupsPayload: PutChatgroupsPayload
  ): Promise<void> => {
    await http.put(`/chatgroups`, updateChatgroupsPayload);
  };

  const usePutChatgroupsMemberMutation = () =>
    useMutation(putChatgroupsMember, {
      onSuccess: () => {
        showToast(
          'success',
          '参加ユーザーを編集しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('chatgroups');
          queryClient.invalidateQueries('chatgroups');
        }, 299);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  // トークグループ新規作成
  const createChatgroups = async (
    createChatgroupsPayload: CreateChatgroupsPayload
  ): Promise<void> => {
    await http.post('/chatgroups', createChatgroupsPayload);
  };

  const useCreateChatgroupsMutation = () =>
    useMutation(createChatgroups, {
      onSuccess: () => {
        showToast(
          'success',
          'トークグループを作成しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('chatgroups');
        }, 299);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ取得に失敗しました', e.message, 3000);
        }
      },
    });
  // トークグループ名、グループの説明編集
  const updateChatgroups = async (
    updateChatgroupsPayload: UpdateChatgroupsPayload
  ): Promise<void> => {
    await http.patch('/chatgroups/', updateChatgroupsPayload);
  };

  const useUpdateChatgroupsMutation = () =>
    useMutation(updateChatgroups, {
      onSuccess: () => {
        showToast(
          'success',
          'グループ情報を更新しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('chatgroups');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ取得に失敗しました', e.message, 3000);
        }
      },
    });

  // トークグループ削除
  const deleteChatgroups = async (id: number): Promise<void> => {
    await http.delete(`/chatgroups/${id}`);
  };

  const useDeleteChatgroupsMutation = () =>
    useMutation(deleteChatgroups, {
      onSuccess: () => {
        showToast(
          'success',
          'グループを削除しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('chatgroups');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  return {
    useGetChatgroupsMutation,
    usePutChatgroupsMemberMutation,
    useCreateChatgroupsMutation,
    useUpdateChatgroupsMutation,
    useDeleteChatgroupsMutation,
  };
};
