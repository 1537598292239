import { useState } from 'react';
import { commonHttpClient } from './commonHttpClient';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useToast } from '../../components/molecules/toastMolecules';
import {
  GroupBattle,
  CreateGroupBattlePayload,
  UpdateGroupBattlePayload,
  PutGroupBattlePayload,
  CreateGroupBattleChatGroupsPayload,
} from '../../utils/interfaces/Battle';
import { ContactsOutlined } from '@material-ui/icons';

export const useGroupBattles = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  //全バトル取得
  const getGroupBattles = async () => {
    const { data } = await http.get('/groupbattles');
    return data as GroupBattle[];
  };

  const useGetGroupBattlesMutation = () =>
    useQuery('GroupBattles', getGroupBattles);

  // 指定したIDのバトル取得;
  const getSelectedGroupBattle = async (
    groupbattle_id: number
  ): Promise<GroupBattle> => {
    const { data } = await http.get(`/groupbattles/${groupbattle_id}`);
    return data as GroupBattle;
  };

  const useGetSelectedGroupBattleMutation = (groupbattle_id: number) =>
    useQuery('GroupBattle', () => getSelectedGroupBattle(groupbattle_id));

  //バトル新規作成
  const createGroupBattle = async (
    createGroupBattlePayload: CreateGroupBattlePayload
  ): Promise<void> => {
    await http.post('/groupbattles', createGroupBattlePayload);
  };

  const useCreateGroupBattleMutation = () =>
    useMutation(createGroupBattle, {
      onSuccess: () => {
        showToast(
          'success',
          '新規対抗戦を作成しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('GroupBattles');
        }, 299);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ取得に失敗しました', e.message, 3000);
        }
      },
    });

  //チャットグループ付き対抗戦作成
  const createGroupBattleChatGroups = async (
    createGroupBattleChatGroupPayload: CreateGroupBattleChatGroupsPayload
  ): Promise<void> => {
    await http.post(
      '/groupbattles/chat_groups',
      createGroupBattleChatGroupPayload
    );
  };
  const useCreateGroupBattleChatGroupsMutation = () =>
    useMutation(createGroupBattleChatGroups, {
      onSuccess: () => {
        showToast(
          'success',
          '対抗戦を作成しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('GroupBattles');
        }, 299);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ取得に失敗しました', e.message, 3000);
        }
      },
    });

  //削除
  const deleteGroupBattle = async (groupbattle_id: number): Promise<void> => {
    await http.delete(`/groupbattles/${groupbattle_id}`);
  };

  const useDeleteGroupBattleMutation = () =>
    useMutation(deleteGroupBattle, {
      onSuccess: () => {
        showToast(
          'success',
          '対抗戦を削除しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('GroupBattles');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  const updateGroupBattle = async (
    updateGroupBattlePayload: UpdateGroupBattlePayload
  ): Promise<void> => {
    const { data } = await http.patch(
      `/groupbattles`,
      updateGroupBattlePayload
    );
  };

  const useUpdateGroupBattleMutation = () =>
    useMutation(updateGroupBattle, {
      onSuccess: () => {
        showToast(
          'success',
          '対抗戦を編集しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('GroupBattles');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  const putGroupBattle = async (
    updateGroupBattlePayload: PutGroupBattlePayload
  ): Promise<void> => {
    const { data } = await http.put(`/groupbattles`, updateGroupBattlePayload);
  };

  const usePutGroupBattleMutation = () =>
    useMutation(putGroupBattle, {
      onSuccess: () => {
        showToast(
          'success',
          '対抗戦を編集しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('GroupBattles');
          queryClient.invalidateQueries('GroupBattle');
        }, 299);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  return {
    useGetGroupBattlesMutation,
    useCreateGroupBattleMutation,
    useDeleteGroupBattleMutation,
    useUpdateGroupBattleMutation,
    usePutGroupBattleMutation,
    useGetSelectedGroupBattleMutation,
    useCreateGroupBattleChatGroupsMutation,
  };
};
