import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import LoadingBubbleOrganisms from '../components/organisms/LoadingBubbleOrganisms';
import MenuBarsTemplate from '../components/templates/MenuBarsTemplate';
import { useAuth } from '../controllers/services/useAuth';

const PrivateRoute = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<Route<RouteProps>> &
    Readonly<RouteProps> &
    Readonly<{ children?: React.ReactNode }>
) => {
  const { useGetAuth } = useAuth();
  const { isLoading, data, isError, error } = useGetAuth();

  if (isLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (data === undefined || !Object.keys(data).length) {
    return <Redirect to={'/login'} />;
  }

  // const decodedTokenStr = localStorage.getItem('decoded_token');
  // const decodedToken: DecodedToken = JSON.parse(decodedTokenStr || '');

  return <Route {...props} />;
};
export default PrivateRoute;
