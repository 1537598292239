import React from 'react';
import ReactLoading from 'react-loading';

export interface LoadingBubbleOrganisms {}

const LoadingBubbleOrganisms: React.FC<LoadingBubbleOrganisms> = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
      }}>
      <ReactLoading type="bubbles" color="#17A673" height="20vh" width="20vh" />
    </div>
  );
};
export default LoadingBubbleOrganisms;
