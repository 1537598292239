import React, { useState } from 'react';
import DashbordCard from '../organisms/DashboardCard';
import MenuBarsTemplate from '../templates/MenuBarsTemplate';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import {
  getCompanyEmployee,
  getNotificationInfo,
  getUserData,
  MonthlyManageData,
  getAvarageSteps,
  MonthlyAvgStep,
} from '../../controllers/services/useCompany';
import {
  DashBoardStepDailyGraph,
  DashBoardStepMonthlyGraph,
  DashBoardStepWeeklyGraph,
  DashBoardStepAnnuallyGraph,
  DashboardLoginManagementMonthlyGraph,
  DashboardLoginManagementDailyGraph,
  DashboardLoginManagementWeeklyGraph,
  DashboardLoginManagementAnnuallyGraph,
} from '../organisms/Graph';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { useUser } from '../../controllers/services/useUser';
import LoadingBubbleOrganisms from '../organisms/LoadingBubbleOrganisms';
import { Link } from 'react-router-dom';
import { yellow } from '@material-ui/core/colors';
import { Box, Typography } from '@material-ui/core';
import { getTotalCompanyMember } from '../../controllers/services/useCompany';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#8fc31f' }, // テーマの色
  },
});

const useStyles = makeStyles({
  category: {
    fontSize: 30,
  },
  paper: {
    // display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(80),
      height: theme.spacing(45),
    },
  },
  graph: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
    // minHeight: 500,
    // maxHeight:600,
    height: 550,
    margin: theme.spacing(),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.secondary,
  },
  notification: {
    backgroundColor: yellow[100],
    borderRadius: 10,
    border: `3px solid ${yellow[300]}`,
  },
});

const DashBoard: React.FC = () => {
  const classes = useStyles();
  const date = dayjs();
  const thisMonth = date.format('YYYY-MM');
  const lastMonth = date.subtract(1, 'month').format('YYYY-MM');
  const { useGetUserMutation } = useUser();
  const { data } = useGetUserMutation();

  const [companyid, setCompanyId] = useState();
  const [showDailyActiveU, setShowDailyActiveU] = useState('daily');
  const [showGraphActive, setShowGraphActive] = useState('monthly');

  const { data: companyInfo } = useQuery('companyInfo', () =>
    getCompanyEmployee()
  );
  // ダッシュボード通知機能用
  // const { data: notificationData } = useQuery(
  //   'notificationInfo',
  //   () => getNotificationInfo(),
  //   {
  //     refetchOnWindowFocus: false,
  //     staleTime: 5 * 60 * 1000,
  //   }
  // );

  const { data: averageData } = useQuery(
    ['monthlyAvarage', 'monthly'],
    () => getAvarageSteps('monthly'),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: loginData, isFetching: monthlyLoginData } = useQuery(
    ['loginData', 'monthly'],
    () => getUserData('monthly', 'login'),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { isFetching: dailyLoginData } = useQuery(
    ['loginData', 'daily'],
    () => getUserData('daily', 'login'),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { isFetching: dailyPerficientData } = useQuery(
    ['perficientData', 'daily'],
    () => getUserData('daily', 'perficient'),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { isFetching: dailyMemberData } = useQuery(
    ['memberData', 'daily'],
    () => getTotalCompanyMember('daily'),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { isFetching: monthlyPerficientData } = useQuery(
    ['perficientData', 'monthly'],
    () => getUserData('monthly', 'perficient'),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { isFetching: monthlyMemberData } = useQuery(
    ['memberData', 'monthly'],
    () => getTotalCompanyMember('monthly'),
    {
      refetchOnWindowFocus: false,
    }
  );

  // const { data: graphData } = useQuery(['dashboardRecord', companyid], () =>
  //   getDashboardRecord(Number(companyid))
  // );

  //今月の歩数
  const tmp2 = (averageData as MonthlyAvgStep[]) ?? [];
  const monthlyStepData = tmp2.find(
    (row: MonthlyAvgStep) => row.month == thisMonth
  );
  const totalStepThisMonth = monthlyStepData?.record ?? 0;

  //先月の歩数
  const tmp3 = (averageData as MonthlyAvgStep[]) ?? [];
  const lastMonthlyStepData = tmp3.find(
    (row: MonthlyAvgStep) => row.month == lastMonth
  );
  const totalStepLastMonth = lastMonthlyStepData?.record ?? 0;

  //会社名
  const companyName = companyInfo?.name;

  // 今月のアクティブユーザー数
  const tmp1 = (loginData as MonthlyManageData[]) ?? [];
  const filteredThisMonthActiveUser = tmp1.find(
    (row: MonthlyManageData) => row.month == thisMonth + '月'
  );
  const thisMonthActiveUser = filteredThisMonthActiveUser?.count ?? 0;

  // 先月のアクティブユーザー数
  const tmp = (loginData as MonthlyManageData[]) ?? [];
  const filteredLastMonthActiveUser = tmp.find(
    (row: MonthlyManageData) => row.month == lastMonth + '月'
  );
  const lastMonthActiveUser = filteredLastMonthActiveUser?.count ?? 0;

  const fetching: boolean =
    dailyLoginData ||
    dailyPerficientData ||
    dailyMemberData ||
    monthlyLoginData ||
    monthlyPerficientData ||
    monthlyMemberData;
  if (!data || fetching) {
    return (
      // <MenuBarsTemplate title="">
      <div>
        <LoadingBubbleOrganisms />
        <Typography align="center">
          データ読み込み中...少々お待ちください。
        </Typography>
      </div>
      // </MenuBarsTemplate>
    );
  }

  return (
    <MenuBarsTemplate title="ダッシュボード">
      {/* {Number(notificationData) > 100 ?
      <Paper className={classes.notification}>
        <Box p={1.5}>
          <Typography variant="h5" display='inline' style={{ fontWeight: 600, color: "#A37B0F" }}>Information</Typography>
          <Typography variant="h5" display='inline'> 前日から{Math.round((Number(notificationData) / 100 - 1) * 100)}%歩数が増えました！</Typography>
        </Box>
      </Paper>
      :<Box></Box>} */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <DashbordCard category="会社名" title={companyName || ''} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <DashbordCard
            category="ユーザー数"
            title={`${data.length.toString()}人`}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <DashbordCard
            category="今月の全従業員の平均歩数"
            title={
              totalStepThisMonth == undefined
                ? '0' + '歩'
                : totalStepThisMonth + '歩'
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <DashbordCard
            category="先月の全従業員の平均歩数"
            title={
              totalStepLastMonth == undefined
                ? '0' + '歩'
                : totalStepLastMonth + '歩'
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <DashbordCard
            category="今月のアプリ利用者数"
            title={
              thisMonthActiveUser == undefined
                ? '0' + '人'
                : thisMonthActiveUser + '人'
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <DashbordCard
            category="先月のアプリ利用者数"
            title={
              lastMonthActiveUser == undefined
                ? '0' + '人'
                : lastMonthActiveUser + '人'
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}></Grid>
      </Grid>
      <br />
      <br />
      <div>
        <Grid container spacing={3} alignItems="stretch" justify="center">
          <Grid item xs={6}>
            {/* {showGraphActive == 'daily' && (
            <Paper className={classes.graph}>
              <DashBoardStepDailyGraph
               onClose={(prop) => setShowGraphActive(prop)}
              />
            </Paper>
            )} */}
            {/* {showGraphActive == 'weekly' && (
            <Paper className={classes.graph}>
              <DashBoardStepWeeklyGraph
               onClose={(prop) => setShowGraphActive(prop)}
              />
            </Paper>
            )} */}
            {showGraphActive == 'monthly' && (
              <Paper className={classes.graph}>
                <h6 style={{ textAlign: 'center' }}>平均歩数推移</h6>
                <DashBoardStepMonthlyGraph
                  onClose={(prop) => setShowGraphActive(prop)}
                />
              </Paper>
            )}
            {/* {showGraphActive == 'annually' && (
            <Paper className={classes.graph}>
              <DashBoardStepAnnuallyGraph
               onClose={(prop) => setShowGraphActive(prop)}
              />
            </Paper>
            )} */}
          </Grid>
          <Grid item xs={6}>
            {showDailyActiveU == 'daily' && (
              <Paper className={classes.graph}>
                <DashboardLoginManagementDailyGraph
                  onClose={(prop) => setShowDailyActiveU(prop)}
                />
              </Paper>
            )}
            {/* {showDailyActiveU == 'weekly' && (
              <Paper className={classes.graph}>
                <DashboardLoginManagementWeeklyGraph
                  onClose={(prop) => setShowDailyActiveU(prop)}
                />
              </Paper>
            )} */}
            {showDailyActiveU == 'monthly' && (
              <Paper className={classes.graph}>
                <DashboardLoginManagementMonthlyGraph
                  onClose={(prop) => setShowDailyActiveU(prop)}
                />
              </Paper>
            )}
            {/* {showDailyActiveU == 'annually' && (
              <Paper className={classes.graph}>
                <DashboardLoginManagementAnnuallyGraph
                  onClose={(prop) => setShowDailyActiveU(prop)}
                />
              </Paper>
            )} */}
          </Grid>
        </Grid>
      </div>
    </MenuBarsTemplate>
  );
};

export default DashBoard;
