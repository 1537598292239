import { commonHttpClient } from './commonHttpClient';
import { useQueryClient, useMutation, useQuery } from 'react-query';
// import { useToast } from '../../components/molecules/toastMolecules';
import { AdminsRole } from '../../utils/interfaces/Admin';

export const useAdminRole = () => {
  const { http } = commonHttpClient();
  // const queryClient = useQueryClient();
  // const { showToast } = useToast();

  const getAdminRole = async (): Promise<AdminsRole[]> => {
    const { data } = await http.get('/admin_role');
    return data as AdminsRole[];
  };

  const useReadAdminRole = () => useQuery('AdminRole', getAdminRole);

  return {
    useAdminRole,
    useReadAdminRole,
  };
};
