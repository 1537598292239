import React, { memo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      padding: 20,
      outline: 'none',
      borderRadius: 10,
    },
  })
);

export interface ModalMolecures {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  fullWidth?: boolean;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

const ModalMolecures: React.FC<ModalMolecures> = ({
  children,
  isOpen,
  onClose,
  fullWidth = true,
  maxWidth = undefined,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={isOpen}>
        <div>{children}</div>
      </Fade>
    </Dialog>
  );
};
export default memo(ModalMolecures);
