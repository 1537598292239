import { Input, MenuItem, Select, Typography } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import MenuBarsTemplate from '../templates/MenuBarsTemplate';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Build } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useQueryClient, useQuery } from 'react-query';
import LoadingBubbleOrganisms from '../organisms/LoadingBubbleOrganisms';
import { useQueryParams } from '../../utils/useQueryParams';
import { getUserDetailInfo } from '../../controllers/services/Api';
import { StepGraph } from '../organisms/Graph';
import ModalMolecures from '../molecules/ModalMolecules';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { useHistory } from 'react-router-dom';
import { commonHttpClient } from '../../controllers/services/commonHttpClient';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Avatar from '@material-ui/core/Avatar';
import { useUser } from '../../controllers/services/useUser';
import { useToast } from '../../components/molecules/toastMolecules';
import { LoginUserContext } from '../../utils/CommonContext';
import { useFormik, validateYupSchema } from 'formik';
import { User } from '../../utils/interfaces/User';
import { USERS_EDIT_VALIDATION_SCHEMA } from '../../utils/validator';
import { Department } from '../../utils/interfaces/Department';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'center';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: '受診年度', minWidth: 100, align: 'center' },
  { id: 'code', label: '総合評価', minWidth: 100, align: 'center' },
  { id: 'name', label: '性別', minWidth: 100, align: 'center' },
  { id: 'code', label: '既往歴', minWidth: 100, align: 'center' },
  { id: 'name', label: '血圧(上)', minWidth: 100, align: 'center' },
  { id: 'code', label: '血圧(下)', minWidth: 100, align: 'center' },
  { id: 'name', label: '中性脂肪(mg/dℓ)', minWidth: 100, align: 'center' },
  { id: 'code', label: 'ヘモグロビン(g/dl)', minWidth: 100, align: 'center' },
  { id: 'name', label: 'HbA1c(%)', minWidth: 100, align: 'center' },
  { id: 'code', label: 'γ-GTP(U/L)', minWidth: 100, align: 'center' },
  { id: 'name', label: 'ALT(U/L)', minWidth: 100, align: 'center' },
  { id: 'code', label: 'AST(U/UL)', minWidth: 100, align: 'center' },
  { id: 'name', label: 'BMI', minWidth: 100, align: 'center' },
  { id: 'code', label: '空腹時血糖(mg/dℓ)', minWidth: 100, align: 'center' },
  { id: 'name', label: 'HDL(mg/dℓ)', minWidth: 100, align: 'center' },
  { id: 'code', label: 'LDL(mg/dℓ)', minWidth: 100, align: 'center' },
  {
    id: 'name',
    label: '総コレステロール(mg/dℓ)',
    minWidth: 100,
    align: 'center',
  },
  { id: 'code', label: '尿酸値(mg/dℓ)', minWidth: 100, align: 'center' },
];

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#8fc31f' }, // テーマの色
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '28%',
    },
    userCard: {
      display: 'flex',
      width: '100%',
    },
    category: {
      fontSize: 30,
    },
    container: {
      maxHeight: 440,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    table: {
      minWidth: 650,
    },
    toggleButton: {
      marginLeft: theme.spacing(2),
    },
    paper: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(82),
        height: theme.spacing(45),
      },
    },
    passChange: {
      marginLeft: theme.spacing(2),
      backgroundColor: '#FFFFFF',
      outline: 'true',
      textDecorationColor: '#17A673',
      outlineColor: '#17A673',
      borderColor: '#17A673',
      borderWidth: '1px',
      color: '#17A673',
    },
    deleteUser: {
      marginLeft: theme.spacing(2),
      backgroundColor: '#FFFFFF',
      outlineColor: '#ff0000',
      borderColor: '#ff0000',
      borderWidth: '1px',
      outline: 'true',
      color: '#ff0000',
    },
    editUser: {
      marginLeft: theme.spacing(2),
      textDecorationColor: '#17A673',
      outlineColor: '#17A673',
      borderColor: '#17A673',
      borderWidth: '1px',
      outline: 'true',
      color: '#17A673',
    },
    progressEditingUser: {
      marginLeft: theme.spacing(2),
      textDecorationColor: '#17A673',
      outlineColor: '#17A673',
      borderColor: '#17A673',
      borderWidth: '1px',
      outline: 'true',
      color: '#17A673',
    },

    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    card: {
      display: 'flex',
      transition: '0.3s',
      boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
      '&:hover': {
        boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(40),
        height: theme.spacing(35),
      },
    },
    content: {
      flex: '1 0 auto',
    },
    avatar: {
      border: '2px solid white',
      width: 100,
      height: 100,
      marginLeft: 40,
    },
    form: {
      height: 50,
      width: '80%',
      marginTop: 20,
      marginLeft: 45,
    },
  })
);
function UserEditCard(props: { userDetail: User }) {
  const { userDetail: userDetailOrigin } = props;
  const classes = useStyles();
  const history = useHistory();
  const { http } = commonHttpClient();
  const queryParameters = useQueryParams();
  const uid = queryParameters.get('uid');
  const { showToast } = useToast();
  // const { data } = useQuery(['userDetails', uid], () =>
  //   getUserDetailInfo(Number(uid))
  // );
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [user, setUser] = React.useState<User>(userDetailOrigin);
  const [pswd, setPswd] = useState('');
  const [changeModalState, setChangeModalState] = useState(false);
  // const [doneModal, setDoneModal] = useState(false);
  const { useDeleteUser, useEditUser, useDeleteDemoUser } = useUser();
  const deleteUserMutate = useDeleteUser().mutate;
  const deleteDemoUserMutate = useDeleteDemoUser().mutate;
  const loginUser = useContext(LoginUserContext);
  let isDemo: any;
  const editUserMutate = useEditUser().mutate;
  const queryClient = useQueryClient();
  const [previous, setPrevious] = React.useState({} as User);
  const {useGetDeptMutation} = useUser();

  const {
    isLoading: deptLoading,
    data: deptData,
 } = useGetDeptMutation();

  if (loginUser.company) {
    isDemo = new Map(Object.entries(loginUser.company)).get('is_demo');
  } else {
    isDemo = 1;
  }
  const avater = userDetailOrigin?.avatar;

  useEffect(() => {
    setUser(userDetailOrigin);
  }, [userDetailOrigin]);
  const onToggleEditMode = () => {
    if (!Object.keys(previous).length) {
      setPrevious((state) => ({ ...state, ...userDetailOrigin }));
    }
    setIsEditMode((state) => !state);
  };
  const handleDeleteUser = async () => {
    try {
      if (isDemo === 0) {
        await deleteUserMutate(Number(uid));
        history.push('/users');
      } else {
        await deleteDemoUserMutate(Number(uid));
        history.push('/users');
      }
    } catch (err) {
      console.log(' delete error ', err);
    }
  };
  const handleChangePass = async () => {
    try {
      await http.patch('/users', {
        uid: uid,
        delete_flag: 0,
        pswd: pswd,
      });
      showToast('success', 'パスワードを変更しました', '', 3000);
      setChangeModalState(false);
    } catch (err) {
      showToast(
        'error',
        'パスワードエラー',
        'パスワードは8文字以上32文字以内の英数字で入力してください',
        3000
      );
    }
  };
  const {
    values,
    touched,
    handleSubmit,
    handleChange,
    errors,
    setFieldValue,
    resetForm,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      uname: user.uname,
      rname: user.rname,
      email: user.email,
      employeeId: user.employeeId,
      department: user.departments,
    },
    validationSchema: USERS_EDIT_VALIDATION_SCHEMA,
    onSubmit: onSubmit,
  });

  const departments = userDetailOrigin.departments?.map((elm: any) => {
    return elm.department_name;
  });

  async function onSubmit() {
    try {
      if (
        values.uname != previous.uname ||
        values.rname != previous.rname ||
        values.email != previous.email ||
        values.employeeId != previous.employeeId ||
        values.department != previous.departments
      ) {      
        await editUserMutate({
          uid: user.uid,
          uname: values.uname,
          rname: values.rname,
          email: values.email,
          employeeId: values.employeeId,
          department: values.department
        });
      }
      setPrevious((state) => {
        state = {} as User;
        return state;
      });
      resetForm();
      setChangeModalState(false);
      onToggleEditMode();
    } catch (err) {
      console.log(err);
    }
  }

  const onRevert = () => {
    setUser(previous);

    setPrevious((state) => {
      state = {} as User;
      return state;
    });
    resetForm();
    onToggleEditMode();
  };

  const options = {
    title: '確認',
    message: '本当に削除しますか？',
    buttons: [
      {
        label: 'いいえ',
        onClick: () => onclose,
      },
      {
        label: 'はい',
        onClick: () => handleDeleteUser(),
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    overlayClassName: 'root',
  };
  const options2 = {
    title: '確認',
    message: '編集を確定しますか？',
    buttons: [
      {
        label: 'いいえ',
        onClick: () => onRevert(),
      },
      {
        label: 'はい',
        onClick: () => onSubmit(),
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    overlayClassName: 'paper',
  };

  if ( !deptData ) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if ( deptLoading ) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  return (
    <React.Fragment>
      <Typography color="inherit" variant="h5" gutterBottom component="div">
        ユーザー詳細
        {!loginUser.admin_grants?.user.any.read ||
        loginUser.admin_grants?.user.any.create ||
        loginUser.admin_grants?.user.any.delete ||
        loginUser.admin_grants?.user.any.update ? (
          <>
            <Button
              variant="outlined"
              className={classes.passChange}
              startIcon={<Build />}
              onClick={() => {
                setChangeModalState(true);
              }}>
              パスワード変更
            </Button>
            {!isEditMode ? (
              <>
                <Button
                  variant="outlined"
                  className={classes.editUser}
                  startIcon={<EditIcon />}
                  onClick={() => {
                    onToggleEditMode();
                  }}>
                  ユーザー情報編集
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  className={classes.progressEditingUser}
                  startIcon={<CheckIcon />}
                  onClick={() => {
                    confirmAlert(options2);
                  }}
                  disabled={!isValid}>
                  編集確定
                </Button>
                <Button
                  variant="outlined"
                  className={classes.deleteUser}
                  startIcon={<ClearIcon />}
                  onClick={() => {
                    queryClient.invalidateQueries('userDetails');
                    resetForm();
                    onToggleEditMode();
                  }}>
                  キャンセル
                </Button>
              </>
            )}
            <Button
              variant="outlined"
              className={classes.deleteUser}
              startIcon={<DeleteIcon />}
              onClick={() => confirmAlert(options)}>
              ユーザー削除
            </Button>
          </>
        ) : (
          <></>
        )}
      </Typography>

      <Paper className={classes.userCard}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '10%' }} align="center">
                  <Avatar
                    className={classes.avatar}
                    src={avater ? avater : 'aiueo'}
                  />
                </TableCell>
                <TableCell size="small" style={{ width: '20%' }}>
                  <Typography variant="subtitle2" color="textSecondary" noWrap>
                    ニックネーム
                  </Typography>
                  <Typography variant="h6" color="inherit" noWrap>
                    {user.uname}
                  </Typography>
                  {isEditMode ? (
                    <>
                      <TextField
                        label="ニックネーム"
                        value={values.uname}
                        error={!!touched.uname && !!errors.uname}
                        required
                        helperText={!touched.uname ? errors.uname : ''}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onKeyPress={(e) => {
                          if (e.key == 'Enter' && isValid) {
                            e.preventDefault();
                            confirmAlert(options2);
                          }
                        }}
                        name="uname"
                        id="uname"
                        placeholder="ニックネーム"
                        fullWidth={true}
                        inputProps={{ minLength: 1 }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell size="small" style={{ width: '20%' }}>
                  <Typography variant="subtitle2" color="textSecondary" noWrap>
                    社員名
                  </Typography>
                  <Typography variant="h6" color="inherit" noWrap>
                    {user.rname}
                  </Typography>
                  {isEditMode ? (
                    <>
                      <TextField
                        label="社員名"
                        value={values.rname}
                        error={!!touched.rname && !!errors.rname}
                        required
                        helperText={!touched.rname ? errors.rname : ''}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onKeyPress={(e) => {
                          if (e.key == 'Enter' && isValid) {
                            e.preventDefault();
                            confirmAlert(options2);
                          }
                        }}
                        name="rname"
                        id="rname"
                        placeholder="社員名"
                        fullWidth={true}
                        inputProps={{ minLength: 1 }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell style={{ width: '20%' }}>
                  <Typography variant="subtitle2" color="textSecondary" noWrap>
                    メールアドレス
                  </Typography>
                  <Typography variant="subtitle1" color="inherit" noWrap>
                    {user.email}
                  </Typography>
                  {isEditMode ? (
                    <>
                      <TextField
                        label="メールアドレス"
                        value={values.email}
                        error={!!touched.email && !!errors.email}
                        required
                        helperText={!touched.email ? errors.email : ''}
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          if (e.key == 'Enter' && isValid) {
                            e.preventDefault();
                            confirmAlert(options2);
                          }
                        }}
                        name="email"
                        id="email"
                        placeholder="メールアドレス"
                        fullWidth={true}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell style={{ width: '10%' }}>
                  <Typography variant="subtitle2" color="textSecondary" noWrap>
                    社員番号
                  </Typography>
                  <Typography variant="h6" color="inherit" noWrap>
                    {user.employeeId}
                  </Typography>
                  {isEditMode ? (
                    <>
                      <TextField
                        label="社員番号"
                        value={values.employeeId}
                        error={!!touched.employeeId && !!errors.employeeId}
                        required
                        helperText={
                          !touched.employeeId ? errors.employeeId : ''
                        }
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          if (e.key == 'Enter' && isValid) {
                            e.preventDefault();
                            confirmAlert(options2);
                          }
                        }}
                        name="employeeId"
                        id="employeeId"
                        placeholder="社員番号"
                        fullWidth={true}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell style={{ width: '20%' }}>
                  <Typography variant="subtitle2" color="textSecondary" noWrap>
                      所属部署
                  </Typography>
                  <Typography variant="h6" color="inherit" noWrap>
                      {departments?.length == 0 || departments == undefined
                      ? '未設定'
                      : departments[0]}
                  </Typography>
                  { (()=> {
                     if( deptData.length !== 0 ){
                      return(
                      <div  style={{ width: '20%' }}>
                        {isEditMode ? (
                        <Select
                          labelId="department_id"
                          label="所属部署"
                          id="department_id"
                          value={values.department[0].department_name}
                          onChange={(event) => {
                            const selectedDepartment = deptData.filter(
                              (dept) => {
                                return(
                                  event.target.value as string[]
                               ).includes(dept.department_name); 
                             }
                           );
                           setFieldValue('department', selectedDepartment);
                          }}
                         input={<Input />}>
                          {deptData.map((dept)=> (
                           <MenuItem
                           key={dept.department_id}
                           value={dept.department_name}>
                           {dept.department_name}
                           </MenuItem>
                          ))}
                        </Select>
                          ) : (
                          <></>
                          ) }
                      </div>
                          )
                        }              
                  })() }
                </TableCell>
              </TableRow>
           </TableBody>
         </Table>
       </TableContainer>
      </Paper>
      <ModalMolecures
        isOpen={changeModalState}
        onClose={() => setChangeModalState(false)}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          noWrap
          style={{ margin: '2%' }}>
          パスワード変更
        </Typography>
        <hr></hr>
        <DialogContent>
          <DialogContentText>
            新しいパスワードを入力してください
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="validation-error"
            label="新しいパスワードを入力"
            type="password"
            fullWidth
            value={pswd}
            onChange={(evt) => setPswd(evt.target.value)}
            error={pswd.length < 8 || pswd.length > 32}
            helperText={
              pswd.length < 8 || pswd.length > 32
                ? '8文字以上32文字以内で入力してください'
                : ''
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChangeModalState(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={() => handleChangePass()} color="primary">
            決定
          </Button>
        </DialogActions>
      </ModalMolecures>
    </React.Fragment>
  );
}

const UserDetail: React.FC = () => {
  const classes = useStyles();
  const queryParameters = useQueryParams();
  const uid = queryParameters.get('uid');
  const [reportOpen, setReportOpen] = useState(false);
  const { data } = useQuery(['userDetails', uid], () =>
    getUserDetailInfo(Number(uid))
  );
  const loginUser = useContext(LoginUserContext);
  let isDemo: any;
  if (loginUser.company) {
    isDemo = new Map(Object.entries(loginUser.company)).get('is_demo');
  } else {
    isDemo = 1;
  }
  const toggleReportOpenState = () => {
    setReportOpen(!reportOpen);
  };

  if (!data) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  return (
    <MenuBarsTemplate title="ユーザー詳細">
      <UserEditCard key={data.uid} userDetail={data} />
      <Typography
        component="h5"
        variant="h5"
        color="inherit"
        noWrap
        style={{ marginTop: 30 }}>
        歩行記録推移
      </Typography>
      <div
        style={{ display: 'flex', flexDirection: 'row' }}
        className={classes.paper}>
        <Paper>
          <StepGraph />
        </Paper>
        {/* <Paper>
          <DistanceGraph />
        </Paper> */}
      </div>

      {(isDemo === 0 && loginUser.admin_grants?.healthcheck.any.read) ||
      loginUser.admin_grants?.healthcheck.self.read ? (
        <div>
          <Typography
            component="h5"
            variant="h5"
            color="inherit"
            noWrap
            style={{ marginTop: 30 }}>
            診断結果一覧
            <Button
              className={classes.toggleButton}
              variant="outlined"
              vertical-align="center"
              size="small"
              onClick={() => toggleReportOpenState()}>
              {reportOpen ? '表示を隠す' : '表示する'}
            </Button>
          </Typography>
        </div>
      ) : (
        <></>
      )}
      {reportOpen &&
      ((isDemo === 0 && loginUser.admin_grants?.healthcheck.any.read) ||
        loginUser.admin_grants?.healthcheck.self.read) ? (
        <Paper style={{ marginTop: 5, marginLeft: 5 }}>
          <Box margin={1}>
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className={classes.table}>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.reports.map((elm: any, idx: number) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                        <TableCell align="center">{elm['during']}</TableCell>
                        <TableCell align="center">{elm['total']}</TableCell>
                        <TableCell align="center">{elm['sex']}</TableCell>
                        <TableCell align="center">{elm['past']}</TableCell>
                        <TableCell align="center">{elm['bloodup']}</TableCell>
                        <TableCell align="center">{elm['bloodlow']}</TableCell>
                        <TableCell align="center">{elm['TG']}</TableCell>
                        <TableCell align="center">{elm['hmg']}</TableCell>
                        <TableCell align="center">{elm['HbA1c']}</TableCell>
                        <TableCell align="center">{elm['GTP']}</TableCell>
                        <TableCell align="center">{elm['ALT']}</TableCell>
                        <TableCell align="center">{elm['AST']}</TableCell>
                        <TableCell align="center">{elm['BMI']}</TableCell>
                        <TableCell align="center">{elm['glucose']}</TableCell>
                        <TableCell align="center">{elm['HDL']}</TableCell>
                        <TableCell align="center">{elm['LDL']}</TableCell>
                        <TableCell align="center">{elm['Tchol']}</TableCell>
                        <TableCell align="center">{elm['UA']}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      ) : (
        <></>
      )}
    </MenuBarsTemplate>
  );
};

export default UserDetail;
