import { commonHttpClient } from './commonHttpClient';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useToast } from '../../components/molecules/toastMolecules';
import {
  Challenge,
  CreateChallengePayload,
} from '../../utils/interfaces/Challenge';

export const useChallenges = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const useGetChallenges = () => useQuery('Challenges', getChallenges);

  const getChallenges = async () => {
    const { data } = await http.get('/challenges');
    return data as Challenge[];
  };

  const createChallenge = async (
    createChallengePayload: CreateChallengePayload
  ): Promise<void> => {
    await http.post('/challenges', createChallengePayload);
  };

  const deleteChallenge = async (id: number): Promise<void> => {
    await http.delete(`/challenges/${id}`);
  };

  const useCreateChallengeMutation = () =>
    useMutation(createChallenge, {
      onSuccess: () => {
        showToast('success', '新規チャレンジを作成しました', '', 3000);
        setTimeout(() => {
          queryClient.invalidateQueries('Challenges');
        }, 500);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  const useDeleteChallengeMutation = () =>
    useMutation(deleteChallenge, {
      onSuccess: () => {
        showToast('success', 'チャレンジを削除しました', '', 3000);
        setTimeout(() => {
          queryClient.invalidateQueries('Challenges');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  return {
    useGetChallenges,
    deleteChallenge,
    useCreateChallengeMutation,
    useDeleteChallengeMutation,
  };
};
