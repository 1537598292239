import { Line, Chart, Doughnut, Bar, getElementAtEvent } from 'react-chartjs-2';
import 'chart.js/auto';
import type { InteractionItem } from 'chart.js';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js';
import { useQueryParams } from '../../utils/useQueryParams';
import React, { useState, useRef, MouseEvent } from 'react';
import { useQuery } from 'react-query';
import { getUserDetailInfo } from '../../controllers/services/Api';
import {
  DailyManageData,
  getAvarageSteps,
  getUserData,
  MonthlyManageData,
  AnnuallyManageData,
  WeeklyManageData,
  getTotalCompanyMember,
  UserCount,
  DailyAvgStep,
  WeeklyAvgStep,
  MonthlyAvgStep,
  AnnuallyAvgStep,
} from '../../controllers/services/useCompany';
import MenuBarsTemplate from '../templates/MenuBarsTemplate';
import LoadingBubbleOrganisms from './LoadingBubbleOrganisms';
import number_format from './ChartFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { useHistory } from 'react-router-dom';
import { ChartGraph, GraphData } from '../../utils/interfaces/Interface';
import { Report } from '../../utils/interfaces/Report';
import * as _ from 'lodash';
import { Button, MenuItem, Select } from '@material-ui/core';
import { SpaceBar } from '@material-ui/icons';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

// userdetail steps graph
export const StepGraph = () => {
  const queryParameters = useQueryParams();
  const uid = queryParameters.get('uid');
  let value: string;
  const { data } = useQuery(['userDetails', uid], () =>
    getUserDetailInfo(Number(uid))
  );

  if (!data) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  const stepDays = data.records
    .filter((row) => row.type == 'steps')
    .slice(-7)
    .map((elm) => {
      return elm['yeardate'];
    });

  console.log(' ===== stepDays ====', stepDays);

  const stepSum = data.records
    .filter((row) => row.type == 'steps')
    .slice(-7)
    .map((elm) => {
      return elm['value'];
    });

  console.log(' ===== stepSum ====', stepSum);

  const graphData = {
    labels: stepDays,
    datasets: [
      {
        data: stepSum,
        fill: true,
        label: 'steps',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#6ddccf',
        pointRadius: 3,
        pointBackgroundColor: '#94ebcd',
        pointBorderColor: '#6ddccf',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#6ddccf',
        pointHoverBorderColor: '#6ddccf',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: '歩数推移',
    },
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 25,
        bottom: 0,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          value = data.datasets[0].data[tooltipItem.index];
          if (parseInt(value) >= 1000) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          } else {
            return value;
          }
        },
      },
    },
    scales: {
      x: {
        time: {
          unit: 'date',
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 7,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 45,
          callback: function (value: any, index: any, values: any) {
            return number_format(value) + '歩';
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: 'rgb(255,255,255)',
          bodyFontColor: '#858796',
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
        },
        gridLines: {
          color: 'rgb(234, 236, 244)',
          zeroLineColor: 'rgb(234, 236, 244)',
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2],
        },
      },
    },
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          textAlign: 'right',
        }}>
        <SpaceBar height={36}></SpaceBar>
      </div>
      <Line data={graphData} width={100} height={50} options={options} />
    </>
  );
};

// userdetail distance graph
export const DistanceGraph = () => {
  const queryParameters = useQueryParams();
  const uid = queryParameters.get('uid');
  let value: string;

  const { data } = useQuery(['userDetails', uid], () =>
    getUserDetailInfo(Number(uid))
  );

  if (!data) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  const disDays = data.records
    .filter((row) => row.type == 'distance')
    .slice(-7)
    .map((elm) => {
      return elm['yeardate'];
    });

  const disSum = data.records
    .filter((row) => row.type == 'distance')
    .slice(-7)
    .map((elm) => {
      return elm['value'];
    });

  const graphData = {
    labels: disDays,
    datasets: [
      {
        data: disSum,
        label: 'distance',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#00af91',
        pointRadius: 3,
        pointBackgroundColor: '#a2d0c1',
        pointBorderColor: '#00af91',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#00af91',
        pointHoverBorderColor: '#00af91',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: '歩行距離推移',
    },
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 100,
        bottom: 0,
      },
    },
    scales: {
      x: {
        time: {
          unit: 'date',
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 7,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 10,
          callback: function (value: any, index: any, values: any) {
            return number_format(value) + 'm';
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: 'rgb(255,255,255)',
          bodyFontColor: '#858796',
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
          callbacks: {
            label: function (
              tooltipItem: { datasetIndex: string | number; yLabel: any },
              chart: { datasets: { [x: string]: { label: string } } }
            ) {
              const datasetLabel =
                chart.datasets[tooltipItem.datasetIndex].label || '';
              return (
                datasetLabel + ' ' + number_format(tooltipItem.yLabel) + 'm'
              );
            },
          },
        },
        gridLines: {
          color: 'rgb(234, 236, 244)',
          zeroLineColor: 'rgb(234, 236, 244)',
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2],
        },
      },
    },
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          textAlign: 'right',
        }}>
        <SpaceBar height={36}></SpaceBar>
      </div>
      <Line data={graphData} width={100} height={50} options={options} />
    </>
  );
};

//============================================================================================

// dashboard steps graph
export const DashBoardStepDailyGraph = ({ onClose }: ActiveUserGraphProps) => {
  const [companyid, setCompanyId] = useState();
  let value: string;

  const { data } = useQuery(
    ['dailyAvarage', 'daily'],
    () => getAvarageSteps('daily'),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  if (!data) {
    return <LoadingBubbleOrganisms />;
  }
  const dailyData = data as DailyAvgStep[];

  const graphData = {
    labels: dailyData.map((elm) => elm.date),
    datasets: [
      {
        data: dailyData.map((elm) => elm.record),
        fill: true,
        label: '歩数',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#6ddccf',
        pointRadius: 3,
        pointBackgroundColor: '#94ebcd',
        pointBorderColor: '#6ddccf',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#6ddccf',
        pointHoverBorderColor: '#6ddccf',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: '平均歩数推移',
    },
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 15,
        bottom: 30,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          value = data.datasets[0].data[tooltipItem.index];
          if (parseInt(value) >= 1000) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          } else {
            return value;
          }
        },
      },
    },
    scales: {
      x: {
        time: {
          unit: 'date',
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 7,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 10,
          callback: function (value: any, index: any, values: any) {
            return number_format(value) + '歩';
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: 'rgb(255,255,255)',
          bodyFontColor: '#858796',
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 10,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
        },
        gridLines: {
          color: 'rgb(234, 236, 244)',
          zeroLineColor: 'rgb(234, 236, 244)',
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2],
        },
      },
    },
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          textAlign: 'right',
        }}>
        <Select
          labelId="graph-select-label"
          id="graph-select-outlined"
          value={'daily'}
          onChange={(e) => {
            onClose(e.target.value);
          }}
          label="Period">
          <MenuItem value={'daily'}>日別表示する</MenuItem>
          <MenuItem value={'weekly'}>週別表示する</MenuItem>
          <MenuItem value={'monthly'}>月別表示する</MenuItem>
          <MenuItem value={'annually'}>年別表示する</MenuItem>
        </Select>
      </div>
      <Line data={graphData} width={100} height={50} options={options} />
    </>
  );
};

export const DashBoardStepWeeklyGraph = ({ onClose }: ActiveUserGraphProps) => {
  const [companyid, setCompanyId] = useState();
  let value: string;

  const { data } = useQuery(
    ['weeklyAvarage', 'weekly'],
    () => getAvarageSteps('weekly'),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  if (!data) {
    return <LoadingBubbleOrganisms />;
  }
  const weeklyData = data as WeeklyAvgStep[];

  const graphData = {
    labels: weeklyData.map((elm) => elm.week),
    datasets: [
      {
        data: weeklyData.map((elm) => elm.record),
        fill: true,
        label: '歩数',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#6ddccf',
        pointRadius: 3,
        pointBackgroundColor: '#94ebcd',
        pointBorderColor: '#6ddccf',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#6ddccf',
        pointHoverBorderColor: '#6ddccf',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: '平均歩数推移',
    },
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 15,
        bottom: 30,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          value = data.datasets[0].data[tooltipItem.index];
          if (parseInt(value) >= 1000) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          } else {
            return value;
          }
        },
      },
    },
    scales: {
      x: {
        time: {
          unit: 'date',
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 7,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 10,
          callback: function (value: any, index: any, values: any) {
            return number_format(value) + '歩';
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: 'rgb(255,255,255)',
          bodyFontColor: '#858796',
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 10,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
        },
        gridLines: {
          color: 'rgb(234, 236, 244)',
          zeroLineColor: 'rgb(234, 236, 244)',
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2],
        },
      },
    },
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          textAlign: 'right',
        }}>
        <Select
          labelId="graph-select-label"
          id="graph-select-outlined"
          value={'weekly'}
          onChange={(e) => {
            onClose(e.target.value);
          }}
          label="Period">
          <MenuItem value={'daily'}>日別表示する</MenuItem>
          <MenuItem value={'weekly'}>週別表示する</MenuItem>
          <MenuItem value={'monthly'}>月別表示する</MenuItem>
          <MenuItem value={'annually'}>年別表示する</MenuItem>
        </Select>
      </div>
      <Line data={graphData} width={100} height={50} options={options} />
    </>
  );
};

export const DashBoardStepMonthlyGraph = ({
  onClose,
}: ActiveUserGraphProps) => {
  const [companyid, setCompanyId] = useState();
  let value: string;

  const { data } = useQuery(
    ['monthlyAvarage', 'monthly'],
    () => getAvarageSteps('monthly'),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  if (!data) {
    return <LoadingBubbleOrganisms />;
  }
  const monthlyData = data as MonthlyAvgStep[];

  const graphData = {
    labels: monthlyData.map((elm) => elm.month),
    datasets: [
      {
        data: monthlyData.map((elm) => elm.record),
        fill: true,
        label: '歩数',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#6ddccf',
        pointRadius: 3,
        pointBackgroundColor: '#94ebcd',
        pointBorderColor: '#6ddccf',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#6ddccf',
        pointHoverBorderColor: '#6ddccf',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: '平均歩数推移',
    },
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 15,
        bottom: 30,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          value = data.datasets[0].data[tooltipItem.index];
          if (parseInt(value) >= 1000) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          } else {
            return value;
          }
        },
      },
    },
    scales: {
      x: {
        time: {
          unit: 'date',
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 7,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 10,
          callback: function (value: any, index: any, values: any) {
            return number_format(value) + '歩';
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: 'rgb(255,255,255)',
          bodyFontColor: '#858796',
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 10,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
        },
        gridLines: {
          color: 'rgb(234, 236, 244)',
          zeroLineColor: 'rgb(234, 236, 244)',
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2],
        },
      },
    },
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          textAlign: 'right',
        }}>
        {/* <Select
      labelId="graph-select-label"
      id="graph-select-outlined"
      value={'monthly'}
      onChange={(e) => {onClose(e.target.value)}}
      label="Period">
        <MenuItem value={'daily'}>日別表示する</MenuItem>
        <MenuItem value={'weekly'}>週別表示する</MenuItem>
        <MenuItem value={'monthly'}>月別表示する</MenuItem>
        <MenuItem value={'annually'}>年別表示する</MenuItem>
    </Select> */}
      </div>
      <Line data={graphData} width={100} height={50} options={options} />
    </>
  );
};

export const DashBoardStepAnnuallyGraph = ({
  onClose,
}: ActiveUserGraphProps) => {
  const [companyid, setCompanyId] = useState();
  let value: string;

  const { data } = useQuery(
    ['annuallyAvarage', 'annually'],
    () => getAvarageSteps('annually'),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  if (!data) {
    return <LoadingBubbleOrganisms />;
  }
  const annuallyData = data as AnnuallyAvgStep[];

  const graphData = {
    labels: annuallyData.map((elm) => elm.year),
    datasets: [
      {
        data: annuallyData.map((elm) => elm.record),
        fill: true,
        label: '歩数',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#6ddccf',
        pointRadius: 3,
        pointBackgroundColor: '#94ebcd',
        pointBorderColor: '#6ddccf',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#6ddccf',
        pointHoverBorderColor: '#6ddccf',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: '平均歩数推移',
    },
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 15,
        bottom: 30,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          value = data.datasets[0].data[tooltipItem.index];
          if (parseInt(value) >= 1000) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          } else {
            return value;
          }
        },
      },
    },
    scales: {
      x: {
        time: {
          unit: 'date',
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 7,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 10,
          callback: function (value: any, index: any, values: any) {
            return number_format(value) + '歩';
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: 'rgb(255,255,255)',
          bodyFontColor: '#858796',
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 10,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
        },
        gridLines: {
          color: 'rgb(234, 236, 244)',
          zeroLineColor: 'rgb(234, 236, 244)',
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2],
        },
      },
    },
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          textAlign: 'right',
        }}>
        <Select
          labelId="graph-select-label"
          id="graph-select-outlined"
          value={'annually'}
          onChange={(e) => {
            onClose(e.target.value);
          }}
          label="Period">
          <MenuItem value={'daily'}>日別表示する</MenuItem>
          <MenuItem value={'weekly'}>週別表示する</MenuItem>
          <MenuItem value={'monthly'}>月別表示する</MenuItem>
          <MenuItem value={'annually'}>年別表示する</MenuItem>
        </Select>
      </div>
      <Line data={graphData} width={100} height={50} options={options} />
    </>
  );
};

type ActiveUserGraphProps = {
  onClose: (props: any) => void;
};

// dashboard monthly login_management graph
export const DashboardLoginManagementMonthlyGraph = ({
  onClose,
}: ActiveUserGraphProps) => {
  const history = useHistory();
  const chartRef = useRef<ChartJS>(null);
  const { data: login_data } = useQuery(['loginData', 'monthly'], {
    enabled: false,
  });

  const { data: perficient_data } = useQuery(['perficientData', 'monthly'], {
    enabled: false,
  });

  const { data: member_data } = useQuery(['memberData', 'monthly'], {
    enabled: false,
  });
  if (!login_data || !perficient_data || !member_data) {
    return <LoadingBubbleOrganisms />;
  }
  const monthlyData = login_data as MonthlyManageData[];
  const perficientData = perficient_data as MonthlyManageData[];
  const memberData = member_data as UserCount[];

  const loginPerData = [
    { per: (monthlyData[0].count / memberData[0].user_count) * 100 },
    { per: (monthlyData[1].count / memberData[1].user_count) * 100 },
    { per: (monthlyData[2].count / memberData[2].user_count) * 100 },
    { per: (monthlyData[3].count / memberData[3].user_count) * 100 },
    { per: (monthlyData[4].count / memberData[4].user_count) * 100 },
    { per: (monthlyData[5].count / memberData[5].user_count) * 100 },
    { per: (monthlyData[6].count / memberData[6].user_count) * 100 },
    { per: (monthlyData[7].count / memberData[7].user_count) * 100 },
    { per: (monthlyData[8].count / memberData[8].user_count) * 100 },
    { per: (monthlyData[9].count / memberData[9].user_count) * 100 },
    { per: (monthlyData[10].count / memberData[10].user_count) * 100 },
    { per: (monthlyData[11].count / memberData[11].user_count) * 100 },
  ];

  const perficientPerData = [
    { per: (perficientData[0].count / memberData[0].user_count) * 100 },
    { per: (perficientData[1].count / memberData[1].user_count) * 100 },
    { per: (perficientData[2].count / memberData[2].user_count) * 100 },
    { per: (perficientData[3].count / memberData[3].user_count) * 100 },
    { per: (perficientData[4].count / memberData[4].user_count) * 100 },
    { per: (perficientData[5].count / memberData[5].user_count) * 100 },
    { per: (perficientData[6].count / memberData[6].user_count) * 100 },
    { per: (perficientData[7].count / memberData[7].user_count) * 100 },
    { per: (perficientData[8].count / memberData[8].user_count) * 100 },
    { per: (perficientData[9].count / memberData[9].user_count) * 100 },
    { per: (perficientData[10].count / memberData[10].user_count) * 100 },
    { per: (perficientData[11].count / memberData[11].user_count) * 100 },
  ];

  // const handleClick = (idx: number) => {
  //   const date = dayjs(monthlyData[idx].month)
  //     .startOf('month')
  //     .format('YYYY-MM-DD');
  //   history.push(`/login_management/${date}?type=monthly`);
  // };
  const ElementsAtEvent = (element: InteractionItem[]) => {
    if (!element.length) return;
    const date = dayjs(monthlyData[element[0]['index']].month)
      .startOf('month')
      .format('YYYY-MM');

    switch (element[0]['datasetIndex']) {
      case 0:
        history.push(`/login_management/${date}?type=monthly&data=login`);
        break;
      case 1:
        history.push(`/login_management/${date}?type=monthly&data=perficient`);
        break;
      // case 2:
      //   history.push(`/login_management/${date}?type=monthly`);
      //   break;
      default:
        break;
    }
  };

  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;
    if (!chart) {
      return;
    }
    ElementsAtEvent(getElementAtEvent(chart, event));
  };

  const graphData = {
    labels: monthlyData.map((elm) => elm.month),
    datasets: [
      {
        data: loginPerData.map((elm) => parseFloat(elm.per.toFixed(1))),
        fill: true,
        label: 'アプリ利用率',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#00af91',
        pointRadius: 3,
        pointBackgroundColor: '#a2d0c1',
        pointBorderColor: '#00af91',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#00af91',
        pointHoverBorderColor: '#00af91',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
      {
        data: perficientPerData.map((elm) => parseFloat(elm.per.toFixed(1))),
        fill: true,
        label: '目標歩数達成率',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#faa755',
        pointRadius: 3,
        pointBackgroundColor: '#fcd1a6',
        pointBorderColor: '#faa755',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#faa755',
        pointHoverBorderColor: '#faa755',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
      // {
      //   data: memberData.map((elm) => elm.user_count),
      //   fill: true,
      //   label: 'ユーザー登録者数',
      //   lineTension: 0.3,
      //   backgroundColor: 'rgba(78, 115, 223, 0.05)',
      //   borderColor: '#0558aa',
      //   pointRadius: 3,
      //   pointBackgroundColor: '#a2d0c1',
      //   pointBorderColor: '#0558aa',
      //   pointHoverRadius: 3,
      //   pointHoverBackgroundColor: '#0558aa',
      //   pointHoverBorderColor: '#0558aa',
      //   pointHitRadius: 10,
      //   pointBorderWidth: 2,
      // },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      //text: 'アクティブユーザー推移(monthly)',
    },
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 0,
        bottom: 30,
      },
    },
    scales: {
      x: {
        time: {
          unit: 'date',
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 7,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 10,
          callback: function (value: any, index: any, values: any) {
            return Number(value) < 1 && Number(value) != 0 ? '' : value + '％';
          },
        },
        legend: {
          display: false,
        },
        events: ['click'],
        tooltips: {
          backgroundColor: 'rgb(255,255,255)',
          bodyFontColor: '#858796',
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 10,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
          callbacks: {
            label: function (
              tooltipItem: { datasetIndex: string | number; yLabel: any },
              chart: { datasets: { [x: string]: { label: string } } }
            ) {
              const datasetLabel =
                chart.datasets[tooltipItem.datasetIndex].label || '';
              return (
                datasetLabel + ' ' + number_format(tooltipItem.yLabel) + 'm'
              );
            },
          },
        },
        gridLines: {
          color: 'rgb(234, 236, 244)',
          zeroLineColor: 'rgb(234, 236, 244)',
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2],
        },
      },
    },
  };
  return (
    <>
      <div
        style={{
          width: '100%',
          textAlign: 'right',
        }}>
        <Select
          labelId="graph-select-label"
          id="graph-select-outlined"
          value={'monthly'}
          onChange={(e) => {
            onClose(e.target.value);
          }}
          label="Period">
          <MenuItem value={'daily'}>日別表示する</MenuItem>
          <MenuItem value={'monthly'}>月別表示する</MenuItem>
        </Select>
      </div>
      <Chart
        ref={chartRef}
        type="line"
        data={graphData}
        width={100}
        height={50}
        options={options}
        onClick={onClick}
      />
    </>
  );
};

// dashboard yearly login_management graph
export const DashboardLoginManagementAnnuallyGraph = ({
  onClose,
}: ActiveUserGraphProps) => {
  const [companyid, setCompanyId] = useState();
  const history = useHistory();
  const chartRef = useRef<ChartJS>(null);

  const { data: login_data } = useQuery(
    ['loginData', 'annually'],
    () => getUserData('annually', 'login'),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  const { data: perficient_data } = useQuery(
    ['perficientData', 'annually'],
    () => getUserData('annually', 'perficient'),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  const { data: member_data } = useQuery(
    ['memberData', 'annually'],
    () => getTotalCompanyMember('annually'),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  if (!login_data || !perficient_data || !member_data) {
    return <LoadingBubbleOrganisms />;
  }
  const annuallyData = login_data as AnnuallyManageData[];
  const perficientData = perficient_data as AnnuallyManageData[];
  const memberData = member_data as UserCount[];

  // const handleClick = (idx: number) => {
  //   const date = dayjs(annuallyData[idx].year)
  //     .startOf('year')
  //     .format('YYYY');
  //   history.push(`/login_management/${date}?type=annually`);
  // };

  const ElementsAtEvent = (element: InteractionItem[]) => {
    if (!element.length) return;
    const date = dayjs(annuallyData[element[0]['index']].year)
      .startOf('year')
      .format('YYYY');

    switch (element[0]['datasetIndex']) {
      case 0:
        history.push(`/login_management/${date}?type=annually&data=login`);
        break;
      case 1:
        history.push(`/login_management/${date}?type=annually&data=perficient`);
        break;
      case 2:
        history.push(`/login_management/${date}?type=annually`);
        break;
      default:
        break;
    }
  };

  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;
    if (!chart) {
      return;
    }
    ElementsAtEvent(getElementAtEvent(chart, event));
  };

  const graphData = {
    labels: annuallyData.map((elm) => elm.year + '年'),
    datasets: [
      {
        data: annuallyData.map((elm) => elm.count),
        fill: true,
        label: 'アプリ利用者',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#00af91',
        pointRadius: 3,
        pointBackgroundColor: '#a2d0c1',
        pointBorderColor: '#00af91',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#00af91',
        pointHoverBorderColor: '#00af91',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
      {
        data: perficientData.map((elm) => elm.count),
        fill: true,
        label: '目標歩数達成者',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#faa755',
        pointRadius: 3,
        pointBackgroundColor: '#a2d0c1',
        pointBorderColor: '#faa755',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#faa755',
        pointHoverBorderColor: '#faa755',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
      {
        data: memberData.map((elm) => elm.user_count),
        fill: true,
        label: 'ユーザー登録者数',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#0558aa',
        pointRadius: 3,
        pointBackgroundColor: '#a2d0c1',
        pointBorderColor: '#0558aa',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#0558aa',
        pointHoverBorderColor: '#0558aa',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      //text: 'アクティブユーザー推移(yearly)',
    },
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 0,
        bottom: 30,
      },
    },
    scales: {
      x: {
        time: {
          unit: 'year',
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 7,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 10,
          callback: function (value: any, index: any, values: any) {
            return Number(value) < 1 && Number(value) != 0 ? '' : value + '人';
          },
        },
        legend: {
          display: false,
        },
        events: ['click'],
        tooltips: {
          backgroundColor: 'rgb(255,255,255)',
          bodyFontColor: '#858796',
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 10,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
          callbacks: {
            label: function (
              tooltipItem: { datasetIndex: string | number; yLabel: any },
              chart: { datasets: { [x: string]: { label: string } } }
            ) {
              const datasetLabel =
                chart.datasets[tooltipItem.datasetIndex].label || '';
              return (
                datasetLabel + ' ' + number_format(tooltipItem.yLabel) + 'm'
              );
            },
          },
        },
        gridLines: {
          color: 'rgb(234, 236, 244)',
          zeroLineColor: 'rgb(234, 236, 244)',
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2],
        },
      },
    },
  };
  return (
    <>
      <div
        style={{
          width: '100%',
          textAlign: 'right',
        }}>
        <Select
          labelId="graph-select-label"
          id="graph-select-outlined"
          value={'annually'}
          onChange={(e) => {
            onClose(e.target.value);
          }}
          label="Period">
          <MenuItem value={'daily'}>日別表示する</MenuItem>
          <MenuItem value={'weekly'}>週別表示する</MenuItem>
          <MenuItem value={'monthly'}>月別表示する</MenuItem>
          <MenuItem value={'annually'}>年別表示する</MenuItem>
        </Select>
      </div>
      <Chart
        ref={chartRef}
        type="line"
        data={graphData}
        width={100}
        height={50}
        options={options}
        onClick={onClick}
      />
    </>
  );
};

// dashboard Weekly login_management graph
export const DashboardLoginManagementWeeklyGraph = ({
  onClose,
}: ActiveUserGraphProps) => {
  const [companyid, setCompanyId] = useState();
  const history = useHistory();
  const chartRef = useRef<ChartJS>(null);

  const { data: login_data } = useQuery(
    ['loginData', 'weekly'],
    () => getUserData('weekly', 'login'),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  const { data: perficient_data } = useQuery(
    ['perficientData', 'weekly'],
    () => getUserData('weekly', 'perficient'),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  const { data: member_data } = useQuery(
    ['memberData', 'weekly'],
    () => getTotalCompanyMember('weekly'),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  if (!login_data || !perficient_data || !member_data) {
    return <LoadingBubbleOrganisms />;
  }
  const weeklyData = login_data as WeeklyManageData[];
  const perficientData = perficient_data as WeeklyManageData[];
  const memberData = member_data as UserCount[];

  // const handleClick = (idx: number) => {
  //   const date = dayjs(weeklyData[idx].week)
  //     .startOf('week')
  //     .format('YYYY');
  //   history.push(`/login_management/${date}?type=weekly`);
  // };
  const ElementsAtEvent = (element: InteractionItem[]) => {
    if (!element.length) return;
    const date = dayjs(weeklyData[element[0]['index']].weekdate)
      .startOf('day')
      .format('YYYY-MM-DD');

    switch (element[0]['datasetIndex']) {
      case 0:
        history.push(`/login_management/${date}?type=weekly&data=login`);
        break;
      case 1:
        history.push(`/login_management/${date}?type=weekly&data=perficient`);
        break;
      case 2:
        history.push(`/login_management/${date}?type=weekly`);
        break;
      default:
        break;
    }
  };

  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;
    if (!chart) {
      return;
    }
    ElementsAtEvent(getElementAtEvent(chart, event));
  };

  const graphData = {
    labels: weeklyData.map((elm) => elm.weekdate),
    datasets: [
      {
        data: weeklyData.map((elm) => elm.count),
        fill: true,
        label: 'アプリ利用者',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#00af91',
        pointRadius: 3,
        pointBackgroundColor: '#a2d0c1',
        pointBorderColor: '#00af91',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#00af91',
        pointHoverBorderColor: '#00af91',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
      {
        data: perficientData.map((elm) => elm.count),
        fill: true,
        label: '目標歩数達成者',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#faa755',
        pointRadius: 3,
        pointBackgroundColor: '#a2d0c1',
        pointBorderColor: '#faa755',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#faa755',
        pointHoverBorderColor: '#faa755',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
      {
        data: memberData.map((elm) => elm.user_count),
        fill: true,
        label: 'ユーザー登録者数',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#0558aa',
        pointRadius: 3,
        pointBackgroundColor: '#a2d0c1',
        pointBorderColor: '#0558aa',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#0558aa',
        pointHoverBorderColor: '#0558aa',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      //text: 'アクティブユーザー推移 (weekly)',
    },
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 0,
        bottom: 30,
      },
    },
    scales: {
      x: {
        time: {
          unit: 'date',
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 7,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 10,
          callback: function (value: any, index: any, values: any) {
            return Number(value) < 1 && Number(value) != 0 ? '' : value + '人';
          },
        },
        legend: {
          display: false,
        },
        events: ['click'],
        tooltips: {
          backgroundColor: 'rgb(255,255,255)',
          bodyFontColor: '#858796',
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
          callbacks: {
            label: function (
              tooltipItem: { datasetIndex: string | number; yLabel: any },
              chart: { datasets: { [x: string]: { label: string } } }
            ) {
              const datasetLabel =
                chart.datasets[tooltipItem.datasetIndex].label || '';
              return (
                datasetLabel + ' ' + number_format(tooltipItem.yLabel) + 'm'
              );
            },
          },
        },
        gridLines: {
          color: 'rgb(234, 236, 244)',
          zeroLineColor: 'rgb(234, 236, 244)',
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2],
        },
      },
    },
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          textAlign: 'right',
        }}>
        <Select
          labelId="graph-select-label"
          id="graph-select-outlined"
          value={'weekly'}
          onChange={(e) => {
            onClose(e.target.value);
          }}
          label="Period">
          <MenuItem value={'daily'}>日別表示する</MenuItem>
          <MenuItem value={'weekly'}>週別表示する</MenuItem>
          <MenuItem value={'monthly'}>月別表示する</MenuItem>
          <MenuItem value={'annually'}>年別表示する</MenuItem>
        </Select>
      </div>
      <Chart
        ref={chartRef}
        type="line"
        data={graphData}
        width={100}
        height={50}
        options={options}
        onClick={onClick}
      />
    </>
  );
};

// dashboard Daily login_management graph
export const DashboardLoginManagementDailyGraph = ({
  onClose,
}: ActiveUserGraphProps) => {
  const history = useHistory();
  const chartRef = useRef<ChartJS>(null);

  const { data: login_data } = useQuery(['loginData', 'daily'], {
    enabled: false,
  });

  const { data: perficient_data } = useQuery(['perficientData', 'daily'], {
    enabled: false,
  });

  const { data: member_data } = useQuery(['memberData', 'daily'], {
    enabled: false,
  });

  if (!login_data || !perficient_data || !member_data) {
    return <LoadingBubbleOrganisms />;
  }

  const dailyData = login_data as DailyManageData[];
  const perficientData = perficient_data as DailyManageData[];
  const memberData = member_data as UserCount[];

  const loginPerData = [
    { per: (dailyData[0].count / memberData[0].user_count) * 100 },
    { per: (dailyData[1].count / memberData[1].user_count) * 100 },
    { per: (dailyData[2].count / memberData[2].user_count) * 100 },
    { per: (dailyData[3].count / memberData[3].user_count) * 100 },
    { per: (dailyData[4].count / memberData[4].user_count) * 100 },
    { per: (dailyData[5].count / memberData[5].user_count) * 100 },
    { per: (dailyData[6].count / memberData[6].user_count) * 100 },
  ];

  const perficientPerData = [
    { per: (perficientData[0].count / memberData[0].user_count) * 100 },
    { per: (perficientData[1].count / memberData[1].user_count) * 100 },
    { per: (perficientData[2].count / memberData[2].user_count) * 100 },
    { per: (perficientData[3].count / memberData[3].user_count) * 100 },
    { per: (perficientData[4].count / memberData[4].user_count) * 100 },
    { per: (perficientData[5].count / memberData[5].user_count) * 100 },
    { per: (perficientData[6].count / memberData[6].user_count) * 100 },
  ];
  // const handleNavigateToListPage = (idx: number) => {
  //   history.push(`/dashboard/login/${dailyData[idx].date}?type=daily`);
  // };
  const ElementsAtEvent = (element: InteractionItem[]) => {
    if (!element.length) return;
    const date = dayjs(dailyData[element[0]['index']].date)
      .startOf('day')
      .format('YYYY-MM-DD');

    switch (element[0]['datasetIndex']) {
      case 0:
        history.push(`/login_management/${date}?type=daily&data=login`);
        break;
      case 1:
        history.push(`/login_management/${date}?type=daily&data=perficient`);
        break;
      // case 2:
      //   history.push(`/login_management/${date}?type=daily`);
      //   break;
      default:
        break;
    }
  };

  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;
    if (!chart) {
      return;
    }
    ElementsAtEvent(getElementAtEvent(chart, event));
  };

  const graphData = {
    labels: dailyData.map((elm) => elm.date),
    datasets: [
      {
        data: loginPerData.map((elm) => parseFloat(elm.per.toFixed(1))),
        fill: true,
        label: 'アプリ利用率',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#00af91',
        pointRadius: 3,
        pointBackgroundColor: '#a2d0c1',
        pointBorderColor: '#00af91',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#00af91',
        pointHoverBorderColor: '#00af91',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
      {
        data: perficientPerData.map((elm) => parseFloat(elm.per.toFixed(1))),
        fill: true,
        label: '目標歩数達成率',
        lineTension: 0.3,
        backgroundColor: 'rgba(78, 115, 223, 0.05)',
        borderColor: '#faa755',
        pointRadius: 3,
        pointBackgroundColor: '#fcd1a6',
        pointBorderColor: '#faa755',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#faa755',
        pointHoverBorderColor: '#faa755',
        pointHitRadius: 10,
        pointBorderWidth: 2,
      },
      // {
      //   data: memberData.map((elm) => elm.user_count),
      //   fill: true,
      //   label: 'ユーザー登録者数',
      //   lineTension: 0.3,
      //   backgroundColor: 'rgba(78, 115, 223, 0.05)',
      //   borderColor: '#0558aa',
      //   pointRadius: 3,
      //   pointBackgroundColor: '#a2d0c1',
      //   pointBorderColor: '#0558aa',
      //   pointHoverRadius: 3,
      //   pointHoverBackgroundColor: '#0558aa',
      //   pointHoverBorderColor: '#0558aa',
      //   pointHitRadius: 10,
      //   pointBorderWidth: 2,
      // },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      //text: 'アクティブユーザー推移 (daily)',
    },
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 0,
        bottom: 30,
      },
    },
    scales: {
      x: {
        time: {
          unit: 'date',
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 7,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 10,
          callback: function (value: any, index: any, values: any) {
            return Number(value) < 1 && Number(value) != 0 ? '' : value + '％';
          },
        },
        legend: {
          display: false,
        },
        events: ['click'],
        tooltips: {
          backgroundColor: 'rgb(255,255,255)',
          bodyFontColor: '#858796',
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
          callbacks: {
            label: function (
              tooltipItem: { datasetIndex: string | number; yLabel: any },
              chart: { datasets: { [x: string]: { label: string } } }
            ) {
              const datasetLabel =
                chart.datasets[tooltipItem.datasetIndex].label || '';
              return (
                datasetLabel + ' ' + number_format(tooltipItem.yLabel) + 'm'
              );
            },
          },
        },
        gridLines: {
          color: 'rgb(234, 236, 244)',
          zeroLineColor: 'rgb(234, 236, 244)',
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2],
        },
      },
    },
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          textAlign: 'right',
        }}>
        <Select
          labelId="graph-select-label"
          id="graph-select-outlined"
          value={'daily'}
          onChange={(e) => {
            onClose(e.target.value);
          }}
          label="Period">
          <MenuItem value={'daily'}>日別表示する</MenuItem>
          <MenuItem value={'monthly'}>月別表示する</MenuItem>
        </Select>
      </div>
      <Chart
        ref={chartRef}
        type="line"
        data={graphData}
        width={100}
        height={50}
        options={options}
        onClick={onClick}
      />
    </>
  );
};

// health report total man graph
export const TotalStatistics = ({
  record,
  sex,
}: {
  record: Report[];
  sex: string;
}) => {
  let caption = '';
  if (sex == 'M') {
    caption = '総合評価(男性)';
  } else if (sex == 'F') {
    caption = '総合評価(女性)';
  }
  if (!record) {
    return <LoadingBubbleOrganisms />;
  }

  let countOfA = 0;
  let countOfB = 0;
  let countOfC = 0;
  let countOfD = 0;

  record.map((elm) => {
    if (elm.total_evaluation === 'A') {
      countOfA++;
    } else if (elm.total_evaluation === 'B') {
      countOfB++;
    } else if (elm.total_evaluation === 'C') {
      countOfC++;
    } else if (elm.total_evaluation === 'D') {
      countOfD++;
    }
  });

  const graphData = {
    labels: ['A評価', 'B評価', 'C評価', 'D評価'],
    datasets: [
      {
        backgroundColor: ['#A8FCC4', '#09E85E', '#00BE41', '#004F24'],
        data: [countOfA, countOfB, countOfC, countOfD],
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: caption,
        fontSize: 18,
      },
    },
    legend: {
      labels: {
        fontColor: 'grey',
        fontSize: 15,
      },
    },
  };

  return (
    <div style={{ marginTop: 40 }}>
      <Doughnut data={graphData} width={11} height={6} options={options} />
    </div>
  );
};

// statics report graph
export const CategoryStatistics = ({
  record,
  sex,
}: {
  record: Report[];
  sex: string;
}) => {
  let caption = '';
  if (sex == 'M') {
    caption = '項目別評価(男性)';
  } else if (sex == 'F') {
    caption = '項目別評価(女性)';
  }
  if (!record) {
    return <LoadingBubbleOrganisms />;
  }

  const statistics = {
    value: {} as {
      [prop: string]: any;
    },
    addEvaluattion: function (category: string, reports: Report[]) {
      this.value[`${category}`] = {};
      this.value[`${category}`].A = 0;
      this.value[`${category}`].B = 0;
      this.value[`${category}`].C = 0;
      this.value[`${category}`].D = 0;

      reports.map((elm: Report) => {
        if (elm[`${category}`] === 'A') {
          this.value[`${category}`].A++;
        } else if (elm[`${category}`] === 'B') {
          this.value[`${category}`].B++;
        } else if (elm[`${category}`] === 'C') {
          this.value[`${category}`].C++;
        } else if (elm[`${category}`] === 'D') {
          this.value[`${category}`].D++;
        }
      });
      return this;
    },
  };
  statistics
    .addEvaluattion('ALT_evaluation', record)
    .addEvaluattion('AST_evaluation', record)
    .addEvaluattion('BMI_evaluation', record)
    .addEvaluattion('GTP_evaluation', record)
    .addEvaluattion('HDL_evaluation', record)
    .addEvaluattion('HbA1c_evaluation', record)
    .addEvaluattion('LDL_evaluation', record)
    .addEvaluattion('bloodlow_evaluation', record)
    .addEvaluattion('bloodup_evaluation', record)
    .addEvaluattion('glucose_evaluation', record)
    .addEvaluattion('hmg_evaluation', record)
    .addEvaluattion('TG_evaluation', record)
    .addEvaluattion('UA_evaluation', record);

  const graphData = {
    labels: [
      'ALT',
      'AST',
      'BMI',
      'γ-GTP',
      'HDL',
      'HbA1c',
      'LDL',
      '空腹時血糖',
      '収縮期血圧',
      '拡張期血圧',
      '中性脂肪',
      '尿酸値',
    ],
    datasets: [
      {
        label: 'A評価',
        borderWidth: 1,
        backgroundColor: '#A8FCC4',
        borderColor: '#A8FCC4',
        data: [
          statistics.value['ALT_evaluation']['A'],
          statistics.value['AST_evaluation']['A'],
          statistics.value['BMI_evaluation']['A'],
          statistics.value['GTP_evaluation']['A'],
          statistics.value['HDL_evaluation']['A'],
          statistics.value['HbA1c_evaluation']['A'],
          statistics.value['LDL_evaluation']['A'],
          statistics.value['glucose_evaluation']['A'],
          statistics.value['bloodup_evaluation']['A'],
          statistics.value['bloodlow_evaluation']['A'],
          statistics.value['TG_evaluation']['A'],
          statistics.value['UA_evaluation']['A'],
        ],
      },

      {
        label: 'B評価',
        borderWidth: 1,
        backgroundColor: '#09E85E',
        borderColor: '#09E85E',
        data: [
          statistics.value['ALT_evaluation']['B'],
          statistics.value['AST_evaluation']['B'],
          statistics.value['BMI_evaluation']['B'],
          statistics.value['GTP_evaluation']['B'],
          statistics.value['HDL_evaluation']['B'],
          statistics.value['HbA1c_evaluation']['B'],
          statistics.value['LDL_evaluation']['B'],
          statistics.value['glucose_evaluation']['B'],
          statistics.value['bloodup_evaluation']['B'],
          statistics.value['bloodlow_evaluation']['B'],
          statistics.value['TG_evaluation']['B'],
          statistics.value['UA_evaluation']['B'],
        ],
      },
      {
        label: 'C評価',
        borderWidth: 1,
        backgroundColor: '#00BE41',
        borderColor: '#00BE41',
        data: [
          statistics.value['ALT_evaluation']['C'],
          statistics.value['AST_evaluation']['C'],
          statistics.value['BMI_evaluation']['C'],
          statistics.value['GTP_evaluation']['C'],
          statistics.value['HDL_evaluation']['C'],
          statistics.value['HbA1c_evaluation']['C'],
          statistics.value['LDL_evaluation']['C'],
          statistics.value['glucose_evaluation']['C'],
          statistics.value['bloodup_evaluation']['C'],
          statistics.value['bloodlow_evaluation']['C'],
          statistics.value['TG_evaluation']['C'],
          statistics.value['UA_evaluation']['C'],
        ],
      },
      {
        label: 'D評価',
        borderWidth: 1,
        backgroundColor: '#004F24',
        borderColor: '#004F24',
        data: [
          statistics.value['ALT_evaluation']['D'],
          statistics.value['AST_evaluation']['D'],
          statistics.value['BMI_evaluation']['D'],
          statistics.value['GTP_evaluation']['D'],
          statistics.value['HDL_evaluation']['D'],
          statistics.value['HbA1c_evaluation']['D'],
          statistics.value['LDL_evaluation']['D'],
          statistics.value['glucose_evaluation']['D'],
          statistics.value['bloodup_evaluation']['D'],
          statistics.value['bloodlow_evaluation']['D'],
          statistics.value['TG_evaluation']['D'],
          statistics.value['UA_evaluation']['D'],
        ],
      },
    ],
  };

  const options = {
    title: {
      display: true,
      text: caption,
      fontSize: 18,
    },
    scales: {
      x: {
        stacked: true, //積み上げ棒グラフにする設定
        categoryPercentage: 0.4, //棒グラフの太さ
      },
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 10,
        },
        stacked: true, //積み上げ棒グラフにする設定
      },
    },
    legend: {
      labels: {
        fontColor: 'grey',
        fontSize: 15,
      },
    },
    tooltips: {
      mode: 'label', //マウスオーバー時に表示されるtooltip
    },
  };

  return (
    <div style={{ marginTop: 40 }}>
      <Bar data={graphData} width={100} height={50} options={options} />
    </div>
  );
};
