import { commonHttpClient } from './commonHttpClient';
import { useQuery } from 'react-query';
import { CompanyBattleEvent } from '../../utils/interfaces/CompanyBattle';

export const useCompanyBattleEvents = () => {
  const { http } = commonHttpClient();

  const getCompanyBattleEvents = async (): Promise<CompanyBattleEvent[]> => {
    const { data } = await http.get('/companybattle_battleevents');
    return data as CompanyBattleEvent[];
  };

  const useReadCompanyBattleEvents = () => useQuery('CompanyBattleEvents', getCompanyBattleEvents);

  return {
    useCompanyBattleEvents,
    useReadCompanyBattleEvents,
  };
};
