import React, { createContext, useEffect, useState } from 'react';
import {
  createMuiTheme,
  makeStyles,
  createStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import MenuBarsTemplate from '../../templates/MenuBarsTemplate';
import LoadingBubbleOrganisms from '../../organisms/LoadingBubbleOrganisms';
import 'react-datepicker/dist/react-datepicker.css';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Line } from 'react-chartjs-2';
import { Company } from '../../../utils/interfaces/Company';
import {
  CompanyBattle,
  CompanyBattleEvent,
} from '../../../utils/interfaces/CompanyBattle';
import { ChartGraph, GraphData } from '../../../utils/interfaces/Interface';

import {
  AppBar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import dayjs from 'dayjs';

import { useCompanies } from '../../../controllers/services/useCompany';
import { useCompanyBattles } from '../../../controllers/services/useCompanyBattle';
import { useToast } from '../../molecules/toastMolecules';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import ModalMolecures from '../../molecules/ModalMolecules';
import { User } from '../../../utils/interfaces/User';
import { useUser } from '../../../controllers/services/useUser';

const CompanyCtx = createContext<Company[]>([]);
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function createChartDatas(
  start_date: dayjs.Dayjs,
  end_date: dayjs.Dayjs,
  companybattle_battleevents: CompanyBattleEvent
) {
  let refDate: dayjs.Dayjs = dayjs();
  if (refDate.isAfter(end_date)) {
    refDate = end_date;
  } else if (start_date.add(6, 'day').isAfter(refDate)) {
    refDate = start_date.add(6, 'day');
  }
  const yCoordRange = 7;
  const yAxisID: dayjs.Dayjs[] = [];
  const chartgraph: ChartGraph = {} as ChartGraph;
  const labels: string[] = [];
  const datasets: GraphData[] = [] as GraphData[];
  const graphtemplate = {
    fill: false,
    lineTension: 0.1,
    borderCapStyle: 'round',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'square',
    pointBackgroundColor: '#eee',
    pointBorderWidth: 10,
    pointHoverRadius: 5,
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 1,
    pointRadius: 1,
    pointHitRadius: 10,
  };
  for (let i = 1; i <= yCoordRange; i++) {
    yAxisID.push(refDate.subtract(yCoordRange - i - 1, 'day'));
    labels.push(refDate.subtract(yCoordRange - i, 'day').format('MM/DD'));
  }
  companybattle_battleevents.company?.map((company) => {
    const idx = datasets.push({
      label: company.name,
      data: [],
      backgroundColor: `rgba(\
        ${parseInt(company.team_color.substr(0, 2), 16)},\
        ${parseInt(company.team_color.substr(2, 2), 16)},\
        ${parseInt(company.team_color.substr(4, 2), 16)},\
        0.4)`,
      borderColor: `rgba(\
        ${parseInt(company.team_color.substr(0, 2), 16)},\
        ${parseInt(company.team_color.substr(2, 2), 16)},\
        ${parseInt(company.team_color.substr(4, 2), 16)},\
        1)`,
      pointBorderColor: `rgba(\
        ${parseInt(company.team_color.substr(0, 2), 16)},\
        ${parseInt(company.team_color.substr(2, 2), 16)},\
        ${parseInt(company.team_color.substr(4, 2), 16)},\
        1)`,
      pointHoverBackgroundColor: `rgba(\
        ${parseInt(company.team_color.substr(0, 2), 16)},\
        ${parseInt(company.team_color.substr(2, 2), 16)},\
        ${parseInt(company.team_color.substr(4, 2), 16)},\
        1)`,
      ...graphtemplate,
    });
    yAxisID.map((yAxis, yAxisIdx) => {
      datasets[idx - 1].data[yAxisIdx] = 0;
      let user_count = 0;
      company.companybattleSummary?.map((companybattleRecord) => {
        if (
          dayjs(companybattleRecord.batch_execute_time).format('YYYY-MM-DD') ===
            yAxis.format('YYYY-MM-DD') &&
          companybattleRecord.company_id === company.companyid &&
          companybattleRecord.companybattle_battleevents_id ===
            companybattle_battleevents.companybattle_battleevents_id
        ) {
          user_count++;
          datasets[idx - 1].data[yAxisIdx] += Number(
            companybattleRecord.summation_value
          );
        }
      });
      if (companybattle_battleevents.companybattle_battleevents_id == 1) {
        datasets[idx - 1].data[yAxisIdx] = Math.floor(
          datasets[idx - 1].data[yAxisIdx] / user_count
        );
      } else {
        datasets[idx - 1].data[yAxisIdx] = Math.floor(
          (datasets[idx - 1].data[yAxisIdx] * 100) / user_count
        );
      }
    });
  });
  chartgraph.labels = labels;
  chartgraph.datasets = datasets;
  return chartgraph;
}

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#8fc31f' }, // テーマの色
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    container: {
      maxHeight: 2000,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      height: 600,
      width: 500,
      padding: 20,
      outline: 'none',
      borderRadius: 10,
    },
    button: {
      height: 50,
      width: 200,
      marginBottom: 30,
      backgroundColor: 'textSecondary',
      outline: 'none',
    },
    form: {
      height: 50,
      width: '80%',
      marginTop: 20,
      marginLeft: 45,
    },
    formControl: {
      marginBottom: 100,
      width: '5%',
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    deleteModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: 'white',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    EditButton: {
      marginLeft: theme.spacing(2),
    },
    rowAreaformControl: {
      margin: theme.spacing(1),
      maxWidth: '200px',
    },
    createGroupButton: {
      marginLeft: theme.spacing(2),
    },
    customTableContainer: {
      overflowX: 'initial',
    },
  })
);

type CompanyBattleProps = RouteComponentProps<{
  companybattleid: string;
}>;

function CompanyBattleEditModal(props: {
  isOpen: boolean;
  onClose: () => void;
  companybattleOrigin: CompanyBattle;
}) {
  const { isOpen, onClose, companybattleOrigin } = props;
  const [companybattle, setCompanyBattle] = useState(companybattleOrigin);
  const [companybattleUsers, setCompanyBattleUsers] =
    useState(companybattleOrigin);
  const { usePutCompanyBattleMemberMutation } = useCompanyBattles();
  const putCompanyBattleUsersMutate =
    usePutCompanyBattleMemberMutation().mutate;
  const { useGetUserMutation } = useUser();
  const [searchedUsersName, setSearchedUsersName] = useState('');
  const [searchedUsersId, setSearchedUsersId] = useState('');
  const [searchedUsersEmail, setSearchedUsersEmail] = useState('');
  const [searchedJoinedUsersName, setSearchedJoinedUsersName] = useState('');
  const [searchedJoinedUsersId, setSearchedJoinedUsersId] = useState('');
  const [searchedJoinedUsersEmail, setSearchedJoinedUsersEmail] = useState('');

  useEffect(() => {
    setCompanyBattleUsers(companybattleOrigin);
  }, [companybattleOrigin]);

  const { data: usersData } = useGetUserMutation();

  const addUsersToCompanyBattle = async (user: User) => {
    setCompanyBattleUsers((state) => {
      return {
        ...state,
        users: [...state.users, user],
      };
    });
  };

  const removeUsersFromCompanyBattle = async (users: User) => {
    setCompanyBattleUsers((state) => {
      return {
        ...state,
        users: state.users.filter((usersRow) => usersRow.uid !== users.uid),
      };
    });
  };

  const confirmCompanyBattle = async () => {
    await putCompanyBattleUsersMutate(companybattleUsers);
    onClose();
  };

  const cancelEditCompanyBattle = async () => {
    setCompanyBattleUsers(companybattleOrigin);
    onClose();
  };

  const notPerticipatedUsers = usersData?.filter(
    (user) =>
      !companybattle.users?.find(
        (unJoinedUsers) => unJoinedUsers.uid === user.uid
      )
  );

  const searchedResultUsers = notPerticipatedUsers?.filter(
    (user) =>
      user.uname.includes(searchedUsersName) &&
      user.employeeId?.toString().includes(searchedUsersId) &&
      user.email.includes(searchedUsersEmail)
  );

  const clearJoinedUsersSearch = () => {
    setSearchedJoinedUsersName('');
    setSearchedJoinedUsersId('');
    setSearchedJoinedUsersEmail('');
  };

  const clearUsersSearch = () => {
    setSearchedUsersName('');
    setSearchedUsersId('');
    setSearchedUsersEmail('');
  };

  const searchResultUsers = companybattle.users?.filter(
    (user) =>
      user.uname.includes(searchedJoinedUsersName) &&
      user.employeeId.toString().includes(searchedJoinedUsersId) &&
      user.email.includes(searchedJoinedUsersEmail)
  );

  return (
    <ThemeProvider theme={theme}>
      <ModalMolecures
        isOpen={isOpen}
        onClose={() => false}
        fullWidth={true}
        maxWidth={false}>
        <Typography
          color="textSecondary"
          variant="h5"
          gutterBottom
          component="div">
          参加ユーザー編集
        </Typography>
        <hr></hr>
        <Grid container spacing={0} style={{ width: '80vw' }}>
          <Grid item xs={6}>
            <DialogContent>
              <DialogContentText>
                対抗戦に参加しているユーザー
              </DialogContentText>
              <Table
                size="small"
                aria-label="purchases"
                style={{ marginBottom: '2%' }}>
                <TableBody>
                  <TableRow>
                    <TableCell size="small" style={{ width: '20%' }}>
                      <TextField
                        id="searchedJoinedUsersName"
                        label="氏名"
                        value={searchedJoinedUsersName}
                        onChange={(event) =>
                          setSearchedJoinedUsersName(event.target.value)
                        }
                        name="usersName"
                        placeholder="氏名"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell size="small" style={{ width: '20%' }}>
                      <TextField
                        id="searchedJoinedUsersId"
                        label="社員番号"
                        value={searchedJoinedUsersId}
                        onChange={(event) =>
                          setSearchedJoinedUsersId(event.target.value)
                        }
                        name="userId"
                        placeholder="社員番号"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell style={{ width: '50%' }}>
                      <TextField
                        id="searchedJoinedUsersEmail"
                        label="メールアドレス"
                        value={searchedJoinedUsersEmail}
                        onChange={(event) =>
                          setSearchedJoinedUsersEmail(event.target.value)
                        }
                        name="userId"
                        placeholder="メールアドレス"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell align="right" style={{ width: '10%' }}>
                      <IconButton aria-label="delete">
                        <HighlightOffIcon
                          onClick={() => clearJoinedUsersSearch()}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <TableContainer style={{ height: '50vh' }}>
                <Table
                  stickyHeader
                  size="small"
                  aria-label="perticipatedUsersTable">
                  <TableHead>
                    <TableRow>
                      <TableCell>氏名</TableCell>
                      <TableCell>社員番号</TableCell>
                      <TableCell>メール</TableCell>
                      <TableCell align="center">削除</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchResultUsers?.map((user) => (
                      <TableRow
                        key={companybattle.companybattle_id + '-' + user.uid}>
                        <TableCell component="th" scope="row">
                          {user.uname}
                        </TableCell>
                        <TableCell>{user.employeeId}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="delete"
                            onClick={() => removeUsersFromCompanyBattle(user)}>
                            <DeleteIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          </Grid>
          <Grid item xs={6}>
            <DialogContent>
              <DialogContentText>
                対抗戦に追加するユーザーを設定してください
              </DialogContentText>
              <Table
                size="small"
                aria-label="purchases"
                style={{ marginBottom: '2%' }}>
                <TableBody>
                  <TableRow>
                    <TableCell size="small" style={{ width: '20%' }}>
                      <TextField
                        id="searchedUsersName"
                        label="氏名"
                        value={searchedUsersName}
                        onChange={(event) =>
                          setSearchedUsersName(event.target.value)
                        }
                        name="usersName"
                        placeholder="氏名"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell size="small" style={{ width: '20%' }}>
                      <TextField
                        id="searchedUsersId"
                        label="社員番号"
                        value={searchedUsersId}
                        onChange={(event) =>
                          setSearchedUsersId(event.target.value)
                        }
                        name="userId"
                        placeholder="社員番号"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell style={{ width: '50%' }}>
                      <TextField
                        id="searchedUsersEmail"
                        label="メールアドレス"
                        value={searchedUsersEmail}
                        onChange={(event) =>
                          setSearchedUsersEmail(event.target.value)
                        }
                        name="userId"
                        placeholder="メールアドレス"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell align="right" style={{ width: '10%' }}>
                      <IconButton aria-label="delete">
                        <HighlightOffIcon onClick={() => clearUsersSearch()} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <TableContainer style={{ height: '50vh' }}>
                <Table stickyHeader size="small" aria-label="search-result">
                  <TableHead>
                    <TableRow>
                      <TableCell>氏名</TableCell>
                      <TableCell>社員番号</TableCell>
                      <TableCell>メール</TableCell>
                      <TableCell align="center">追加</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchedResultUsers?.map((user, idx) => (
                      <TableRow key={idx}>
                        <TableCell component="th">{user.uname}</TableCell>
                        <TableCell>{user.employeeId}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="add"
                            onClick={() => addUsersToCompanyBattle(user)}>
                            <AddCircleOutlineIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => cancelEditCompanyBattle()} color="primary">
                キャンセル
              </Button>
              <Button onClick={() => confirmCompanyBattle()} color="primary">
                決定
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </ModalMolecures>
    </ThemeProvider>
  );
}

const CompanyBattleDetail: React.FC<CompanyBattleProps> = (props) => {
  const classes = useStyles();
  const { showToast } = useToast();
  const { useGetSelectedGroupBattleMutation } = useCompanyBattles();
  const { useGetCompany } = useCompanies();
  const companybattleid = props.match.params.companybattleid;

  const [companyEditModalState, setCompanyEditModalState] =
    React.useState(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const {
    isLoading: companyIsLoading,
    data: companyData,
    isError: companyIsError,
  } = useGetCompany();

  const {
    isLoading: companybattleIsLoading,
    data: companybattleData,
    isError: companybattleIsError,
  } = useGetSelectedGroupBattleMutation(Number(companybattleid));

  if (!companyData || !companybattleData) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (companyIsLoading || companybattleIsLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (companyIsError || companybattleIsError) {
    showToast(
      'error',
      'データ取得に失敗しました',
      '時間をおいて再度お試しください',
      3000
    );
    return (
      <MenuBarsTemplate title="">
        <></>
      </MenuBarsTemplate>
    );
  }

  return (
    <CompanyCtx.Provider value={companyData}>
      <MenuBarsTemplate title="対抗戦詳細">
        <Typography
          color="textSecondary"
          variant="h5"
          gutterBottom
          component="div">
          <Link to={`/companybattles`}>
            <IconButton>
              <ChevronLeftIcon />
            </IconButton>
          </Link>
          対抗戦詳細
          {/* <Button
            className={classes.createGroupButton}
            variant="outlined"
            vertical-align="center"
            size="small"
            onClick={() => {
              setCompanyEditModalState(true);
            }}>
            <EditIcon />
            参加ユーザー編集
          </Button> */}
        </Typography>
        <br />
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example">
              {companybattleData.companybattle_battleevents.map(
                (battleevent, idx) => (
                  <Tab
                    label={battleevent.companybattle_battleevents_name}
                    key={`tabmenu-${idx}`}
                    {...a11yProps(idx)}
                  />
                )
              )}
            </Tabs>
          </AppBar>
          {companybattleData.companybattle_battleevents.map(
            (battleevent, idx) => (
              <TabPanel key={`tabpanel-${idx}`} value={value} index={idx}>
                <Grid container>
                  <Grid item xs={9}>
                    <Line
                      height={window.innerHeight * 0.6}
                      width={window.innerWidth * 0.8}
                      data={createChartDatas(
                        dayjs(companybattleData.start_date),
                        dayjs(companybattleData.end_date),
                        battleevent
                      )}
                      options={{
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TableContainer style={{ height: '55vh' }}>
                      <Table
                        stickyHeader
                        size="small"
                        aria-label="perticipatedUserTable">
                        <TableHead>
                          <TableRow>
                            <TableCell>参加企業</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {battleevent.company?.map((company) => (
                            <TableRow key={company.companyid}>
                              <TableCell component="th" scope="row">
                                <div
                                  style={{
                                    cursor: 'pointer',
                                    float: 'left',
                                    width: '18px',
                                    height: '18px',
                                    border: '1px solid #999',
                                    margin: '1px',
                                    backgroundColor: `#${company.team_color}`,
                                  }}>
                                  &nbsp;
                                </div>
                                &nbsp;
                                {company.name}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
            )
          )}
        </div>
        <Paper className={classes.root}></Paper>
      </MenuBarsTemplate>
      <CompanyBattleEditModal
        isOpen={companyEditModalState}
        onClose={() => setCompanyEditModalState(false)}
        companybattleOrigin={companybattleData}></CompanyBattleEditModal>
    </CompanyCtx.Provider>
  );
};

export default CompanyBattleDetail;
