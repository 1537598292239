import { commonHttpClient } from './commonHttpClient';
import { useQuery } from 'react-query';
// import { useToast } from '../../components/molecules/toastMolecules';
import { BattleEvent } from '../../utils/interfaces/Battle';

export const useBattleEvents = () => {
  const { http } = commonHttpClient();
  // const queryClient = useQueryClient();
  // const { showToast } = useToast();

  const getBattleEvents = async (): Promise<BattleEvent[]> => {
    const { data } = await http.get('/battleevents');
    return data as BattleEvent[];
  };

  const useReadBattleEvents = () => useQuery('BattleEvents', getBattleEvents);

  return {
    useBattleEvents,
    useReadBattleEvents,
  };
};
