import { commonHttpClient } from './commonHttpClient';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useToast } from '../../components/molecules/toastMolecules';
import {
  Admin,
  CreateAdminPayload,
  UpdateAdminPayload,
} from '../../utils/interfaces/Admin';

export const useAdmins = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const getAdmins = async (): Promise<Admin[]> => {
    const { data } = await http.get('/admins');
    return data as Admin[];
  };

  const createAdmin = async (
    createAdminPayload: CreateAdminPayload
  ): Promise<void> => {
    await http.post('/admins', createAdminPayload);
  };

  const updateAdmin = async (
    updateAdminPayload: UpdateAdminPayload
  ): Promise<void> => {
    await http.patch('/admins', updateAdminPayload);
  };

  const deleteAdmins = async (id: number): Promise<void> => {
    await http.delete(`/admins/${id}`);
  };

  const useGetAdmins = () => useQuery('Admins', getAdmins);

  const useCreateAdmin = () =>
    useMutation(createAdmin, {
      onSuccess: () => {
        showToast(
          'success',
          '管理者を追加しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('Admins');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  const useUpdateAdmin = () =>
    useMutation(updateAdmin, {
      onSuccess: () => {
        showToast(
          'success',
          '管理者の情報を更新しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('Admins');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  const useDeleteAdmin = () =>
    useMutation(deleteAdmins, {
      onSuccess: () => {
        showToast(
          'success',
          '管理者を削除しました',
          '※画面が更新がされない場合はブラウザの更新をしてください',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries('Admins');
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'データ更新に失敗しました', e.message, 3000);
        }
      },
    });

  return {
    useGetAdmins,
    useCreateAdmin,
    useUpdateAdmin,
    useDeleteAdmin,
  };
};
