import React from 'react';
import 'rsuite/dist/styles/rsuite-default.css';
///* rsuiteのモーダルを画面全面に表示するための設定追加
import './index.css';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import AdministratorsPage from './components/pages/Administrators';
import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';
import ChallengesPage from './components/pages/Challenges';
import DashBoardPage from './components/pages/DashBoard';
import GroupPage from './components/pages/Groups';
import HealthChecksPage from './components/pages/HealthChecks';
import LoginPage from './components/pages/Login';
import UserDetailPage from './components/pages/UserDetail';
import UsersPage from './components/pages/Users';
import ResetPasswordPage from './components/pages/ResetPassword';
import GroupBattlePage from './components/pages/GroupBattle/GroupBattles';
import GroupBattleDetailPage from './components/pages/GroupBattle/GroupBattleDetail';
import TalkgroupPage from './components/pages/Chatgroups/Chatgroups';
import TalkgroupListPage from './components/pages/Chatgroups/ChatgroupsList';
import CommonContext from './utils/CommonContext';
import Import from './components/pages/Import';
import LoginManagement from './components/pages/LoginManagement';
import CompanyBattles from './components/pages/CompanyBattle/CompanyBattle';
import CompanyBattleDetail from './components/pages/CompanyBattle/CompanyBattleDetail';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <CommonContext>
        <Router>
          <Switch>
            <PublicRoute path="/login" component={LoginPage} exact />
            <PublicRoute
              path="/reset_password"
              component={ResetPasswordPage}
              exact
            />
            <PrivateRoute path="/dashboard" component={DashBoardPage} exact />
            <PrivateRoute
              path="/administrators"
              component={AdministratorsPage}
              exact
            />
            <PrivateRoute path="/challenges" component={ChallengesPage} exact />
            <PrivateRoute path="/groups" component={GroupPage} exact />
            <PrivateRoute
              path="/healthchecks"
              component={HealthChecksPage}
              exact
            />
            <PrivateRoute path="/users" component={UsersPage} exact />
            <PrivateRoute path="/userdetail" component={UserDetailPage} exact />
            <PrivateRoute path="/import" component={Import} exact />
            <PrivateRoute
              path="/login_management/:login_date"
              component={LoginManagement}
            />
            <PrivateRoute
              path="/groupbattles"
              component={GroupBattlePage}
              exact
            />

            <PrivateRoute path="/chatgroups" component={TalkgroupPage} exact />

            <PrivateRoute
              path="/chatgroupsList"
              component={TalkgroupListPage}
              exact
            />

            <PrivateRoute
              path="/groupbattledetail/:groupbattleid"
              component={GroupBattleDetailPage}
              exact
            />
            <PrivateRoute
              path="/companybattles"
              component={CompanyBattles}
              exact
            />
            <PrivateRoute
              path="/companybattledetail/:companybattleid"
              component={CompanyBattleDetail}
              exact
            />
            <PublicRoute
              path="/"
              render={() => {
                return <Redirect to="/login" />;
              }}
            />
          </Switch>
          {/* TODO  ログインしている時に任意のパス/aaaなどに飛んだ時の制御*/}
        </Router>
      </CommonContext>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
};

export default App;
