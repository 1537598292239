import * as Yup from 'yup';
// const tester = /^[\w+/_?-](\.?[\w+/_?.-])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
// export function emailValidation(
//   this:Yup.TestFunction<string | undefined, Record<string, any>>,email:string
//   )
//   :Schema{//型がわからない

//       const tester = /^[\w+/_?-](\.?[\w+/_?.-])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
//       if (!email) return false;

//       const emailParts = email.split('@');

//       if(emailParts.length !== 2) return false

//       const account = emailParts[0];
//       const address = emailParts[1];

//       if(account.length > 64) return false

//       else if(address.length > 255) return false

//       const domainParts = address.split('.');
//       if (domainParts.some(function (part) {
//         return part.length > 63;
//       })) return false;

//       if (!tester.test(email)) return false;

//       return true;
// };

export const LOGIN_INITIAL_VALUES = {
  email: '',
  password: '',
};

export const LOGIN_VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .required('メールアドレスを入力してください')
    .test('email', '無効なEmailです', function (this, email) {
      const tester =
        /^[\w+/_?-](\.?[\w+/_?-])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
      if (!email) return false;
      const emailParts = email.split('@');
      if (emailParts.length !== 2) return false;
      const account = emailParts[0];
      const address = emailParts[1];
      if (account.length > 64) return false;
      else if (address.length > 255) return false;
      const domainParts = address.split('.');
      if (
        domainParts.some(function (part) {
          return part.length > 63;
        })
      )
        return false;
      if (!tester.test(email)) return false;
      return true;
    }),

  password: Yup.string()
    .min(8, 'パスワードは8文字以上32文字以内で入力してください')
    .max(32, 'パスワードは8文字以上32文字以内で入力してください')
    .required('パスワードを入力してください'),
});

export const USERS_ADD_INITIAL_VALUES = {
  uname: '',
  rname: '',
  employeeId: '',
  email: '',
  password: '',
  department:'',
};

export const USERS_ADD_VALIDATION_SCHEMA = Yup.object().shape({
  uname: Yup.string()
    .min(1, 'ニックネームは最低1文字以上,20文字以内で入力してください')
    .max(20, 'ニックネームは20文字以内で入力してください')
    .required('ニックネームを入力してください'),
  rname: Yup.string()
    .min(1, '社員名は最低1文字以上,20文字以内で入力してください')
    .max(20, '社員名は20文字以内で入力してください')
    .required('社員名を入力してください'),
  employeeId: Yup.string()
    .min(1, '社員番号は最低1文字以上,24文字以内で入力してください')
    .max(24, '社員番号は24文字以内で入力してください')
    .required('社員番号を入力してください'),
  email: Yup.string()
    .test('email', '無効なEmailです', function (this, email) {
      const tester =
        /^[\w+/_?-](\.?[\w+/_?-])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
      if (!email) return false;
      const emailParts = email.split('@');
      if (emailParts.length !== 2) return false;
      const account = emailParts[0];
      const address = emailParts[1];
      if (account.length > 64) return false;
      else if (address.length > 255) return false;
      const domainParts = address.split('.');
      if (
        domainParts.some(function (part) {
          return part.length > 63;
        })
      )
        return false;
      if (!tester.test(email)) return false;
      return true;
    })
    .required('メールアドレスを入力してください'),
  password: Yup.string()
    .min(8, 'パスワードは8文字以上32文字以内で入力してください')
    .max(32, 'パスワードは8文字以上32文字以内で入力してください')
    .required('パスワードを入力してください')
    .matches(/^[0-9a-zA-Z]+$/, '半額英数字のみで入力してください'),

  // department_name: Yup.string()
  //   .required('部署名を選択してください'),
});

export const USERS_EDIT_VALIDATION_SCHEMA = Yup.object().shape({
  uname: Yup.string()
    .min(1, 'ニックネームは最低1文字以上,20文字以内で入力してください')
    .max(20, 'ニックネームは20文字以内で入力してください')
    .required('ニックネームを入力してください'),
  rname: Yup.string()
    .min(1, '社員名は最低1文字以上,20文字以内で入力してください')
    .max(20, '社員名は20文字以内で入力してください')
    .required('社員名を入力してください'),
  employeeId: Yup.string()
    .min(1, '社員番号は最低1文字以上,24文字以内で入力してください')
    .max(24, '社員番号は24文字以内で入力してください')
    .required('社員番号を入力してください'),
  email: Yup.string()
    .test('email', '無効なEmailです', function (this, email) {
      const tester =
        /^[\w+/_?-](\.?[\w+/_?-])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
      if (!email) return false;
      const emailParts = email.split('@');
      if (emailParts.length !== 2) return false;
      const account = emailParts[0];
      const address = emailParts[1];
      if (account.length > 64) return false;
      else if (address.length > 255) return false;
      const domainParts = address.split('.');
      if (
        domainParts.some(function (part) {
          return part.length > 63;
        })
      )
        return false;
      if (!tester.test(email)) return false;
      return true;
    })
    .required('メールアドレスを入力してください'),

    // department_name: Yup.string()
    // .required('部署名を選択してください'),
});

export const ADMIN_ADD_INITIAL_VALUES = {
  name: '',
  email: '',
  password: '',
  role: '',
};

export const ADMIN_ADD_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .min(1, '社員名は最低1文字以上,20文字以内で入力してください')
    .max(20, '社員名は20文字以内で入力してください')
    .required('社員名を入力してください'),
  email: Yup.string()
    .required('メールアドレスを入力してください')
    .test('email', '無効なEmailです', function (this, email) {
      const tester =
        /^[\w+/_?-](\.?[\w+/_?-])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
      if (!email) return false;
      const emailParts = email.split('@');
      if (emailParts.length !== 2) return false;
      const account = emailParts[0];
      const address = emailParts[1];
      if (account.length > 64) return false;
      else if (address.length > 255) return false;
      const domainParts = address.split('.');
      if (
        domainParts.some(function (part) {
          return part.length > 63;
        })
      )
        return false;
      if (!tester.test(email)) return false;
      return true;
    }),
  password: Yup.string()
    .min(8, 'パスワードは8文字以上32文字以内で入力してください')
    .max(32, 'パスワードは8文字以上32文字以内で入力してください')
    .required('パスワードを入力してください')
    .matches(/^[0-9a-zA-Z]+$/, '半額英数字のみ'),
  role: Yup.array()
    .min(1, '権限を１つ以上選択してください')
    .required('権限を選択してください'),
});

export const ADMIN_EDIT_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .min(1, '社員名は最低1文字以上,20文字以内で入力してください')
    .max(20, '社員名は20文字以内で入力してください')
    .required('社員名を入力してください'),
  email: Yup.string()
    .test('email', '無効なEmailです', function (this, email) {
      const tester =
        /^[\w+/_?-](\.?[\w+/_?-])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
      if (!email) return false;
      const emailParts = email.split('@');
      if (emailParts.length !== 2) return false;
      const account = emailParts[0];
      const address = emailParts[1];
      if (account.length > 64) return false;
      else if (address.length > 255) return false;
      const domainParts = address.split('.');
      if (
        domainParts.some(function (part) {
          return part.length > 63;
        })
      )
        return false;
      if (!tester.test(email)) return false;
      return true;
    })
    .required('メールアドレスを入力してください'),
  admin_role: Yup.array()
    .min(1, '権限を１つ以上選択してください')
    .required('権限を選択してください'),
});

export const CHALLENGE_ADD_INITIAL_VALUES = {
  title: '',
  span: '',
  challetype: '',
  description: '',
  objective: 0,
  prize: 0,
};

export const CHALLENGE_ADD_VALIDATION_SCHEMA = Yup.object().shape({
  title: Yup.string()
    .min(1, 'チャレンジ名は最低1文字以上,64文字以内で入力してください')
    .max(24, 'チャレンジ名は24文字以内で入力してください')
    .required('チャレンジ名を入力してください'),
  span: Yup.string().required('期間を選択してください'),
  challetype: Yup.string().required('チャレンジ種目を選択してください'),
  description: Yup.string().max(
    30,
    'チャレンジ概要を30文字以内で入力してください'
  ),
  objective: Yup.number()
    .min(1, '目標値を入力してください')
    .required('目標値を入力してください'),
  prize: Yup.number()
    .min(1, '達成獲得コイン数を入力してください')
    .required('達成獲得コイン数を入力してください'),
});

export const USERS_PASS_CHANGE_INITIAL_VALUES = {
  password: '',
  cfmPass: '',
};

export const USERS_PASS_CHANGE_VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string()
    .min(8, 'パスワードは8文字以上32文字以内で入力してください')
    .max(32, 'パスワードは8文字以上32文字以内で入力してください')
    .required('パスワードを入力してください')
    .matches(/^[0-9a-zA-Z]+$/, '半額英数字のみで入力してください'),
  cfmPass: Yup.string().oneOf(
    [Yup.ref('password')],
    'パスワードが一致しません'
  ),
});
