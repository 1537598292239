import React, { useEffect, useState, useContext, createContext } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { useGroups } from '../../controllers/services/useGroups';
import { useAdmins } from '../../controllers/services/useAdmins';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Admin } from '../../utils/interfaces/Admin';
import { Group } from '../../utils/interfaces/Group';
import { useToast } from '../molecules/toastMolecules';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LoadingBubbleOrganisms from '../organisms/LoadingBubbleOrganisms';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DoneIcon from '@material-ui/icons/DoneAllTwoTone';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RevertIcon from '@material-ui/icons/NotInterestedOutlined';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import MenuBarsTemplate from '../templates/MenuBarsTemplate';
import ModalMolecures from '../molecules/ModalMolecules';
// import { group } from 'node:console';

const AdminsCtx = createContext<Admin[]>([]);

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#8fc31f' }, // テーマの色
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    container: {
      maxHeight: '70vh',
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    createGroupButton: {
      marginLeft: theme.spacing(2),
    },
  })
);

function GroupDetailModal(props: {
  isOpen: boolean;
  onClose: () => void;
  groupOrigin: Group;
}) {
  const { isOpen, onClose, groupOrigin } = props;
  const [group, setGroup] = useState(groupOrigin);
  const { useUpdateGroupMutation } = useGroups();
  const updateGroupMutate = useUpdateGroupMutation().mutate;
  const admins = useContext(AdminsCtx);
  const [searchedAdminName, setSearchedAdminName] = useState('');
  const [searchedAdminId, setSearchedAdminId] = useState('');
  const [searchedAdminEmail, setSearchedAdminEmail] = useState('');
  const [searchedMemberName, setSearchedMemberName] = useState('');
  const [searchedMemberId, setSearchedMemberId] = useState('');
  const [searchedMemberEmail, setSearchedMemberEmail] = useState('');

  useEffect(() => {
    setGroup(groupOrigin);
  }, [groupOrigin]);

  const addUserToGroup = async (admin: Admin) => {
    setGroup((state) => ({
      ...state,
      admins: [...state.admins, admin],
    }));
  };

  const removeUserFromGroup = async (admin: Admin) => {
    setGroup((state) => {
      return {
        ...state,
        admins: state.admins.filter(
          (adminRow) => adminRow.admin_id !== admin.admin_id
        ),
      };
    });
  };

  const confirmGroup = async () => {
    await updateGroupMutate(group);
    onClose();
  };

  const cancelEditGroup = async () => {
    setGroup(groupOrigin);
    onClose();
  };

  const notPerticipatedAdmins = admins.filter(
    (admin) =>
      !group.admins.find((groupAdmin) => groupAdmin.admin_id == admin.admin_id)
  );

  const searchResultAdmins = notPerticipatedAdmins.filter(
    (admin) =>
      admin.name.includes(searchedAdminName) &&
      admin.admin_id.toString().includes(searchedAdminId) &&
      admin.email.includes(searchedAdminEmail)
  );

  const clearMemberSearch = () => {
    setSearchedMemberName('');
    setSearchedMemberId('');
    setSearchedMemberEmail('');
  };

  const clearAdminSearch = () => {
    setSearchedAdminName('');
    setSearchedAdminId('');
    setSearchedAdminEmail('');
  };

  const searchResultMembers = group.admins.filter(
    (admin) =>
      admin.name.includes(searchedMemberName) &&
      admin.admin_id.toString().includes(searchedMemberId) &&
      admin.email.includes(searchedMemberEmail)
  );

  return (
    <ModalMolecures
      isOpen={isOpen}
      onClose={onClose}
      fullWidth={true}
      maxWidth={false}>
      <Typography
        color="textSecondary"
        variant="h5"
        gutterBottom
        component="div">
        ユーザー編集
      </Typography>
      <hr></hr>
      <Grid container spacing={0} style={{ width: '80vw' }}>
        <Grid item xs={6}>
          <DialogContent>
            <DialogContentText>グループに参加しているユーザー</DialogContentText>
            <Table
              size="small"
              aria-label="purchases"
              style={{ marginBottom: '2%' }}>
              <TableBody>
                <TableRow>
                  <TableCell size="small" style={{ width: '20%' }}>
                    <TextField
                      id="searchedMemberName"
                      label="氏名"
                      value={searchedMemberName}
                      onChange={(event) =>
                        setSearchedMemberName(event.target.value)
                      }
                      name="userId"
                      placeholder="氏名"
                      fullWidth={true}
                    />
                  </TableCell>
                  <TableCell size="small" style={{ width: '20%' }}>
                    <TextField
                      id="searchedMemberId"
                      label="社員番号"
                      value={searchedMemberId}
                      onChange={(event) =>
                        setSearchedMemberId(event.target.value)
                      }
                      name="userId"
                      placeholder="社員番号"
                      fullWidth={true}
                    />
                  </TableCell>
                  <TableCell style={{ width: '50%' }}>
                    <TextField
                      id="searchedMemberEmail"
                      label="メールアドレス"
                      value={searchedMemberEmail}
                      onChange={(event) =>
                        setSearchedMemberEmail(event.target.value)
                      }
                      name="userId"
                      placeholder="メールアドレス"
                      fullWidth={true}
                    />
                  </TableCell>
                  <TableCell align="right" style={{ width: '10%' }}>
                    <IconButton aria-label="delete">
                      <HighlightOffIcon onClick={() => clearMemberSearch()} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TableContainer style={{ height: '50vh' }}>
              <Table
                stickyHeader
                size="small"
                aria-label="perticipatedUserTable">
                <TableHead>
                  <TableRow>
                    <TableCell>氏名</TableCell>
                    <TableCell>社員番号</TableCell>
                    <TableCell>メール</TableCell>
                    <TableCell align="center">削除</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchResultMembers.map((admin) => (
                    <TableRow key={group.group_id + '-' + admin.admin_id}>
                      <TableCell component="th" scope="row">
                        {admin.name}
                      </TableCell>
                      <TableCell>{admin.admin_id}</TableCell>
                      <TableCell>{admin.email}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="delete"
                          onClick={() => removeUserFromGroup(admin)}>
                          <DeleteIcon color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Grid>
        <Grid item xs={6}>
          <DialogContent>
            <DialogContentText>
              グループに追加するユーザーを設定してください
            </DialogContentText>
            <Table
              size="small"
              aria-label="purchases"
              style={{ marginBottom: '2%' }}>
              <TableBody>
                <TableRow>
                  <TableCell size="small" style={{ width: '20%' }}>
                    <TextField
                      id="searchedAdminName"
                      label="氏名"
                      value={searchedAdminName}
                      onChange={(event) =>
                        setSearchedAdminName(event.target.value)
                      }
                      name="userId"
                      placeholder="氏名"
                      fullWidth={true}
                    />
                  </TableCell>
                  <TableCell size="small" style={{ width: '20%' }}>
                    <TextField
                      id="searchedAdminId"
                      label="社員番号"
                      value={searchedAdminId}
                      onChange={(event) =>
                        setSearchedAdminId(event.target.value)
                      }
                      name="userId"
                      placeholder="社員番号"
                      fullWidth={true}
                    />
                  </TableCell>
                  <TableCell style={{ width: '50%' }}>
                    <TextField
                      id="searchedAdminEmail"
                      label="メールアドレス"
                      value={searchedAdminEmail}
                      onChange={(event) =>
                        setSearchedAdminEmail(event.target.value)
                      }
                      name="userId"
                      placeholder="メールアドレス"
                      fullWidth={true}
                    />
                  </TableCell>
                  <TableCell align="right" style={{ width: '10%' }}>
                    <IconButton aria-label="delete">
                      <HighlightOffIcon onClick={() => clearAdminSearch()} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TableContainer style={{ height: '50vh' }}>
              <Table stickyHeader size="small" aria-label="search-result">
                <TableHead>
                  <TableRow>
                    <TableCell>氏名</TableCell>
                    <TableCell>社員番号</TableCell>
                    <TableCell>メール</TableCell>
                    <TableCell align="center">追加</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchResultAdmins.map((admin, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th">{admin.name}</TableCell>
                      <TableCell>{admin.admin_id}</TableCell>
                      <TableCell>{admin.email}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="add"
                          onClick={() => addUserToGroup(admin)}>
                          <AddCircleOutlineIcon color="primary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelEditGroup()} color="primary">
              キャンセル
            </Button>
            <Button onClick={() => confirmGroup()} color="primary">
              決定
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </ModalMolecures>
  );
}

function GroupRow(props: { group: Group }) {
  const classes = useStyles();

  const { group: groupOrigin } = props;
  const [open, setOpen] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [group, setGroup] = React.useState<Group>(groupOrigin);
  const [previous, setPrevious] = React.useState({} as Group);
  const { useUpdateGroupMutation, useDeleteGroupMutation } = useGroups();
  const updateGroupMutate = useUpdateGroupMutation().mutate;
  const deleteGroupMutate = useDeleteGroupMutation().mutate;

  useEffect(() => {
    setGroup(groupOrigin);
  }, [groupOrigin]);

  // モーダル表示制御関数
  const [userEditModalState, setUserEditModalState] = React.useState(false);
  const [deleteGroupModalState, setDeleteGroupModalState] = React.useState<{
    [key: string]: number;
  }>({});

  const onToggleEditMode = () => {
    setIsEditMode((state) => !state);
    if (!Object.keys(previous).length) {
      setPrevious((state) => ({ ...state, ...group }));
    }
  };

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    group: Group
  ) => {
    const value = event.target.value;
    const group_name = event.target.name;
    const { group_id } = group;
    const newGroup = {
      ...group,
      [group_name]: value,
    };
    setGroup(newGroup);
  };

  const onConfirm = async (id: number) => {
    setPrevious((state) => {
      state = {} as Group;
      return state;
    });
    await updateGroupMutate({
      group_id: group.group_id,
      group_name: group.group_name,
      admins: group.admins,
    });
    onToggleEditMode();
  };

  const onRevert = (id: number) => {
    setGroup(previous);
    setPrevious((state) => {
      state = {} as Group;
      return state;
    });
    onToggleEditMode();
  };

  const onClickDeleteGroupButton = async () => {
    await deleteGroupMutate(deleteGroupModalState.group_id);
    setDeleteGroupModalState({});
  };
  return group.is_invalidated ? null : (
    <React.Fragment>
      {/* グループ表示領域 */}
      <TableRow className={classes.root} hover role="checkbox" tabIndex={-1}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {isEditMode ? (
            <TextField
              id="standard-basic"
              label="問い合わせ窓口名"
              onChange={(event) => onChange(event, group)}
              onKeyPress={(e) => {
                if (e.key == 'Enter' && group.group_name !== '') {
                  e.preventDefault();
                  onConfirm(group.group_id);
                }
              }}
              name="group_name"
              placeholder="問い合わせ窓口名"
              defaultValue={group.group_name}
              fullWidth={true}
            />
          ) : (
            <div>{group.group_name}</div>
          )}
        </TableCell>
        <TableCell align="center">
          {isEditMode ? (
            <>
              <IconButton
                aria-label="done"
                onClick={(e) => onConfirm(group.group_id)}>
                <DoneIcon color="primary" />
              </IconButton>
              <IconButton
                aria-label="revert"
                onClick={() => onRevert(group.group_id)}>
                <RevertIcon color="secondary" />
              </IconButton>
            </>
          ) : (
            <IconButton aria-label="edit" onClick={(e) => onToggleEditMode()}>
              <EditIcon color="primary" />
            </IconButton>
          )}
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="delete"
            onClick={() =>
              setDeleteGroupModalState({ group_id: group.group_id })
            }>
            <DeleteIcon color="secondary" />
          </IconButton>
        </TableCell>
      </TableRow>
      {/* 参加ユーザー表示領域 */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                参加メンバー
                <Button
                  className={classes.createGroupButton}
                  variant="outlined"
                  vertical-align="center"
                  size="small"
                  onClick={() => {
                    setUserEditModalState(true);
                  }}>
                  <EditIcon />
                  編集
                </Button>
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>氏名</TableCell>
                    <TableCell>メール</TableCell>
                    <TableCell>権限</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {group.admins.map((admin) => (
                    <TableRow key={group.group_id + '-' + admin.admin_id}>
                      <TableCell component="th" scope="row">
                        {admin.name}
                      </TableCell>
                      <TableCell>{admin.email}</TableCell>
                      <TableCell>{admin.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <GroupDetailModal
        isOpen={userEditModalState}
        onClose={() => setUserEditModalState(false)}
        groupOrigin={group}></GroupDetailModal>
      <ModalMolecures
        isOpen={!!Object.keys(deleteGroupModalState).length}
        onClose={() => setDeleteGroupModalState({})}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          noWrap
          style={{ margin: '2%' }}>
          グループ削除
        </Typography>
        <hr></hr>
        <DialogContent>
          <DialogContentText>
            グループを削除します、よろしいですか
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteGroupModalState({})} color="primary">
            キャンセル
          </Button>
          <Button onClick={() => onClickDeleteGroupButton()} color="secondary">
            削除
          </Button>
        </DialogActions>
      </ModalMolecures>
    </React.Fragment>
  );
}

const Groups: React.FC = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [createGroupName, setCreateGroupName] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { showToast } = useToast();
  const { useGetGroups, useCreateGroupMutation } = useGroups();
  const { useGetAdmins } = useAdmins();
  const createGroupMutate = useCreateGroupMutation().mutate;

  const {
    isLoading: groupIsLoading,
    data: groupData,
    isError: groupIsError,
    error: groupError,
  } = useGetGroups();

  const {
    isLoading: adminIsLoading,
    data: adminData,
    isError: adminIsError,
    error: adminError,
  } = useGetAdmins();

  // モーダル表示制御関数
  const [createModalState, setCreateModalState] = React.useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onClickCreateGroupButton = async () => {
    await createGroupMutate({ group_name: createGroupName });
    setCreateModalState(false);
    setCreateGroupName('');
  };

  if (!groupData || !adminData) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (groupIsLoading || adminIsLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }
  if (groupIsError || adminIsError) {
    showToast(
      'error',
      'データ取得に失敗しました',
      '時間をおいて再度お試しください',
      3000
    );
    return (
      <MenuBarsTemplate title="">
        <></>
      </MenuBarsTemplate>
    );
  }

  return (
    <AdminsCtx.Provider value={adminData}>
      <MenuBarsTemplate title="窓口管理">
        <Typography
          color="textSecondary"
          variant="h5"
          gutterBottom
          component="div">
          窓口一覧
          <Button
            className={classes.createGroupButton}
            variant="outlined"
            vertical-align="center"
            size="small"
            onClick={() => {
              setCreateModalState(true);
            }}>
            <AddCircleOutlineIcon />
            新規作成
          </Button>
        </Typography>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: '5%' }}></TableCell>
                  <TableCell align="left" style={{ width: '80%' }}>
                    窓口名
                  </TableCell>
                  <TableCell align="center" style={{ width: '10%' }}>
                    編集
                  </TableCell>
                  <TableCell align="center" style={{ width: '5%' }}>
                    削除
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((group) => (
                    <GroupRow key={group.group_id} group={group} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={groupData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />

          {/*真っ白 <h1>{groupData}</h1> */}
        </Paper>
        <ModalMolecures
          isOpen={createModalState}
          onClose={() => setCreateModalState(false)}>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            noWrap
            style={{ margin: '2%' }}>
            新規グループ作成
          </Typography>
          <hr></hr>
          <DialogContent>
            <DialogContentText>
              新たに作成する問い合わせ窓口の名前を入力してください
            </DialogContentText>
            <TextField
              value={createGroupName}
              onChange={(event) => setCreateGroupName(event.target.value)}
              onKeyPress={(e) => {
                if (e.key == 'Enter' && createGroupName !== '') {
                  e.preventDefault();
                  onClickCreateGroupButton();
                }
              }}
              autoFocus
              margin="dense"
              id="inquiry-team-name"
              label="窓口名"
              fullWidth={true}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCreateModalState(false)} color="primary">
              キャンセル
            </Button>
            <Button
              onClick={() => onClickCreateGroupButton()}
              color="primary"
              disabled={createGroupName === ''}>
              決定
            </Button>
          </DialogActions>
        </ModalMolecures>
      </MenuBarsTemplate>
    </AdminsCtx.Provider>
  );
};

export default Groups;
