import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MenuBarsTemplate from '../../templates/MenuBarsTemplate';
import LoadingBubbleOrganisms from '../../organisms/LoadingBubbleOrganisms';
import { Paper, Box, Typography } from '@material-ui/core';
import 'date-fns';
import { useCompanyBattleEvents } from '../../../controllers/services/useCompanyBattleEvents';
import { useToast } from '../../molecules/toastMolecules';
import { useChatgroups } from '../../../controllers/services/useChatgroups';
import ChatgroupsList from './ChatgroupsList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      padding: 20,
    },
    container: {
      maxHeight: 2000,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      height: 600,
      width: 500,
      padding: 20,
      outline: 'none',
      borderRadius: 10,
    },
    button: {
      height: 50,
      width: 200,
      marginBottom: 30,
      backgroundColor: 'textSecondary',
      outline: 'none',
    },
    form: {
      height: 50,
      width: '80%',
      marginTop: 20,
      marginLeft: 45,
    },
    formControl: {
      marginBottom: 100,
      width: '5%',
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    deleteModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: 'white',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    EditButton: {
      marginLeft: theme.spacing(2),
    },
    rowAreaformControl: {
      margin: theme.spacing(1),
      maxWidth: '200px',
    },
    createGroupButton: {
      marginLeft: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(3),
    },
  })
);

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Chatgroups: React.FC = () => {
  const classes = useStyles();
  const { showToast } = useToast();
  const { useGetChatgroupsMutation } = useChatgroups();
  const {
    isLoading: chatgroupsLoading,
    data: chatgroupsData,
    isError: chatgroupsIsError,
  } = useGetChatgroupsMutation();

  if (!chatgroupsData) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (chatgroupsLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (chatgroupsIsError) {
    showToast(
      'error',
      'データ取得に失敗しました',
      '時間をおいて再度お試しください',
      3000
    );
    return (
      <MenuBarsTemplate title="">
        <></>
      </MenuBarsTemplate>
    );
  }

  return (
    <MenuBarsTemplate title="トークグループ管理">
      <Typography
        component="h2"
        variant="h5"
        color="textSecondary"
        className={classes.title}>
        トークグループ一覧
      </Typography>
      <Paper square className={classes.root}>
        <ChatgroupsList></ChatgroupsList>
      </Paper>
    </MenuBarsTemplate>
  );
};

export default Chatgroups;
