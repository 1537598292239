import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { commonHttpClient } from '../../controllers/services/commonHttpClient';
import { useToast } from '../../components/molecules/toastMolecules';
import AuthTemplate from '../templates/AuthTemplate';
import {
  USERS_PASS_CHANGE_INITIAL_VALUES,
  USERS_PASS_CHANGE_VALIDATION_SCHEMA,
} from '../../utils/validator';
import { useFormik } from 'formik';
import { useQueryParams } from '../../utils/useQueryParams';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    background: 'linear-gradient(45deg, #17A673 100%, #FE6B8B 0%)',
  },
  image: {
    backgroundImage: `url(${window.location.origin}/LEAF.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    background: 'linear-gradient(45deg, #74ad6c 30%, #55a617 70%)',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: 'linear-gradient(45deg, #17A673 100%, #FE6B8B 0%)',
  },
  notchedOutline: {
    borderWidth: '2px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: '#17A673',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#17A673',
      },
    },
  },
}));

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://arteryex.biz/about-us/">
        with LEAF
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export interface AuthTemplateProps {
  children: React.ReactNode;
}

const PasswordReset: React.FC = () => {
  const classes = useStyles();

  const router = useHistory();
  const { http } = commonHttpClient();
  const [password, setPassword] = useState('');
  const [cfmPass, setCfmPass] = useState('');
  const { showToast } = useToast();
  const queryParameters = useQueryParams();

  console.log(queryParameters.get('e'));

  const { values, touched, handleSubmit, handleChange, errors, resetForm } =
    useFormik({
      initialValues: USERS_PASS_CHANGE_INITIAL_VALUES,
      validationSchema: USERS_PASS_CHANGE_VALIDATION_SCHEMA,
      onSubmit: onSubmit,
    });

  async function onSubmit() {
    try {
      await http.patch('users/mail', {
        password: values.password,
        email: queryParameters.get('e'),
      });
      showToast(
        'success',
        'パスワードを再設定しました',
        '※画面が更新がされない場合はブラウザの更新をしてください',
        3000
      );
      resetForm();
    } catch (err) {
      Alert.error('入力項目に誤りがあります。');
    }
  }

  return (
    <AuthTemplate>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          パスワード再設定
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            error={touched.password && !!errors.password}
            helperText={touched.password ? errors.password : ''}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            id="password"
            label="再設定パスワード"
            placeholder="再設定パスワード"
            value={values.password}
            onChange={handleChange}
            InputLabelProps={{ style: { color: '#17A673' } }}
            name="password"
            autoComplete="password"
            autoFocus
            className={classes.notchedOutline}
          />
          <TextField
            error={touched.cfmPass && !!errors.cfmPass}
            helperText={touched.cfmPass ? errors.cfmPass : ''}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="cfmPass"
            label="再設定パスワード(確認)"
            placeholder="再設定パスワード(確認)"
            value={values.cfmPass}
            onChange={handleChange}
            InputLabelProps={{ style: { color: '#17A673' } }}
            type="password"
            id="cfmPass"
            autoComplete="current-password"
            className={classes.notchedOutline}
          />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={(e) => {
              handleSubmit();
            }}
            className={classes.submit}>
            決定
          </Button>
          <Grid container>
            <Grid item xs></Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
      </div>
    </AuthTemplate>
  );
};

export default PasswordReset;
