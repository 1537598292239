import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import MenuBarsTemplate from '../../templates/MenuBarsTemplate';
import ModalMolecures from '../../molecules/ModalMolecules';
import LoadingBubbleOrganisms from '../../organisms/LoadingBubbleOrganisms';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/EditOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { LoginUserContext } from '../../../utils/CommonContext';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  Box,
  Collapse,
} from '@material-ui/core';
import dayjs from 'dayjs';
import 'date-fns';

import { useCompanyBattles } from '../../../controllers/services/useCompanyBattle';
import {
  CompanyBattleEvent,
  CompanyBattle,
} from '../../../utils/interfaces/CompanyBattle';
import { useCompanyBattleEvents } from '../../../controllers/services/useCompanyBattleEvents';
import { useToast } from '../../molecules/toastMolecules';
import { Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useUser } from '../../../controllers/services/useUser';
import { User } from '../../../utils/interfaces/User';

const CompanyBattleEventsCtx = createContext<CompanyBattleEvent[]>([]);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    container: {
      maxHeight: 2000,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      height: 600,
      width: 500,
      padding: 20,
      outline: 'none',
      borderRadius: 10,
    },
    button: {
      height: 50,
      width: 200,
      marginBottom: 30,
      backgroundColor: 'textSecondary',
      outline: 'none',
    },
    form: {
      height: 50,
      width: '80%',
      marginTop: 20,
      marginLeft: 45,
    },
    formControl: {
      marginBottom: 100,
      width: '5%',
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    deleteModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: 'white',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    EditButton: {
      marginLeft: theme.spacing(2),
    },
    rowAreaformControl: {
      margin: theme.spacing(1),
      maxWidth: '200px',
    },
    createDepartmentButton: {
      marginLeft: theme.spacing(2),
    },
    createCompanyBattleMemberButton: {
      marginLeft: theme.spacing(2),
    },
  })
);

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#8fc31f' }, // テーマの色
  },
});

function CompanyBattleEditModal(props: {
  isOpen: boolean;
  onClose: () => void;
  companybattleOrigin: CompanyBattle;
}) {
  const { isOpen, onClose, companybattleOrigin } = props;
  const [companybattle, setCompanyBattle] = useState(companybattleOrigin);
  const [companybattleUsers, setCompanyBattleUsers] =
    React.useState<CompanyBattle>(companybattleOrigin);
  const { usePutCompanyBattleMemberMutation } = useCompanyBattles();
  const putCompanyBattleUsersMutate =
    usePutCompanyBattleMemberMutation().mutate;
  const { useGetUserMutation } = useUser();
  const [searchedUsersName, setSearchedUsersName] = useState('');
  const [searchedUsersId, setSearchedUsersId] = useState('');
  const [searchedUsersEmail, setSearchedUsersEmail] = useState('');
  const [searchedJoinedUsersName, setSearchedJoinedUsersName] = useState('');
  const [searchedJoinedUsersId, setSearchedJoinedUsersId] = useState('');
  const [searchedJoinedUsersEmail, setSearchedJoinedUsersEmail] = useState('');
  const loginUser = useContext(LoginUserContext);
  const [searchedJoinedUsersRname, setSearchedJoinedUsersRname] = useState('');
  const [searchedUsersRname, setSearchedUsersRname] = useState('');

  useEffect(() => {
    setCompanyBattleUsers(companybattleOrigin);
  }, [companybattleOrigin]);

  const { data: usersData } = useGetUserMutation();

  const addUsersToCompanyBattle = async (user: User) => {
    setCompanyBattleUsers((state) => {
      return {
        ...state,
        users: [...state.users, user],
      };
    });
  };

  const addAllUsersToCompanyBattle = async () => {
    searchedResultUsers?.map((user) => {
      setCompanyBattleUsers((state) => {
        return {
          ...state,
          users: [...state.users, user],
        };
      });
    });
  };

  const removeUsersFromCompanyBattle = async (user: User) => {
    setCompanyBattleUsers((state) => {
      return {
        ...state,
        users: state.users.filter((usersRow) => usersRow.uid !== user.uid),
      };
    });
  };

  const removeAllUsersFromCompanyBattle = async () => {
    searchResultUsers
      .filter((user) => user.companyid.toString() === loginUser.company_id)
      .map((user) => {
        setCompanyBattleUsers((state) => {
          return {
            ...state,
            users: state.users.filter((userRow) => userRow.uid !== user.uid),
          };
        });
      });
  };

  const confirmCompanyBattle = async () => {
    await putCompanyBattleUsersMutate(companybattleUsers);
    onClose();
  };

  const cancelEditCompanyBattle = async () => {
    setCompanyBattleUsers(companybattleOrigin);
    onClose();
  };

  const notPerticipatedUsers = usersData?.filter(
    (user) =>
      !companybattleUsers.users?.find(
        (unJoinedUsers) => unJoinedUsers.uid === user.uid
      )
  );

  const searchedResultUsers = notPerticipatedUsers?.filter(
    (user) =>
      user.uname.includes(searchedUsersName) &&
      user.employeeId?.toString().includes(searchedUsersId) &&
      user.email.includes(searchedUsersEmail) &&
      user.rname.includes(searchedUsersRname)
  );

  const clearJoinedUsersSearch = () => {
    setSearchedJoinedUsersName('');
    setSearchedJoinedUsersId('');
    setSearchedJoinedUsersEmail('');
    setSearchedJoinedUsersRname('');
  };

  const clearUsersSearch = () => {
    setSearchedUsersName('');
    setSearchedUsersId('');
    setSearchedUsersEmail('');
    setSearchedUsersRname('');
  };

  const searchResultUsers = companybattleUsers.users?.filter(
    (user) =>
      user.uname.includes(searchedJoinedUsersName) &&
      user.employeeId.toString().includes(searchedJoinedUsersId) &&
      user.email.includes(searchedJoinedUsersEmail) &&
      user.rname.includes(searchedJoinedUsersRname)
  );

  return (
    <ThemeProvider theme={theme}>
      <ModalMolecures
        isOpen={isOpen}
        onClose={() => false}
        fullWidth={true}
        maxWidth={false}>
        <br></br>
        <Typography
          color="textSecondary"
          variant="h5"
          gutterBottom
          component="div">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;参加ユーザー編集
        </Typography>
        <hr></hr>
        <Grid container spacing={0} style={{ width: '80vw' }}>
          <Grid item xs={6}>
            <DialogContent>
              <DialogContentText>
                対抗戦に参加しているユーザー
              </DialogContentText>
              <Table
                size="small"
                aria-label="purchases"
                style={{ marginBottom: '2%' }}>
                <TableBody>
                  <TableRow>
                    <TableCell size="small" style={{ width: '30%' }}>
                      <TextField
                        id="searchedJoinedUsersName"
                        label="ニックネーム"
                        value={searchedJoinedUsersName}
                        onChange={(event) =>
                          setSearchedJoinedUsersName(event.target.value)
                        }
                        name="usersName"
                        placeholder="ニックネーム"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell size="small" style={{ width: '30%' }}>
                      <TextField
                        id="searchedJoinedUsersRname"
                        label="社員名"
                        value={searchedJoinedUsersRname}
                        onChange={(event) =>
                          setSearchedJoinedUsersRname(event.target.value)
                        }
                        name="usersRname"
                        placeholder="社員名"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell size="small" style={{ width: '30%' }}>
                      <TextField
                        id="searchedJoinedUsersId"
                        label="社員番号"
                        value={searchedJoinedUsersId}
                        onChange={(event) =>
                          setSearchedJoinedUsersId(event.target.value)
                        }
                        name="userId"
                        placeholder="社員番号"
                        fullWidth={true}
                      />
                    </TableCell>
                    {/* <TableCell style={{ width: '50%' }}>
                      <TextField
                        id="searchedJoinedUsersEmail"
                        label="メールアドレス"
                        value={searchedJoinedUsersEmail}
                        onChange={(event) =>
                          setSearchedJoinedUsersEmail(event.target.value)
                        }
                        name="userId"
                        placeholder="メールアドレス"
                        fullWidth={true}
                      />
                    </TableCell> */}
                    <TableCell align="right" style={{ width: '10%' }}>
                      <IconButton aria-label="delete">
                        <HighlightOffIcon
                          onClick={() => clearJoinedUsersSearch()}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <TableContainer style={{ height: '50vh' }}>
                <Table
                  stickyHeader
                  size="small"
                  aria-label="perticipatedUsersTable">
                  <TableHead>
                    <TableRow>
                      <TableCell>ニックネーム</TableCell>
                      <TableCell>社員名</TableCell>
                      <TableCell>社員番号</TableCell>
                      <TableCell align="center">削除</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchResultUsers
                      ?.filter(
                        (user) =>
                          user.companyid.toString() === loginUser.company_id
                      )
                      .map((user) => (
                        <TableRow
                          key={companybattle.companybattle_id + '-' + user.uid}>
                          <TableCell component="th" scope="row">
                            {user.uname}
                          </TableCell>
                          <TableCell>{user.rname}</TableCell>
                          <TableCell>{user.employeeId}</TableCell>
                          <TableCell align="center">
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                removeUsersFromCompanyBattle(user)
                              }>
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => removeAllUsersFromCompanyBattle()}
                color="secondary">
                <DeleteIcon color="secondary" />
                一括削除
              </Button>
            </DialogActions>
          </Grid>
          <Grid item xs={6}>
            <DialogContent>
              <DialogContentText>
                対抗戦に追加するユーザーを設定してください
              </DialogContentText>
              <Table
                size="small"
                aria-label="purchases"
                style={{ marginBottom: '2%' }}>
                <TableBody>
                  <TableRow>
                    <TableCell size="small" style={{ width: '30%' }}>
                      <TextField
                        id="searchedUsersName"
                        label="ニックネーム"
                        value={searchedUsersName}
                        onChange={(event) =>
                          setSearchedUsersName(event.target.value)
                        }
                        name="usersName"
                        placeholder="ニックネーム"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell size="small" style={{ width: '30%' }}>
                      <TextField
                        id="searchedUsersName"
                        label="社員名"
                        value={searchedUsersRname}
                        onChange={(event) =>
                          setSearchedUsersRname(event.target.value)
                        }
                        name="usersRname"
                        placeholder="社員名"
                        fullWidth={true}
                      />
                    </TableCell>
                    <TableCell size="small" style={{ width: '30%' }}>
                      <TextField
                        id="searchedUsersId"
                        label="社員番号"
                        value={searchedUsersId}
                        onChange={(event) =>
                          setSearchedUsersId(event.target.value)
                        }
                        name="userId"
                        placeholder="社員番号"
                        fullWidth={true}
                      />
                    </TableCell>
                    {/* <TableCell style={{ width: '50%' }}>
                      <TextField
                        id="searchedUsersEmail"
                        label="メールアドレス"
                        value={searchedUsersEmail}
                        onChange={(event) =>
                          setSearchedUsersEmail(event.target.value)
                        }
                        name="userId"
                        placeholder="メールアドレス"
                        fullWidth={true}
                      />
                    </TableCell> */}
                    <TableCell align="right" style={{ width: '10%' }}>
                      <IconButton aria-label="delete">
                        <HighlightOffIcon onClick={() => clearUsersSearch()} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <TableContainer style={{ height: '50vh' }}>
                <Table stickyHeader size="small" aria-label="search-result">
                  <TableHead>
                    <TableRow>
                      <TableCell>ニックネーム</TableCell>
                      <TableCell>社員名</TableCell>
                      <TableCell>社員番号</TableCell>
                      <TableCell align="center">追加</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchedResultUsers?.map((user, idx) => (
                      <TableRow key={idx}>
                        <TableCell component="th">{user.uname}</TableCell>
                        <TableCell>{user.rname}</TableCell>
                        <TableCell>{user.employeeId}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="add"
                            onClick={() => addUsersToCompanyBattle(user)}>
                            <AddCircleOutlineIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => addAllUsersToCompanyBattle()}
                color="primary">
                <AddCircleOutlineIcon color="primary" />
                一括追加
              </Button>
            </DialogActions>
            <DialogActions>
              <Button
                onClick={() => cancelEditCompanyBattle()}
                color="primary"
                variant="contained">
                キャンセル
              </Button>
              <Button
                onClick={() => confirmCompanyBattle()}
                color="primary"
                variant="contained">
                決定
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </ModalMolecures>
    </ThemeProvider>
  );
}

function CompanyBattleRow(props: { companybattle: CompanyBattle }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { companybattle: companybattleOrigin } = props;
  const [
    companyBattleMemberEditModalState,
    setCompanyBattleMemberEditModalState,
  ] = useState(false);
  const [companybattles, setCompanyBattles] =
    useState<CompanyBattle>(companybattleOrigin);
  const loginUser = useContext(LoginUserContext);

  useEffect(() => {
    setCompanyBattles(companybattleOrigin);
  }, [companybattleOrigin]);

  return companybattles.is_invalidated ? null : (
    <React.Fragment>
      {/* 対抗戦表示領域 */}
      <TableRow className={classes.root} hover role="checkbox" tabIndex={-1}>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            color="default"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{ width: '20%' }}>
          <>{companybattleOrigin.companybattle_name}</>
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: '20%' }}>
          <>{companybattleOrigin.companybattle_subtitle}</>
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: '20%' }}>
          <List dense={true}>
            {companybattleOrigin?.companybattle_battleevents.map(
              (battleEvent, idx) => (
                <ListItem key={idx} dense={true} disableGutters={true}>
                  <ListItemText
                    primary={battleEvent.companybattle_battleevents_name}
                  />
                </ListItem>
              )
            )}
          </List>
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: '10%' }}>
          <>{dayjs(companybattleOrigin.start_date).format('YYYY-MM-DD')}</>
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: '10%' }}>
          <>{dayjs(companybattleOrigin.end_date).format('YYYY-MM-DD')}</>
        </TableCell>
        <TableCell align="right" style={{ width: '5%' }}>
          <Link
            to={`/companybattledetail/${companybattleOrigin.companybattle_id}`}>
            <IconButton>
              <ChevronRightIcon />
            </IconButton>
          </Link>
        </TableCell>
      </TableRow>
      {/* 参加ユーザ表示領域 */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                参加メンバー
                <Button
                  className={classes.createCompanyBattleMemberButton}
                  variant="outlined"
                  vertical-align="center"
                  size="small"
                  onClick={() => {
                    setCompanyBattleMemberEditModalState(true);
                  }}>
                  <EditIcon />
                  編集
                </Button>
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '15%' }}>氏名</TableCell>
                    <TableCell style={{ width: '15%' }}>社員番号</TableCell>
                    <TableCell style={{ width: '70%' }}>メール</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companybattleOrigin.users
                    ?.filter(
                      (user) =>
                        user.companyid.toString() === loginUser.company_id
                    )
                    .map((user) => (
                      <TableRow
                        key={
                          companybattleOrigin.companybattle_id + '-' + user.uid
                        }>
                        <TableCell component="th" scope="row">
                          {user.uname}
                        </TableCell>
                        <TableCell>{user.employeeId}</TableCell>
                        <TableCell>{user.email}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <CompanyBattleEditModal
        isOpen={companyBattleMemberEditModalState}
        onClose={() => setCompanyBattleMemberEditModalState(false)}
        companybattleOrigin={companybattleOrigin}></CompanyBattleEditModal>
    </React.Fragment>
  );
}

const CompanyBattlesList: React.FC = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchedText, setSearchedText] = useState('');
  const { showToast } = useToast();
  const { useGetCompanyBattlesMutation } = useCompanyBattles();
  const { useReadCompanyBattleEvents } = useCompanyBattleEvents();

  const {
    isLoading: companybattleLoading,
    data: companybattleData,
    isError: companybattleIsError,
  } = useGetCompanyBattlesMutation();

  const {
    isLoading: battleeventsLoading,
    data: battleeventsData,
    isError: battleeventsIsError,
  } = useReadCompanyBattleEvents();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!companybattleData || !battleeventsData) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (companybattleLoading || battleeventsLoading) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  if (companybattleIsError || battleeventsIsError) {
    showToast(
      'error',
      'データ取得に失敗しました',
      '時間をおいて再度お試しください',
      3000
    );
    return (
      <MenuBarsTemplate title="">
        <></>
      </MenuBarsTemplate>
    );
  }

  return (
    <>
      <CompanyBattleEventsCtx.Provider value={battleeventsData}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
          }}>
          <Autocomplete
            freeSolo
            id="free-solo"
            disableClearable
            onChange={(event, newValue) => setSearchedText(newValue)}
            style={{ width: '25vw' }}
            options={companybattleData.map(
              (option) => option.companybattle_name
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="対抗戦検索"
                margin="normal"
                variant="outlined"
                value={searchedText}
                InputProps={{ ...params.InputProps, type: 'search' }}
                onChange={(event) => setSearchedText(event.target.value)}
              />
            )}
          />
        </div>
        <br />
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '5%' }}></TableCell>
                  <TableCell style={{ width: '20%' }}>バトル名</TableCell>
                  <TableCell style={{ width: '20%' }} align="left">
                    サブタイトル
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>競走種目</TableCell>
                  <TableCell style={{ width: '10%' }}>開始日</TableCell>
                  <TableCell style={{ width: '10%' }}>終了日</TableCell>
                  <TableCell align="center" style={{ width: '5%' }}>
                    詳細
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companybattleData
                  ?.filter(
                    (companybattle) =>
                      companybattle.companybattle_name?.includes(
                        searchedText
                      ) ||
                      companybattle.companybattle_subtitle?.includes(
                        searchedText
                      )
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((companybattle) => (
                    <CompanyBattleRow
                      key={companybattle.companybattle_id}
                      companybattle={companybattle}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={companybattleData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </CompanyBattleEventsCtx.Provider>
    </>
  );
};

export default CompanyBattlesList;
