import React, { useEffect, useState, useContext, createContext } from 'react';
import { useGroups } from '../../../controllers/services/useGroups';
import { useUser } from '../../../controllers/services/useUser';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Group } from '../../../utils/interfaces/Group';
import { User } from '../../../utils/interfaces/User';
import { useToast } from '../../molecules/toastMolecules';

import {
  Box,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import LoadingBubbleOrganisms from '../../organisms/LoadingBubbleOrganisms';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DoneIcon from '@material-ui/icons/DoneAllTwoTone';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RevertIcon from '@material-ui/icons/NotInterestedOutlined';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ModalMolecures from '../../molecules/ModalMolecules';
import Autocomplete from '@material-ui/lab/Autocomplete';

const UsersCtx = createContext<User[]>([]);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    container: {
      maxHeight: '70vh',
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    createGroupButton: {
      marginLeft: theme.spacing(2),
    },
  })
);

function GroupDetailModal(props: {
  isOpen: boolean;
  onClose: () => void;
  groupOrigin: Group;
}) {
  const { isOpen, onClose, groupOrigin } = props;
  const [group, setGroup] = useState(groupOrigin);
  const { useUpdateGroupMutation } = useGroups();
  const updateGroupMutate = useUpdateGroupMutation().mutate;
  const users = useContext(UsersCtx);
  const [searchedUserName, setSearchedUserName] = useState('');
  const [searchedUserId, setSearchedUserId] = useState('');
  const [searchedUserEmail, setSearchedUserEmail] = useState('');
  const [searchedMemberName, setSearchedMemberName] = useState('');
  const [searchedMemberId, setSearchedMemberId] = useState('');
  const [searchedMemberEmail, setSearchedMemberEmail] = useState('');
  const [searchedUserRname, setSearchedUserRname] = useState('');
  const [searchedMemberRname, setSearchedMemberRname] = useState('');

  useEffect(() => {
    setGroup(groupOrigin);
  }, [groupOrigin]);

  const addUserToGroup = async (user: User) => {
    setGroup((state) => {
      return {
        ...state,
        users: [...state.users, user],
      };
    });
  };

  const addAllUsersToGroup = async () => {
    searchResultUsers.map((user) => {
      setGroup((state) => {
        return {
          ...state,
          users: [...state.users, user],
        };
      });
    });
  };

  const removeUserFromGroup = async (user: User) => {
    setGroup((state) => {
      return {
        ...state,
        users: state.users.filter((userRow) => userRow.uid !== user.uid),
      };
    });
  };

  const removeAllUsersFromGroup = async () => {
    searchResultMembers.map((user) => {
      setGroup((state) => {
        return {
          ...state,
          users: state.users.filter((userRow) => userRow.uid !== user.uid),
        };
      });
    });
  };

  const confirmGroup = async () => {
    await updateGroupMutate(group);
    onClose();
  };

  const cancelEditGroup = async () => {
    setGroup(groupOrigin);
    onClose();
  };

  const notPerticipatedUsers = users.filter(
    (user) => !group.users.find((groupUser) => groupUser.uid == user.uid)
  );

  const searchResultUsers = notPerticipatedUsers.filter(
    (user) =>
      user.uname.includes(searchedUserName) &&
      user.employeeId.includes(searchedUserId) &&
      user.email.includes(searchedUserEmail) &&
      user.rname.includes(searchedUserRname)
  );

  const clearMemberSearch = () => {
    setSearchedMemberName('');
    setSearchedMemberId('');
    setSearchedMemberEmail('');
    setSearchedMemberRname('');
  };

  const clearUserSearch = () => {
    setSearchedUserName('');
    setSearchedUserRname('');
    setSearchedUserId('');
    setSearchedUserEmail('');
  };

  const searchResultMembers = group.users.filter(
    (user) =>
      user.uname.includes(searchedMemberName) &&
      user.employeeId.includes(searchedMemberId) &&
      user.email.includes(searchedMemberEmail) &&
      user.rname.includes(searchedMemberRname)
  );

  return (
    <ModalMolecures
      isOpen={isOpen}
      onClose={onClose}
      fullWidth={true}
      maxWidth={false}>
      <br></br>
      <Typography
        color="textSecondary"
        variant="h5"
        gutterBottom
        component="div">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ユーザー編集
      </Typography>
      <hr></hr>
      <Grid container spacing={0} style={{ width: '80vw' }}>
        <Grid item xs={6}>
          <DialogContent>
            <DialogContentText>
              グループに参加しているユーザー
            </DialogContentText>
            <Table
              size="small"
              aria-label="purchases"
              style={{ marginBottom: '2%' }}>
              <TableBody>
                <TableRow>
                  <TableCell size="small" style={{ width: '30%' }}>
                    <TextField
                      id="searchedMemberName"
                      label="ニックネーム"
                      value={searchedMemberName}
                      onChange={(event) =>
                        setSearchedMemberName(event.target.value)
                      }
                      name="userId"
                      placeholder="ニックネーム"
                      fullWidth={true}
                    />
                  </TableCell>
                  <TableCell size="small" style={{ width: '30%' }}>
                    <TextField
                      id="searchedMemberRname"
                      label="社員名"
                      value={searchedMemberRname}
                      onChange={(event) =>
                        setSearchedMemberRname(event.target.value)
                      }
                      name="userId"
                      placeholder="社員名"
                      fullWidth={true}
                    />
                  </TableCell>
                  <TableCell size="small" style={{ width: '30%' }}>
                    <TextField
                      id="searchedMemberId"
                      label="社員番号"
                      value={searchedMemberId}
                      onChange={(event) =>
                        setSearchedMemberId(event.target.value)
                      }
                      name="userId"
                      placeholder="社員番号"
                      fullWidth={true}
                    />
                  </TableCell>
                  {/* <TableCell style={{ width: '50%' }}>
                    <TextField
                      id="searchedMemberEmail"
                      label="メールアドレス"
                      value={searchedMemberEmail}
                      onChange={(event) =>
                        setSearchedMemberEmail(event.target.value)
                      }
                      name="userId"
                      placeholder="メールアドレス"
                      fullWidth={true}
                    />
                  </TableCell> */}
                  <TableCell align="right" style={{ width: '10%' }}>
                    <IconButton aria-label="delete">
                      <HighlightOffIcon onClick={() => clearMemberSearch()} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TableContainer style={{ height: '50vh' }}>
              <Table
                stickyHeader
                size="small"
                aria-label="perticipatedUserTable">
                <TableHead>
                  <TableRow>
                    <TableCell>ニックネーム</TableCell>
                    <TableCell>社員名</TableCell>
                    <TableCell>社員番号</TableCell>
                    <TableCell align="center">削除</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchResultMembers.map((user) => (
                    <TableRow key={group.group_id + '-' + user.uid}>
                      <TableCell component="th" scope="row">
                        {user.uname}
                      </TableCell>
                      <TableCell>{user.rname}</TableCell>
                      <TableCell>{user.employeeId}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="delete"
                          onClick={() => removeUserFromGroup(user)}>
                          <DeleteIcon color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => removeAllUsersFromGroup()} color="secondary">
              <DeleteIcon color="secondary" />
              一括削除
            </Button>
          </DialogActions>
        </Grid>
        <Grid item xs={6}>
          <DialogContent>
            <DialogContentText>
              グループに追加するユーザーを設定してください
            </DialogContentText>
            <Table
              size="small"
              aria-label="purchases"
              style={{ marginBottom: '2%' }}>
              <TableBody>
                <TableRow>
                  <TableCell size="small" style={{ width: '30%' }}>
                    <TextField
                      id="searchedUserName"
                      label="ニックネーム"
                      value={searchedUserName}
                      onChange={(event) =>
                        setSearchedUserName(event.target.value)
                      }
                      name="userId"
                      placeholder="ニックネーム"
                      fullWidth={true}
                    />
                  </TableCell>
                  <TableCell size="small" style={{ width: '30%' }}>
                    <TextField
                      id="searchedUserRname"
                      label="社員名"
                      value={searchedUserRname}
                      onChange={(event) =>
                        setSearchedUserRname(event.target.value)
                      }
                      name="userId"
                      placeholder="社員名"
                      fullWidth={true}
                    />
                  </TableCell>
                  <TableCell size="small" style={{ width: '30%' }}>
                    <TextField
                      id="searchedUserId"
                      label="社員番号"
                      value={searchedUserId}
                      onChange={(event) =>
                        setSearchedUserId(event.target.value)
                      }
                      name="userId"
                      placeholder="社員番号"
                      fullWidth={true}
                    />
                  </TableCell>
                  {/* <TableCell style={{ width: '50%' }}>
                    <TextField
                      id="searchedUserEmail"
                      label="メールアドレス"
                      value={searchedUserEmail}
                      onChange={(event) =>
                        setSearchedUserEmail(event.target.value)
                      }
                      name="userId"
                      placeholder="メールアドレス"
                      fullWidth={true}
                    />
                  </TableCell> */}
                  <TableCell align="right" style={{ width: '10%' }}>
                    <IconButton aria-label="delete">
                      <HighlightOffIcon onClick={() => clearUserSearch()} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TableContainer style={{ height: '50vh' }}>
              <Table stickyHeader size="small" aria-label="search-result">
                <TableHead>
                  <TableRow>
                    <TableCell>ニックネーム</TableCell>
                    <TableCell>社員名</TableCell>
                    <TableCell>社員番号</TableCell>
                    <TableCell align="center">追加</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchResultUsers.map((user, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th">{user.uname}</TableCell>
                      <TableCell>{user.rname}</TableCell>
                      <TableCell>{user.employeeId}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="add"
                          onClick={() => addUserToGroup(user)}>
                          <AddCircleOutlineIcon color="primary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => addAllUsersToGroup()} color="primary">
              <AddCircleOutlineIcon color="primary" />
              一括追加
            </Button>
          </DialogActions>
          <DialogActions>
            <Button
              onClick={() => cancelEditGroup()}
              color="primary"
              variant="contained">
              キャンセル
            </Button>
            <Button
              onClick={() => confirmGroup()}
              color="primary"
              variant="contained">
              決定
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </ModalMolecures>
  );
}

function GroupRow(props: { group: Group }) {
  const classes = useStyles();
  const { group: groupOrigin } = props;
  const [open, setOpen] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [group, setGroup] = React.useState<Group>(groupOrigin);
  const [previous, setPrevious] = React.useState({} as Group);
  const { useUpdateGroupMutation, useDeleteGroupMutation } = useGroups();
  const updateGroupMutate = useUpdateGroupMutation().mutate;
  const deleteGroupMutate = useDeleteGroupMutation().mutate;

  useEffect(() => {
    setGroup(groupOrigin);
  }, [groupOrigin]);

  // モーダル表示制御関数
  const [userEditModalState, setUserEditModalState] = React.useState(false);
  const [deleteGroupModalState, setDeleteGroupModalState] = React.useState<{
    [key: string]: number;
  }>({});

  const onToggleEditMode = () => {
    setIsEditMode((state) => !state);
    if (!Object.keys(previous).length) {
      setPrevious((state) => ({ ...state, ...group }));
    }
  };

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    group: Group
  ) => {
    const value = event.target.value;
    const group_name = event.target.name;
    const { group_id } = group;
    const newGroup = {
      ...group,
      [group_name]: value,
    };
    setGroup(newGroup);
  };

  const onConfirm = async () => {
    setPrevious((state) => {
      state = {} as Group;
      return state;
    });
    await updateGroupMutate({
      group_id: group.group_id,
      group_name: group.group_name,
      users: group.users,
    });
    onToggleEditMode();
  };

  const onRevert = () => {
    setGroup(previous);
    setPrevious((state) => {
      state = {} as Group;
      return state;
    });
    onToggleEditMode();
  };

  const onClickDeleteGroupButton = async () => {
    await deleteGroupMutate(deleteGroupModalState.group_id);
    setDeleteGroupModalState({});
  };
  return group.is_invalidated ? null : (
    <React.Fragment>
      {/* グループ表示領域 */}
      <TableRow className={classes.root} hover role="checkbox" tabIndex={-1}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {isEditMode ? (
            <TextField
              id="standard-basic"
              label="対抗戦グループ名"
              inputProps={{ maxLength: 100, minLength: 1 }}
              onChange={(event) => onChange(event, group)}
              onKeyPress={(e) => {
                if (
                  e.key == 'Enter' &&
                  group.group_name.length <= 100 &&
                  group.group_name.length > 0
                ) {
                  e.preventDefault();
                  onConfirm();
                }
              }}
              helperText={
                group.group_name.length < 1 || group.group_name.length > 100
                  ? 'グループ名は1〜100文字で指定してください。'
                  : ''
              }
              name="group_name"
              placeholder="対抗戦グループ名"
              autoComplete="off"
              defaultValue={group.group_name}
              fullWidth={true}
            />
          ) : (
            <div>{group.group_name}</div>
          )}
        </TableCell>
        <TableCell align="center">
          {isEditMode ? (
            <>
              <IconButton
                aria-label="done"
                onClick={(e) => {
                  if (
                    group.group_name.length > 0 &&
                    group.group_name.length <= 100
                  ) {
                    onConfirm();
                  }
                }}>
                <DoneIcon
                  color={
                    group.group_name.length > 0 &&
                    group.group_name.length <= 100
                      ? 'primary'
                      : 'disabled'
                  }
                />
              </IconButton>
              <IconButton aria-label="revert" onClick={() => onRevert()}>
                <RevertIcon color="secondary" />
              </IconButton>
            </>
          ) : (
            <IconButton aria-label="edit" onClick={(e) => onToggleEditMode()}>
              <EditIcon color="primary" />
            </IconButton>
          )}
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="delete"
            onClick={() =>
              setDeleteGroupModalState({ group_id: group.group_id })
            }>
            <DeleteIcon color="secondary" />
          </IconButton>
        </TableCell>
      </TableRow>
      {/* 参加ユーザー表示領域 */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                参加メンバー
                <Button
                  className={classes.createGroupButton}
                  variant="outlined"
                  vertical-align="center"
                  size="small"
                  onClick={() => {
                    setUserEditModalState(true);
                  }}>
                  <EditIcon />
                  編集
                </Button>
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '15%' }}>氏名</TableCell>
                    <TableCell style={{ width: '15%' }}>社員番号</TableCell>
                    <TableCell style={{ width: '70%' }}>メール</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {group.users.map((user) => (
                    <TableRow key={group.group_id + '-' + user.uid}>
                      <TableCell component="th" scope="row">
                        {user.uname}
                      </TableCell>
                      <TableCell>{user.employeeId}</TableCell>
                      <TableCell>{user.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <GroupDetailModal
        isOpen={userEditModalState}
        onClose={() => setUserEditModalState(false)}
        groupOrigin={group}></GroupDetailModal>
      <ModalMolecures
        isOpen={!!Object.keys(deleteGroupModalState).length}
        onClose={() => setDeleteGroupModalState({})}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          noWrap
          style={{ margin: '2%' }}>
          グループ削除
        </Typography>
        <hr></hr>
        <DialogContent>
          <DialogContentText>
            グループを削除します、よろしいですか
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteGroupModalState({})} color="primary">
            キャンセル
          </Button>
          <Button onClick={() => onClickDeleteGroupButton()} color="secondary">
            削除
          </Button>
        </DialogActions>
      </ModalMolecures>
    </React.Fragment>
  );
}

const GroupBattlesGroups: React.FC = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [createGroupName, setCreateGroupName] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchedText, setSearchedText] = useState('');
  const { showToast } = useToast();
  const { useGetGroups, useCreateGroupMutation } = useGroups();
  const { useGetUserMutation } = useUser();
  const createGroupMutate = useCreateGroupMutation().mutate;

  const {
    isLoading: groupIsLoading,
    data: groupData,
    isError: groupIsError,
    error: groupError,
  } = useGetGroups();

  const {
    isLoading: userIsLoading,
    data: userData,
    isError: userIsError,
    error: userError,
  } = useGetUserMutation();

  // モーダル表示制御関数
  const [createModalState, setCreateModalState] = React.useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onClickCreateGroupButton = async () => {
    await createGroupMutate({ group_name: createGroupName });
    setCreateModalState(false);
    setCreateGroupName('');
  };

  if (!groupData || !userData) {
    return <LoadingBubbleOrganisms />;
  }

  if (groupIsLoading || userIsLoading) {
    return <LoadingBubbleOrganisms />;
  }
  if (groupIsError || userIsError) {
    showToast(
      'error',
      'データ取得に失敗しました',
      '時間をおいて再度お試しください',
      3000
    );
    return <></>;
  }

  return (
    <>
      <UsersCtx.Provider value={userData}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Typography
            color="textSecondary"
            variant="h5"
            gutterBottom
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
            component="div">
            <Button
              className={classes.createGroupButton}
              variant="outlined"
              vertical-align="center"
              size="small"
              onClick={() => {
                setCreateModalState(true);
              }}>
              <AddCircleOutlineIcon />
              新規グループ作成
            </Button>
          </Typography>
          <Autocomplete
            freeSolo
            id="free-solo"
            disableClearable
            onChange={(event, newValue) => setSearchedText(newValue)}
            style={{ width: '25vw' }}
            options={groupData.map((option) => option.group_name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="グループ検索"
                margin="normal"
                variant="outlined"
                value={searchedText}
                InputProps={{ ...params.InputProps, type: 'search' }}
                onChange={(event) => setSearchedText(event.target.value)}
              />
            )}
          />
        </div>
        <br />
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: '5%' }}></TableCell>
                  <TableCell align="left" style={{ width: '80%' }}>
                    対抗戦グループ名
                  </TableCell>
                  <TableCell align="center" style={{ width: '10%' }}>
                    編集
                  </TableCell>
                  <TableCell align="center" style={{ width: '5%' }}>
                    削除
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupData
                  .filter((group) => group.group_name.includes(searchedText))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((group) => (
                    <GroupRow key={group.group_id} group={group} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={groupData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        <ModalMolecures
          isOpen={createModalState}
          onClose={() => setCreateModalState(false)}>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            noWrap
            style={{ margin: '2%' }}>
            新規グループ作成
          </Typography>
          <hr></hr>
          <DialogContent>
            <DialogContentText>
              新たに作成する対抗戦グループの名前を入力してください
            </DialogContentText>
            <TextField
              value={createGroupName}
              inputProps={{ maxLength: 60, minLength: 1 }}
              helperText={
                createGroupName.length < 1 || createGroupName.length >= 60
                  ? 'グループ名は60文字までで指定してください。'
                  : ''
              }
              onChange={(event) => setCreateGroupName(event.target.value)}
              onKeyPress={(e) => {
                if (
                  e.key == 'Enter' &&
                  createGroupName !== '' &&
                  createGroupName.length <= 60
                ) {
                  e.preventDefault();
                  onClickCreateGroupButton();
                }
              }}
              autoComplete="off"
              autoFocus
              margin="dense"
              id="inquiry-team-name"
              label="対抗戦グループ名"
              fullWidth={true}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCreateModalState(false)} color="primary">
              キャンセル
            </Button>
            <Button
              onClick={() => onClickCreateGroupButton()}
              color="primary"
              disabled={createGroupName === ''}>
              決定
            </Button>
          </DialogActions>
        </ModalMolecures>
      </UsersCtx.Provider>
    </>
  );
};

export default GroupBattlesGroups;
