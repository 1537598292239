import React, { useContext, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import LoadingBubbleOrganisms from '../organisms/LoadingBubbleOrganisms';
import MenuBarsTemplate from '../templates/MenuBarsTemplate';
import IconButton from '@material-ui/core/IconButton';
import { AssignmentIndRounded, ContactSupportOutlined } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { Button, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useQuery } from 'react-query';
import {
  getActiveUsers,
  getNotActiveUsers,
} from '../../controllers/services/useCompany';

dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    container: {
      maxHeight: 440,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      height: 600,
      width: 500,
      padding: 20,
      outline: 'none',
      borderRadius: 10,
    },
    button: {
      height: 50,
      width: 200,
      marginBottom: 30,
      backgroundColor: 'textSecondary',
      outline: 'none',
    },
    form: {
      height: 50,
      width: '80%',
      marginTop: 20,
      marginLeft: 45,
    },
    createDepartmentButton: {
      marginLeft: theme.spacing(2),
    },
    table: {
      tableLayout: 'fixed',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 110,
    },
  })
);

const LoginManagement: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const [showActiveUsers, setShowActiveUsers] = useState(true);
  const [showChallengeUsers, setShowChallengeUsers] = useState(true);

  const getQueryDates = (): string[] => {
    const type = new URLSearchParams(location.search).get('type');
    const dataType = new URLSearchParams(location.search).get('data') ?? 'login';
    switch (type) {
      case 'daily':
        return [
          location.pathname.split('/')[2],
          location.pathname.split('/')[2],
          dataType,
        ];
      case 'weekly':
        return [
          dayjs(location.pathname.split('/')[2])
          .startOf('week')
          .add(1, 'day')
          .format('YYYY-MM-DD'),
          dayjs(location.pathname.split('/')[2])
          .endOf('week')
          .add(1, 'day')
          .format('YYYY-MM-DD'),
          dataType,
        ];
      case 'monthly':
        return [
          dayjs(location.pathname.split('/')[2])
            .startOf('month')
            .format('YYYY-MM-DD'),
          dayjs(location.pathname.split('/')[2])
            .endOf('month')
            .format('YYYY-MM-DD'),
            dataType,
        ];
      case 'annually':
        return [
          dayjs(location.pathname.split('/')[2])
          .startOf('year')
          .format('YYYY-MM-DD'),
          dayjs(location.pathname.split('/')[2])
          .endOf('month')
          .format('YYYY-MM-DD'),
          dataType,
        ];
      default:
        return ['startDate', 'endDate', 'login'];
    }
  };

  const [startDate, endDate, dataType] = getQueryDates();
  const { data: activeUsers } = useQuery(
    ['activeUsers', startDate, endDate, dataType],
    () => getActiveUsers(startDate, endDate, dataType)
  );
  const { data: notActiveUsers } = useQuery(
    ['notActiveUsers', startDate, endDate, dataType],
    () => getNotActiveUsers(startDate, endDate, dataType)
  );

  if (!activeUsers || !notActiveUsers) {
    return (
      <MenuBarsTemplate title="">
        <LoadingBubbleOrganisms />
      </MenuBarsTemplate>
    );
  }

  return (
    <MenuBarsTemplate title="ログインユーザー管理">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        {dataType == "login" ? 
        <Typography
          style={{ marginTop: 30 }}
          color="textSecondary"
          variant="h5"
          gutterBottom
          component="div">
          {showActiveUsers
            ? 'アクティブユーザー 一覧'
            : '非アクティブユーザー 一覧'}
        </Typography> :
        <Typography
          style={{ marginTop: 30 }}
          color="textSecondary"
          variant="h5"
          gutterBottom
          component="div">
          {showChallengeUsers
                ? 'デイリーチャレンジ達成ユーザー 一覧'
                : 'デイリーチャレンジ未達成ユーザー  一覧'}
        </Typography>
        }
        {dataType == "login" ? 
        <Button
          onClick={() => setShowActiveUsers((old) => !old)}
          color="primary"
          style={{ height: '45px' }}>
          {showActiveUsers
            ? '非アクティブユーザー 一覧を見る'
            : 'アクティブユーザー 一覧を見る'}
        </Button> :
        <Button
          onClick={() => setShowChallengeUsers((old) => !old)}
          color="primary"
          style={{ height: '45px' }}>
          {showChallengeUsers
            ? 'デイリーチャレンジ未達成ユーザー 一覧を見る'
            : 'デイリーチャレンジ達成ユーザー 一覧を見る'}
        </Button>
        }
      </div>
      <MaterialTable
        columns={[
          { title: 'ユーザーネーム', field: 'uname' },
          { title: '社員名', field: 'rname' },
          { title: '社員番号', field: 'employeeId' },
          { title: '社用メール', field: 'email' },
          { 
            title: 'UID', 
            field: 'uid',
            type: 'numeric' },
          // {title:'最終ログイン日時', field: 'login_date', type: 'date',
          // render: rowData => dayjs(rowData.login_date).format('YYYY-MM-DD hh:mm:ss')
          // },
          {
            title: '詳細',
            field: 'detail',
            sorting: false,
            render: function toUserDetail(rowData: any) {
              return (
                <Link
                  to={{
                    pathname: '/userdetail',
                    search: `?uid=${rowData.uid}`,
                  }}>
                  <IconButton aria-label="search">
                    <AssignmentIndRounded color="primary" />
                  </IconButton>
                </Link>
              );
            },
          },
        ]}
        data={dataType == "login" ? 
        showActiveUsers ? activeUsers : notActiveUsers :
        showChallengeUsers ? activeUsers : notActiveUsers
        }
        options={{
          showTitle: false,
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          filtering: false,
        }}
      />
    </MenuBarsTemplate>
  );
};

export default LoginManagement;
